import { SheduledEvent } from '../_data/SheduledEvent';
import {
  getEventMock,
  getRegistrationMock,
  postRegistrationMock,
  postUnregisterMock,
} from '../_data/mocks/mockRequests';
import { User } from '../_data/User';
import { Participant, Payment, Registration } from '../_data/Registration';
import { useMocks } from '../restapi/backendPaths';
import {
  EventRestApiDto,
  mapEventRestApiDto,
} from '../_data/restApiDto/EventRestApiDto';
import { CommonDocsApiDto } from '../_data/restApiDto/CommonDocsApiDto';
import {
  CheckRegistrationApiDto,
  mapCheckRegistrationApiDto,
  mapRegistrationApiDto,
  RegistrationApiDto,
} from '../_data/restApiDto/RegistrationApiDto';
import { mapUserRestApiDto } from '../_data/restApiDto/UserRestApiDto';
import {
  checkHttpResult,
  checkHttpResultVoid,
  get,
  post,
} from '../restapi/api';
import { useAuthContext } from '../auth/AuthContext';
import { useRestApiContext } from '../restapi/RestApiContext';
import { Language } from '../_localization/avaliable.languages';
import { useLocalizationContext } from '../_localization/LocalizationContext';
import { uiMessages } from '../_localization/ui.messages';

const getEventPath = (id: string, lang: Language = 'ru') =>
  `events/${id}?lang=${lang}`;
const getCheckRegistrationPath = (
  id: string,
  userId: string,
  lang: Language = 'ru',
) => `events/${id}/checkUser/${userId}?lang=${lang}`;
const postRegistrationPath = (id: string) => `events/${id}/register`;
const postUnregisterPath = (id: string, userId: string) =>
  `events/${id}/unregister/${userId}`;
const getPublicOfferPath = 'events/commonDocs';

export function useEventRestApi(): {
  getEvent: (id: string, lang?: Language) => Promise<SheduledEvent | undefined>;
  getRegistration: (
    id: string,
    user: User,
    lang?: Language,
  ) => Promise<Registration | undefined>;
  postRegistration: (
    id: string,
    user: User,
  ) => Promise<Participant | Payment | undefined>;
  postUnregister: (id: string, user: User) => Promise<void>;
  getPublicOffer: () => Promise<string>;
} {
  const { language } = useLocalizationContext();
  const { accessToken: accessTokenRestApi } = useRestApiContext();
  const { accessToken: accessTokenSso } = useAuthContext();

  async function getEvent(
    id: string,
    lang: Language = language,
  ): Promise<SheduledEvent | undefined> {
    if (useMocks()) {
      return getEventMock(id);
    }
    return get<EventRestApiDto>(
      getEventPath(id, lang),
      accessTokenRestApi,
    ).then((result) =>
      mapEventRestApiDto(
        checkHttpResult(result, uiMessages.errors_onLoad[language]),
      ),
    );
  }

  async function getRegistration(
    id: string,
    user: User,
    lang?: Language,
  ): Promise<Registration | undefined> {
    if (useMocks()) {
      return getRegistrationMock(id, user);
    }
    return get<CheckRegistrationApiDto>(
      getCheckRegistrationPath(id, user.id, lang),
      accessTokenRestApi,
      accessTokenSso
        ? {
            headers: {
              SSO_TOKEN: accessTokenSso,
            },
          }
        : undefined,
    ).then((result) =>
      mapCheckRegistrationApiDto(
        user.id,
        id,
        checkHttpResult(result, uiMessages.errors_onLoad[language]),
      ),
    );
  }

  async function postRegistration(
    id: string,
    user: User,
  ): Promise<Participant | Payment | undefined> {
    if (useMocks()) {
      return postRegistrationMock(id, user);
    }
    return post<RegistrationApiDto>(
      postRegistrationPath(id),
      mapUserRestApiDto(user),
      accessTokenRestApi,
      accessTokenSso
        ? {
            headers: {
              SSO_TOKEN: accessTokenSso,
            },
          }
        : undefined,
    ).then((result) =>
      mapRegistrationApiDto(
        checkHttpResult(result, uiMessages.errors_onRegister[language]),
      ),
    );
  }

  async function postUnregister(id: string, user: User): Promise<void> {
    if (useMocks()) {
      return postUnregisterMock(id, user.id);
    }
    return post<void>(
      postUnregisterPath(id, user.id),
      undefined,
      accessTokenRestApi,
      accessTokenSso
        ? {
            headers: {
              SSO_TOKEN: accessTokenSso,
            },
          }
        : undefined,
    ).then((result) =>
      checkHttpResultVoid(
        result,
        uiMessages.errors_onCancelRegistration[language],
      ),
    );
  }

  async function getPublicOffer(): Promise<string> {
    if (useMocks()) {
      return 'test_offer.pdf';
    }
    return get<CommonDocsApiDto>(getPublicOfferPath).then(
      (result) =>
        checkHttpResult(result, uiMessages.errors_onLoad[language])
          .publicOfferFileId,
    );
  }

  return {
    getEvent,
    getRegistration,
    postRegistration,
    postUnregister,
    getPublicOffer,
  };
}
