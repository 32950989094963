import {
  getUserProfileMock,
  getUserVisitedEventsMock,
  postUserProfileMock,
} from '../_data/mocks/mockRequests';
import { User, UserProfile } from '../_data/User';
import { useMocks } from '../restapi/backendPaths';
import {
  UserProfileRestApiDto,
  mapUserProfileRestApiDto,
  unmapUserProfileRestApiDto,
} from '../_data/restApiDto/UserRestApiDto';
import { VisitedEvent } from '../_data/SheduledEvent';
import {
  mapVisitedEventRestApiDto,
  VisitedEventRestApiDto,
} from '../_data/restApiDto/EventRestApiDto';
import { checkHttpResultVoid, get, post } from '../restapi/api';
import { checkHttpResult } from '../restapi/api';
import { FileRestApiDto } from '../_data/restApiDto/FileRestApiDto';
import { mockUserProfile } from '../_data/mocks/MockUser';
import { useAuthContext } from '../auth/AuthContext';
import { useRestApiContext } from '../restapi/RestApiContext';
import { uiMessages } from '../_localization/ui.messages';
import { useLocalizationContext } from '../_localization/LocalizationContext';
import { CommonDocsApiDto } from '../_data/restApiDto/CommonDocsApiDto';

const getUserProfilePath = (userId: string) => `users/${userId}`;
const getUserVisitedEventsPath = (userId: string, lang: string) =>
  `users/${userId}/events?lang=${lang}`;
const postUserProfilePath = (userId: string) => `users/${userId}`;
const postUserPhotoPath = (fileName: string) => `v2/files?name=${fileName}`;
const getPersonalInfoAgreementPath = 'events/commonDocs';

export function useUserRestApi(): {
  getUserProfile: (
    id: string,
    accessTokenSsoNew?: string,
  ) => Promise<UserProfile | undefined>;
  getUserVisitedEvents: (
    userId: string,
    lang: string,
  ) => Promise<VisitedEvent[]>;
  postNewUserProfile: (user: User, accessTokenSsoNew?: string) => Promise<void>;
  postUserProfile: (
    profile: UserProfile,
    photo?: File,
    accessTokenSsoNew?: string,
  ) => Promise<void>;
  getPersonalInfoAgreement: () => Promise<string>;
} {
  const { language } = useLocalizationContext();
  const { accessToken: accessTokenRestApi } = useRestApiContext();
  const { accessToken: accessTokenSso } = useAuthContext();

  async function getUserProfile(
    id: string,
    accessTokenSsoNew?: string,
  ): Promise<UserProfile | undefined> {
    if (useMocks()) {
      return getUserProfileMock();
    }
    const accessTokenSsoActual = accessTokenSso || accessTokenSsoNew;
    return get<UserProfileRestApiDto>(
      getUserProfilePath(id),
      accessTokenRestApi,
      accessTokenSsoActual
        ? {
            headers: { SSO_TOKEN: accessTokenSsoActual },
          }
        : undefined,
    ).then((result) =>
      mapUserProfileRestApiDto(
        checkHttpResult(result, uiMessages.errors_onLoadProfile[language]),
      ),
    );
  }

  async function getUserVisitedEvents(
    userId: string,
    lang: string,
  ): Promise<VisitedEvent[]> {
    if (useMocks()) {
      return getUserVisitedEventsMock();
    }
    return get<VisitedEventRestApiDto[]>(
      getUserVisitedEventsPath(userId, lang),
      accessTokenRestApi,
      accessTokenSso
        ? {
            headers: {
              SSO_TOKEN: accessTokenSso,
            },
          }
        : undefined,
    ).then((result) =>
      checkHttpResult(
        result,
        uiMessages.errors_onLoadVisitedEvents[language],
      ).map((ve) => mapVisitedEventRestApiDto(ve)),
    );
  }

  async function postNewUserProfile(
    user: User,
    accessTokenSsoNew?: string,
  ): Promise<void> {
    const profile: UserProfile = {
      id: user.id,
      email: user.email || '',
      firstName: user.firstName,
      middleName: user.middleName,
      lastName: user.lastName,
      work: '',
      position: '',
      city: '',
      specialties: [],
    };
    postUserProfile(profile, undefined, accessTokenSsoNew);
  }

  async function postUserPhoto(photo: File): Promise<FileRestApiDto> {
    if (useMocks()) {
      mockUserProfile.photo = { url: URL.createObjectURL(photo) };
      return { id: '0', name: photo.name, size: 1 };
    }
    const { language } = useLocalizationContext();
    return post<FileRestApiDto>(
      postUserPhotoPath(photo.name),
      photo,
      accessTokenRestApi,
      accessTokenSso
        ? {
            headers: {
              SSO_TOKEN: accessTokenSso,
            },
          }
        : undefined,
    ).then((photoResult) =>
      checkHttpResult(photoResult, uiMessages.errors_onSavePhoto[language]),
    );
  }

  async function postUserProfile(
    profile: UserProfile,
    photo?: File,
    accessTokenSsoNew?: string,
  ): Promise<void> {
    if (useMocks()) {
      new Error(uiMessages.errors_onSavePhoto[language]);
      if (photo) {
        const photoUrl = URL.createObjectURL(photo);
        profile.photo = { url: photoUrl };
      }
      return postUserProfileMock(profile);
    }
    const accessTokenSsoActual = accessTokenSso || accessTokenSsoNew;
    if (photo) {
      profile.photo = { fileId: (await postUserPhoto(photo)).id };
    }
    return post<void>(
      postUserProfilePath(profile.id),
      unmapUserProfileRestApiDto(profile),
      accessTokenRestApi,
      accessTokenSsoActual
        ? {
            headers: {
              SSO_TOKEN: accessTokenSsoActual,
            },
          }
        : undefined,
    ).then((result) =>
      checkHttpResultVoid(result, uiMessages.errors_onSaveProfile[language]),
    );
  }

  async function getPersonalInfoAgreement(): Promise<string> {
    if (useMocks()) {
      return 'test_offer.pdf';
    }
    return get<CommonDocsApiDto>(getPersonalInfoAgreementPath).then(
      (result) =>
        checkHttpResult(
          result,
          uiMessages.errors_onLoadPersonalInfoAgreement[language],
        ).personalInfoAgreementFileId,
    );
  }
  return {
    getUserProfile,
    getUserVisitedEvents,
    postNewUserProfile,
    postUserProfile,
    getPersonalInfoAgreement,
  };
}
