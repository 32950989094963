import React from 'react';
import { format, isWeekend } from 'date-fns';
import { useLocalizationContext } from '../_localization/LocalizationContext';

interface EventDateProps {
  start: Date;
  end?: Date;
}

export const EventDate: React.FC<EventDateProps> = ({ start, end }) => {
  const { locale } = useLocalizationContext();
  return (
    <div className="eventDate">
      <div className="eventDate-date">
        <span>
          {format(start, 'd', { locale }) +
            ' ' +
            format(start, 'MMM', { locale }).slice(0, 3)}
        </span>
        {end && end.toDateString() !== start.toDateString() && (
          <span>
            {'—' +
              format(end, 'd', { locale }) +
              ' ' +
              format(end, 'MMM', { locale }).slice(0, 3)}
          </span>
        )}
      </div>
      <div className="eventDate-day">
        <span className={isWeekend(start) ? ' weekend' : ''}>
          {format(start, 'EEEEEE', { locale })}
        </span>
        {end && end.toDateString() !== start.toDateString() && (
          <span className={isWeekend(end) ? ' weekend' : ''}>
            {'—' + format(end, 'EEEEEE', { locale })}
          </span>
        )}
      </div>
    </div>
  );
};
