import { FileLink } from './FileLink';

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  middleName: string;
  username: string;
  email: string;
  role?: string;
}

export interface UserProfile {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  middleName?: string;
  phone?: string;
  photo?: FileLink;
  work: string;
  position: string;
  specialties: string[];
  city: string;
  personalInfoAgreement?: boolean;
}

export function getUserName(user: User | UserProfile): string {
  return user.firstName || user.lastName
    ? `${user.firstName} ${user.lastName}`
    : user.email;
}

export function isUserProfileUncompleted(user: UserProfile): boolean {
  return !(
    user.firstName &&
    user.lastName &&
    user.email &&
    user.phone &&
    user.city &&
    user.work &&
    user.position &&
    user.specialties[0] &&
    user.personalInfoAgreement
  );
}
