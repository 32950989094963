import React from 'react';

interface EventHeaderCardProps {
  area: string;
  title: string;
  preformatted?: string;
}
export const EventHeaderCard: React.FC<EventHeaderCardProps> = ({
  area,
  title,
  preformatted,
  children,
}) => {
  return (
    <div className="eventHeader-card" style={{ gridArea: area }}>
      <div className="eventHeader-card-title">{title}</div>
      <div className="eventHeader-card-text">
        {preformatted && (
          <span dangerouslySetInnerHTML={{ __html: preformatted }}></span>
        )}
        {children}
      </div>
    </div>
  );
};
