import iconCamera from '../../_img/Camera.svg';
import React from 'react';
import ImgBackend from '../../restapi/ImgBackend';
import { FileLink } from '../../_data/FileLink';
import { uiMessages } from '../../_localization/ui.messages';
import { useLocalizationContext } from '../../_localization/LocalizationContext';

interface LoadPhotoProps {
  name: string;
  initialValue?: FileLink;
  file?: File;
  setFile: (file: File) => void;
  label?: string;
}

export const LoadPhoto: React.FC<LoadPhotoProps> = ({
  name,
  initialValue,
  file,
  setFile,
  label,
}) => {
  const { language } = useLocalizationContext();
  const [value, setValue] = React.useState(initialValue);
  const inputRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (file) {
      setValue({ url: URL.createObjectURL(file) });
    } else {
      setValue(initialValue);
    }
    return () => {
      if (file && value?.url) {
        URL.revokeObjectURL(value.url);
      }
    };
  }, [file, initialValue]);

  const onFileChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const files = e?.target?.files;
    if (!files) {
      return;
    }

    setFile(files[0]);
  };

  return (
    <div className="formField">
      <div className="uploadFile">
        <div className="uploadFile-photo avatar">
          {value && value === initialValue ? (
            <ImgBackend srcFile={value} />
          ) : (
            <img
              src={value?.url || iconCamera}
              className={value ? '' : 'empty'}
            />
          )}
        </div>
        <input
          ref={inputRef}
          className="field-input"
          id={name}
          name={name}
          type="file"
          accept="image/*"
          onChange={onFileChange}
          hidden
        />
        <button
          type="button"
          className="uploadFile-button"
          onClick={() => inputRef?.current?.click()}
        >
          {label || uiMessages.form_LoadPhoto[language]}
        </button>
      </div>
    </div>
  );
};
