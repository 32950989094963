import React from 'react';

interface InputFieldProps {
  name: string;
  type: string;
  disabled?: boolean;
  value: string;
  setValue: (value: string) => void;
}

export const InputField: React.FC<InputFieldProps> = ({
  name,
  type,
  disabled,
  value,
  setValue,
}) => {
  return (
    <input
      className="field-input"
      id={name}
      name={name}
      type={type}
      value={value}
      disabled={disabled}
      onChange={(e) => setValue(e.target.value)}
    />
  );
};
