import React from 'react';
import { InnerPage } from '../layout/InnerPage';

interface UserPageTemplateProps {
  backLink: { title: string; link: string };
  header: string;
}

const UserPageTemplate: React.FC<UserPageTemplateProps> = ({
  backLink,
  header,
  children,
}) => {
  return (
    <InnerPage backLink={backLink} header={<h1>{header}</h1>}>
      <div className="userPage-container">{children}</div>
    </InnerPage>
  );
};

export default UserPageTemplate;
