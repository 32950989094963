import { Language } from './avaliable.languages';

type UiMessage = {
  [language in Language]: string;
};

interface UiMessages {
  samsmu: UiMessage;
  event: UiMessage;
  broadcast: UiMessage;
  conference: UiMessage;
  yes: UiMessage;
  no: UiMessage;
  cancel: UiMessage;
  of: UiMessage;
  days: UiMessage;
  hours: UiMessage;
  minutes: UiMessage;
  rubles: UiMessage;
  priceFree: UiMessage;
  send: UiMessage;
  download: UiMessage;
  loading: UiMessage;
  startIn: UiMessage;
  goTo: UiMessage;
  goBack: UiMessage;
  form_Save: UiMessage;
  form_Required: UiMessage;
  form_LoadPhoto: UiMessage;
  form_NothingSelected: UiMessage;
  dataIsSaved: UiMessage;
  notFound_Title: UiMessage;
  notFound_Excuse: UiMessage;
  notAuth_Title: UiMessage;
  notAuth_Excuse: UiMessage;
  nav_Login: UiMessage;
  nav_Logout: UiMessage;
  nav_BackToCatalog: UiMessage;
  nav_BackToProfile: UiMessage;
  catalog_Period: UiMessage;
  catalog_Specialities: UiMessage;
  nav_Archive: UiMessage;
  nav_Home: UiMessage;
  events_Empty: UiMessage;
  event_Date: UiMessage;
  event_Start: UiMessage;
  event_Type: UiMessage;
  event_EventTitle: UiMessage;
  event_Entrance: UiMessage;
  event_NmoScore: UiMessage;
  event_NmoCode: UiMessage;
  event_Score: UiMessage;
  event_Duration: UiMessage;
  event_Location: UiMessage;
  event_TargetAudience: UiMessage;
  event_Description: UiMessage;
  event_ControlInfo: UiMessage;
  event_HasFinished: UiMessage;
  event_DoRegister: UiMessage;
  event_CancelRegistration: UiMessage;
  event_RegistrationFinished: UiMessage;
  event_QuotaExceeded: UiMessage;
  event_Registered: UiMessage;
  event_RegisteredAsSpeaker: UiMessage;
  event_GoToBroadcast: UiMessage;
  event_SheduleIsEmpty: UiMessage;
  event_Organizers: UiMessage;
  event_Sponsors: UiMessage;
  event_NotPublished: UiMessage;
  payment_Check: UiMessage;
  payment_NotFound: UiMessage;
  payment_Checking: UiMessage;
  payment_Retrieving: UiMessage;
  payment_NothingToCheck: UiMessage;
  payment_NotFinished: UiMessage;
  payment_Success: UiMessage;
  payment_ToBank: UiMessage;
  payment_ToEvent: UiMessage;
  chat_MessageDeleted: UiMessage;
  chat_MessageEditing: UiMessage;
  chat_NewMessage: UiMessage;
  chat_Placeholder: UiMessage;
  chat_DoDisable: UiMessage;
  chat_DoEnable: UiMessage;
  chat_Disabled: UiMessage;
  chat_UsersOnline: UiMessage;
  quiz_PresenceControl: UiMessage;
  quiz_Answer: UiMessage;
  quiz_SendAnswer: UiMessage;
  user_VisitedEvents: UiMessage;
  user_VisitedEventsNone: UiMessage;
  user_ChangePassword: UiMessage;
  profile: UiMessage;
  profile_Edit: UiMessage;
  profile_FirstName: UiMessage;
  profile_MiddleName: UiMessage;
  profile_LastName: UiMessage;
  profile_Speciality: UiMessage;
  profile_Phone: UiMessage;
  profile_Email: UiMessage;
  profile_City: UiMessage;
  profile_Workplace: UiMessage;
  profile_Position: UiMessage;
  profile_YourScore: UiMessage;
  profile_Uncompleted: UiMessage;
  profile_MarkedFields: UiMessage;
  profile_MarkedFields2: UiMessage;
  profile_PersonalInfoAgreement_pre: UiMessage;
  profile_PersonalInfoAgreement_link: UiMessage;
  contacts: UiMessage;
  contacts_Title: UiMessage;
  contacts_Department: UiMessage;
  contacts_Address: UiMessage;
  contacts_Phone: UiMessage;
  photos: UiMessage;
  errors_onLoad: UiMessage;
  errors_onLoadProfile: UiMessage;
  errors_onLoadVisitedEvents: UiMessage;
  errors_onLoadSpecialities: UiMessage;
  errors_onLoadPersonalInfoAgreement: UiMessage;
  errors_onSave: UiMessage;
  errors_onSaveProfile: UiMessage;
  errors_onSavePhoto: UiMessage;
  errors_onRegister: UiMessage;
  errors_onCancelRegistration: UiMessage;
  errors_onCheckRegistration: UiMessage;
  errors_onCheckPayment: UiMessage;
  errors_onWebinarNotAuthorized: UiMessage;
  errors_onWebinarNotRegistered: UiMessage;
  errors_onWebinarCheckRegisteration: UiMessage;
  errors_underDevelopment: UiMessage;
}

export const uiMessages: UiMessages = {
  samsmu: {
    ru: 'Самарский государственный медицинский университет',
    en: 'Samara State Medical University',
  },
  event: {
    ru: 'мероприятие',
    en: 'event',
  },
  broadcast: {
    ru: 'трансляция',
    en: 'broadcast',
  },
  conference: {
    ru: 'конференция',
    en: 'conference',
  },
  yes: {
    ru: 'да',
    en: 'yes',
  },
  no: {
    ru: 'нет',
    en: 'no',
  },
  cancel: {
    ru: 'Отмена',
    en: 'Cancel',
  },
  of: {
    ru: 'из',
    en: 'of',
  },
  days: {
    ru: 'дн.',
    en: 'days',
  },
  hours: {
    ru: 'ч',
    en: 'hours',
  },
  minutes: {
    ru: 'мин.',
    en: 'minutes',
  },
  rubles: {
    ru: 'руб.',
    en: 'rub.',
  },
  priceFree: {
    ru: 'Бесплатно',
    en: 'Free',
  },
  send: {
    ru: 'Отправить',
    en: 'Send',
  },
  download: {
    ru: 'скачать',
    en: 'download',
  },
  loading: {
    ru: 'Загрузка...',
    en: 'Loading...',
  },
  startIn: {
    ru: 'начнется через',
    en: 'is starting in',
  },
  goTo: {
    ru: 'Перейти',
    en: 'Go to',
  },
  goBack: {
    ru: 'Вернуться',
    en: 'Go back',
  },
  form_Save: {
    ru: 'Сохранить изменения',
    en: 'Save changes',
  },
  form_Required: {
    ru: 'Это обязательное поле',
    en: 'This field is required',
  },
  form_LoadPhoto: {
    ru: 'загрузить фото',
    en: 'load photo',
  },
  form_NothingSelected: {
    ru: 'нет',
    en: 'none',
  },
  dataIsSaved: {
    ru: 'Данные успешно сохранены',
    en: 'The data is saved successfully',
  },
  notFound_Title: {
    ru: 'Не найдено',
    en: 'Not Found',
  },
  notFound_Excuse: {
    ru:
      'Извините, запрошенный вами ресурс не найден. Возможно, он был удален или перемещен.',
    en: 'Sorry, the resource is not avaliable.',
  },
  notAuth_Title: {
    ru: 'Доступ закрыт',
    en: 'Access Denied',
  },
  notAuth_Excuse: {
    ru:
      'Извините, запрошенный Вами ресурс доступен только зарегистрированным пользователям.',
    en: 'Sorry, the resource is avaliable only for authorized users.',
  },
  nav_Home: {
    ru: 'На главную',
    en: 'Home',
  },
  nav_Archive: {
    ru: 'Архив',
    en: 'Archive',
  },
  nav_Login: {
    ru: 'Войти',
    en: 'Login',
  },
  nav_Logout: {
    ru: 'Выйти',
    en: 'Logout',
  },
  nav_BackToCatalog: {
    ru: 'к списку',
    en: 'back to catalog',
  },
  nav_BackToProfile: {
    ru: 'к личному кабинету',
    en: 'back to profile',
  },
  catalog_Period: {
    ru: 'Временной отрезок',
    en: 'Period',
  },
  catalog_Specialities: {
    ru: 'Специальности',
    en: 'Specialities',
  },
  events_Empty: {
    ru: 'По вашему запросу ничего не найдено',
    en: 'Sorry, no mathcing events were found',
  },
  event_Date: {
    ru: 'Дата',
    en: 'Date',
  },
  event_Start: {
    ru: 'Начало',
    en: 'Start Time',
  },
  event_Type: {
    ru: 'Тип',
    en: 'Type',
  },
  event_EventTitle: {
    ru: 'Мероприятие',
    en: 'Event',
  },
  event_Entrance: {
    ru: 'Вход',
    en: 'Entrance',
  },
  event_NmoScore: {
    ru: 'Баллы НМО',
    en: 'NMO score',
  },
  event_NmoCode: {
    ru: 'Код НМО',
    en: 'NMO code',
  },
  event_Score: {
    ru: 'Баллы',
    en: 'Score',
  },
  event_Duration: {
    ru: 'Время',
    en: 'Duration',
  },
  event_Location: {
    ru: 'Место',
    en: 'Location',
  },
  event_TargetAudience: {
    ru: 'Целевая аудитория',
    en: 'Target audience',
  },
  event_Description: {
    ru: 'Описание',
    en: 'Description',
  },
  event_ControlInfo: {
    ru: 'Информация о контроле присутствия',
    en: 'Presence control',
  },
  event_HasFinished: {
    ru: 'Мероприятие завершено',
    en: 'The event has finished',
  },
  event_DoRegister: {
    ru: 'Зарегистрироваться',
    en: 'Register',
  },
  event_CancelRegistration: {
    ru: 'Отменить регистрацию',
    en: 'Cancel registration',
  },
  event_RegistrationFinished: {
    ru: 'Регистрация завершена',
    en: 'Registration has finished',
  },
  event_QuotaExceeded: {
    ru: 'Исчерпана квота участников',
    en: 'The event quota is exceeded',
  },
  event_Registered: {
    ru: 'Вы зарегистрированы',
    en: 'You have registered',
  },
  event_RegisteredAsSpeaker: {
    ru: 'Вы зарегистрированы как спикер',
    en: 'You are registered as a speaker',
  },
  event_GoToBroadcast: {
    ru: 'Подключиться к трансляции',
    en: 'Go to the broadcast',
  },
  event_Organizers: {
    ru: 'Организаторы мероприятия',
    en: 'Organizers',
  },
  event_Sponsors: {
    ru: 'Спонсоры',
    en: 'Sponsors',
  },
  event_SheduleIsEmpty: {
    ru: 'Расписание мероприятия не заполнено',
    en: 'Shedule is empty',
  },
  event_NotPublished: {
    ru: 'Данное мероприятие недоступно на русском языке',
    en: 'This event is unavailable in English',
  },
  user_VisitedEvents: {
    ru: 'Посещенные мероприятия',
    en: 'Visited Events',
  },
  user_VisitedEventsNone: {
    ru: 'Вы еще не посетили ни одного мероприятия',
    en: 'You have not visited any events yet',
  },
  user_ChangePassword: {
    ru: 'Смена пароль',
    en: 'Change password',
  },
  payment_Check: {
    ru: 'Проверка платежа',
    en: 'Paymnet check',
  },
  payment_NotFound: {
    ru: 'Платеж не найден',
    en: 'Payment not found',
  },
  payment_Checking: {
    ru: 'Получаем информацию о платеже. Пожалуйста, подождите...',
    en: 'Retrieveing the payment data. Please, wait...',
  },
  payment_Retrieving: {
    ru: 'Выполняется проверка платежа. Пожалуйста, подождите...',
    en: 'Checking the paymnt status. Please, wait...',
  },
  payment_NothingToCheck: {
    ru:
      'Мероприятия с незавершенными платежами не найдены. Попробуйте повторно зайти на страницу мероприятия',
    en:
      'No events with unfinished payments were found. Plese, try to go back to the event page',
  },
  payment_NotFinished: {
    ru: 'Платеж не был завершен',
    en: 'The payment is not finished yet',
  },
  payment_Success: {
    ru: 'Оплата успешно завершена',
    en: 'The payment was finished successfully',
  },
  payment_ToBank: {
    ru: 'на страницу оплаты',
    en: 'to the payment page',
  },
  payment_ToEvent: {
    ru: 'на страницу мероприятия',
    en: 'to the event page',
  },
  chat_MessageDeleted: {
    ru: 'Сообщение удалено',
    en: 'Message deleted',
  },
  chat_MessageEditing: {
    ru: 'Редактирование',
    en: 'Editing',
  },
  chat_NewMessage: {
    ru: 'Новое сообщение',
    en: 'New message',
  },
  chat_Placeholder: {
    ru: 'Написать...',
    en: 'Type here...',
  },
  chat_DoDisable: {
    ru: 'Выключить чат',
    en: 'Disable chat',
  },
  chat_DoEnable: {
    ru: 'Включить чат',
    en: 'Enable chat',
  },
  chat_Disabled: {
    ru: 'Модератор отключил отправку сообщений',
    en: 'The chat was disabled by moderator',
  },
  chat_UsersOnline: {
    ru: 'Зрителей онлайн',
    en: 'Viewers online',
  },
  quiz_PresenceControl: {
    ru: 'Контроль присутствия',
    en: 'Presence Control',
  },
  quiz_Answer: {
    ru: 'Ответьте на вопрос',
    en: 'Answer the question',
  },
  quiz_SendAnswer: {
    ru: 'Ответить',
    en: 'Send answer',
  },
  profile: {
    ru: 'Личный кабинет',
    en: 'Profile',
  },
  profile_Edit: {
    ru: 'Редактировать профиль',
    en: 'Edit profile',
  },
  profile_FirstName: {
    ru: 'Имя',
    en: 'First name',
  },
  profile_MiddleName: {
    ru: 'Отчество',
    en: 'Middle name',
  },
  profile_LastName: {
    ru: 'Фамилия',
    en: 'Last name',
  },
  profile_Speciality: {
    ru: 'Специальность',
    en: 'Speciality',
  },
  profile_Phone: {
    ru: 'Контактный номер',
    en: 'Phone number',
  },
  profile_Email: {
    ru: 'email',
    en: 'email',
  },
  profile_City: {
    ru: 'Город',
    en: 'City',
  },
  profile_Workplace: {
    ru: 'Место работы',
    en: 'Work place',
  },
  profile_Position: {
    ru: 'Должность',
    en: 'Position',
  },
  profile_YourScore: {
    ru: 'Ваше количество баллов',
    en: 'Your total score',
  },
  profile_Uncompleted: {
    ru: 'Пожалуйста, заполните профиль. ',
    en: 'Please, complete your profile. ',
  },
  profile_MarkedFields: {
    ru: 'Отмеченные ',
    en: 'Fields marked with ',
  },
  profile_MarkedFields2: {
    ru: ' поля обязательны для возможности регистрации на мероприятия.',
    en: ' are required to register on events.',
  },
  profile_PersonalInfoAgreement_pre: {
    ru: 'Я даю ',
    en: 'I agree to ',
  },
  profile_PersonalInfoAgreement_link: {
    ru: 'согласие на обработку и передачу личных данных и на сбор статистики',
    en:
      'the processing and transfer of personal data and to the collection of statistics',
  },
  contacts: {
    ru: 'Контакты',
    en: 'Contacts',
  },
  contacts_Title: {
    ru:
      'Федеральное государственное бюджетное образовательное учреждение высшего образования «Самарский государственный медицинский университет» Министерства здравоохранения Российской Федерации (ФГБОУ ВО СамГМУ Минздрава России).',
    en: 'Samara State Medical University',
  },
  contacts_Department: {
    ru: 'Институт профессионального образования (ИПО)',
    en: 'Department of Professional Education (IPO)',
  },
  contacts_Address: {
    ru: 'Адрес: 443099, РФ, г.Самара, ул. Чапаевская, 89',
    en: 'Address: 89 Chapayevskaya St., Samara, Russia, 443099',
  },
  contacts_Phone: {
    ru: '8 (800) 301 57 73 (с 10.00 до 17.00 в рабочие дни)',
    en: '+7 (800) 301 57 73 (10.00-17.00 (GMT+4) Monday-Friday)',
  },
  photos: {
    ru: 'Фотогалерея',
    en: 'Photos',
  },
  errors_onLoad: {
    ru: 'Произошла ошибка при загрузке данных',
    en: 'An error occured while loading the data',
  },
  errors_onLoadProfile: {
    ru: 'Произошла ошибка при загрузке профиля пользователя',
    en: 'An error occured while loading the user profile',
  },
  errors_onLoadVisitedEvents: {
    ru:
      'Произошла ошибка при загрузке списка посещенных мероприятий пользователя',
    en: 'An error occured while loading the visited events',
  },
  errors_onLoadSpecialities: {
    ru: 'Не удалось загрузить специальности',
    en: 'Loading specialities failed',
  },
  errors_onLoadPersonalInfoAgreement: {
    ru:
      'Не удалось получить ссылку на файл согласия на обработку и передачу личных данных и на сбор статистики',
    en:
      'Failed loading file link to the processing and transfer of personal data and to the collection of statistics',
  },
  errors_onSave: {
    ru: 'Произошла ошибка при сохранении данных. Попробуйте позже',
    en:
      'An error occured while trying to save the data. Please, try agian later',
  },
  errors_onSaveProfile: {
    ru: 'Произошла ошибка при сохранении профиля пользователя',
    en: 'An error occured while trying to save the user profile',
  },
  errors_onSavePhoto: {
    ru: 'Произошла ошибка при сохранении фото пользователя',
    // eslint-disable-next-line quotes
    en: "An error occured while trying to save the user's photo",
  },
  errors_onRegister: {
    ru: 'Не удалось зарегистрироваться на мероприятие. Попробуйте позже',
    en: 'An error occured while trying to register. Please, try agian later',
  },
  errors_onCancelRegistration: {
    ru: 'Произошла ошибка при отмене регистрациии. Попробуйте позже',
    en:
      'An error occured while trying to cancel the registration. Please, try agian later',
  },
  errors_onCheckRegistration: {
    ru: 'Не удалось проверить регистрацию на мероприятие',
    en: 'An attempt to check the registration status was failed',
  },
  errors_onCheckPayment: {
    ru: 'Произошла ошибка при проверке платежа',
    en: 'An attempt to check the payment status was failed',
  },
  errors_onWebinarNotAuthorized: {
    ru: 'Только зарегистрированные пользователи имеют доступ к трансляции',
    en: 'Only authorized users have access to the webinar',
  },
  errors_onWebinarNotRegistered: {
    ru: 'Вы не зарегистрированы на данное мероприятие',
    en: 'You are not registered for this webinar',
  },
  errors_onWebinarCheckRegisteration: {
    ru:
      'Не удалось проверить регистрацию на мероприятие. Попробуйте выйти и войти заново',
    en:
      'An attempt to check the registration status was failed. Try to relogin',
  },
  errors_underDevelopment: {
    ru: 'Извините, страница в разработке',
    en: 'Sorry, the page is yet under development',
  },
};
