import { Organizer } from '../Organizer';

export interface OrganizerRestApiDto {
  id: string;
  logoId: string | null;
  logoLink: string | null;
  name: string | null;
  pageLink: string | null;
  primary: boolean | null;
  order: number | null;
}

export function mapOrganizerRestApiDto(
  organizer: OrganizerRestApiDto,
): Organizer {
  return {
    id: organizer.id,
    logo: organizer.logoId
      ? { fileId: organizer.logoId }
      : organizer.logoLink
      ? { fileId: organizer.logoLink }
      : undefined,
    name: organizer.name || '',
    url: organizer.pageLink || '',
    primary: organizer.primary || undefined,
    order: organizer.order || 0,
  };
}
