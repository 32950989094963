/* eslint-disable @typescript-eslint/no-empty-function */
import React, { FC } from 'react';
import { useContext, createContext } from 'react';
import { Message } from '../../_data/Message';
import { AnswerOption, Question } from '../../_data/Question';
import { mockMessages } from '../../_data/mocks/MockChat';
import { mockQuestions } from '../../_data/mocks/MockQuestions';
import { SheduledEvent } from '../../_data/SheduledEvent';
import {
  ChatMessageWsDto,
  MapChatMessageWsDto,
  UnmapChatMessageWsDto,
} from '../../_data/wsDto/ChatMessageWsDto';
import {
  MapQuestionWsDto,
  QuestionWsDto,
} from '../../_data/wsDto/QuestionWsDto';
import { useMocks } from '../../restapi/backendPaths';
import { useAuthContext } from '../../auth/AuthContext';
import { chatUrl, quizUrl } from '../../restapi/backendPaths';
import SockJS from 'sockjs-client';
import { Client, IStompSocket } from '@stomp/stompjs';
import { useLocalizationContext } from '../../_localization/LocalizationContext';
import { useEventRestApi } from '../EventPage.utils';
import { customAlphabet } from 'nanoid';
import { alphanumeric } from 'nanoid-dictionary';
import { post } from '../../restapi/api';

const topicMessagePath = (eventId: string) => '/topic/' + eventId + '-message';
const topicMessagesPath = (eventId: string) =>
  '/topic/' + eventId + '-messages';
const topicDeleteMessagePath = (eventId: string) =>
  '/topic/' + eventId + '-delete-message';
const topicEditMessagePath = (eventId: string) =>
  '/topic/' + eventId + '-edit-message';
const sendGetMessagesPath = (eventId: string) =>
  '/app/event-' + eventId + '-messages';
const sendMessagePath = '/app/send-message';
const sendDeleteMessagePath = (eventId: string, chatMessageId: string) =>
  '/app/' + eventId + '-delete-message-' + chatMessageId;
const sendEditMessagePath = '/app/edit-message';
const topicUsersCountPath = (eventId: string) =>
  '/topic/' + eventId + '-users-count';
const topicCommands = (eventId: string) => '/topic/' + eventId + '-commands';
const sendCommandPath = '/app/send-command';

const topicQuestionPath = (eventId: string) =>
  '/user/queue/get-question-' + eventId;
const sendJoinEventPath = '/app/join-event';
const sendAnswerPath = (eventId: string, userId: string, questionId: string) =>
  '/app/send/' + eventId + '/' + userId + '/' + questionId + '/answer';

const chatDisablePath = (eventId: string, disabled: boolean) =>
  `events/${eventId}/changeChatStatus/${disabled ? 'disable' : 'enable'}`;

interface ChatCommand {
  id: string;
  eventId: string;
  userId: string;
  timestamp: Date;
  command: string;
}

interface WebinarContextProps {
  chatConnected: boolean;
  messages: Message[];
  onSendMessage: (message: Message) => void;
  onEditMessage: (message: Message) => void;
  onDeleteMessage: (message: Message) => void;
  usersCount?: number;
  chatDisabled: boolean;
  toggleChatDisabled: () => void;
  question: Question | null;
  sendAnswers: (answers: AnswerOption[]) => void;
}

export const WebinarContext = createContext<WebinarContextProps>({
  chatConnected: false,
  messages: [],
  onSendMessage: () =>
    console.error('Message cannot be sent. Webinar context is missing'),
  onEditMessage: () =>
    console.error('Message cannot be edited. Webinar context is missing'),
  onDeleteMessage: () =>
    console.error('Message cannot be deleted. Webinar context is missing'),
  usersCount: undefined,
  chatDisabled: true,
  toggleChatDisabled: () =>
    console.error('Chat cannot be disabld. Webinar context is missing'),
  question: null,
  sendAnswers: () =>
    console.error('Answer cannot be sent. Webinar context is missing'),
});

export const useWebinarContext = () => useContext(WebinarContext);

interface WebinarContextProviderProps {
  event: SheduledEvent;
}

export const WebinarContextProvider: FC<WebinarContextProviderProps> = ({
  event,
  children,
}) => {
  const [chatConnected, setChatConnected] = React.useState(false);
  const [chatMessages, setChatMessages] = React.useState<Message[]>([]);
  const [questionsQueue, setQuestionsQueue] = React.useState<Question[]>([]);
  const [question, setQuestion] = React.useState<Question | null>(null);
  const [usersCount, setUsersCount] = React.useState<number | undefined>(
    undefined,
  );
  const [chatDisabled, setChatDisabled] = React.useState(true);
  const { user } = useAuthContext();
  const { language } = useLocalizationContext();
  const { getRegistration } = useEventRestApi();
  const [role, setRole] = React.useState('');
  const [chatClient] = React.useState(new Client());
  const [quizClient] = React.useState(new Client());
  const nanoid = customAlphabet(alphanumeric, 16);
  const chatGetMessageSubscriptionId = `${event.id}-message-${nanoid()}`;
  const chatGetAllMessagesSubscriptionId = `${event.id}-messages-${nanoid()}`;
  const { accessToken: accessTokenSso } = useAuthContext();

  React.useEffect(() => {
    if (!question && questionsQueue.length > 0) {
      setQuestion(questionsQueue[0]);
      setQuestionsQueue((questionsQueue) => questionsQueue.slice(1));
    }
  }, [question, questionsQueue]);

  const messageSubscription = (msg: ChatMessageWsDto) => {
    setChatMessages((messages) => [
      ...messages.filter((m) => m.id !== msg.id),
      MapChatMessageWsDto(msg),
    ]);
  };

  const messageDeleteSubscription = (msg: ChatMessageWsDto) => {
    setChatMessages((messages) => [
      ...messages.filter((m) => m.id !== msg.id),
      MapChatMessageWsDto(msg),
    ]);
  };

  const messagesSubscription = (messages: ChatMessageWsDto[]) => {
    setChatMessages(
      messages.map((msg: ChatMessageWsDto) => MapChatMessageWsDto(msg)),
    );
    const params = new URLSearchParams(window.location.search);
    if (params.get('dontunsubscribe')) {
      chatClient.unsubscribe(chatGetAllMessagesSubscriptionId);
    }
  };

  const usersCountSubscription = (msg: number) => {
    setUsersCount(msg);
  };

  const chatCommandsSubscription = (cmd: ChatCommand) => {
    switch (cmd.command) {
      case 'DISABLE_CHAT':
        setChatDisabled(true);
        break;
      case 'ENABLE_CHAT':
        setChatDisabled(false);
        break;
    }
  };

  const toggleChatDisabled = () => {
    const newDisabled = !chatDisabled;
    const path = chatDisablePath(event.id, newDisabled);
    const chatCommand: ChatCommand = {
      id: '',
      eventId: event.id,
      userId: user?.id || '',
      timestamp: new Date(),
      command: newDisabled ? 'DISABLE_CHAT' : 'ENABLE_CHAT',
    };
    chatClient.publish({
      destination: sendCommandPath,
      body: JSON.stringify(chatCommand),
    });
    post<void>(
      path,
      undefined,
      undefined,
      accessTokenSso
        ? {
            headers: {
              SSO_TOKEN: accessTokenSso,
            },
          }
        : undefined,
    )
      .then(() => {
        setChatDisabled(newDisabled);
      })
      .catch((err) => console.error('Toggle chat disabled failed', err));
  };

  React.useEffect(() => {
    setChatDisabled(event.chatDisabled);
  }, [event.chatDisabled]);

  const questionSubscription = (
    questionDtos: QuestionWsDto | QuestionWsDto[],
  ) => {
    const newQuestions =
      'id' in questionDtos
        ? [MapQuestionWsDto(questionDtos)]
        : questionDtos.map((q) => MapQuestionWsDto(q));
    setQuestionsQueue((queue) => [...queue, ...newQuestions]);
  };

  const activateStompClient = (
    client: Client,
    url: string,
    onConnect: () => void,
  ) => {
    client.webSocketFactory = () => new SockJS(url) as IStompSocket;
    client.onConnect = onConnect;
    client.activate();
  };

  // инициализация
  React.useEffect(() => {
    if (useMocks()) {
      setTimeout(() => setChatMessages(mockMessages), 350);
      setUsersCount(1 + Math.floor(Math.random() * 100));
      const timeout = (max: number) => (Math.random() * max + 5) * 1000;
      const handler = setInterval(() => {
        const newQuestion = Object.assign(
          mockQuestions[Math.floor(Math.random() * mockQuestions.length)],
        );
        setQuestionsQueue((queue) => [...queue, newQuestion]);
        setUsersCount(1 + Math.floor(Math.random() * 100));
      }, timeout(5 * 60));
      return () => clearTimeout(handler);
    } else {
      // ЧАТ
      activateStompClient(chatClient, chatUrl, () => {
        setChatConnected(true);
        // подписки
        chatClient.subscribe(
          topicMessagePath(event.id),
          (msg) => messageSubscription(JSON.parse(msg.body)),
          {
            id: chatGetMessageSubscriptionId,
            'x-queue-name': chatGetMessageSubscriptionId,
          },
        );
        chatClient.subscribe(
          topicMessagesPath(event.id),
          (msg) => messagesSubscription(JSON.parse(msg.body)),
          {
            id: chatGetAllMessagesSubscriptionId,
          },
        );
        chatClient.subscribe(topicDeleteMessagePath(event.id), (msg) =>
          messageDeleteSubscription(JSON.parse(msg.body)),
        );
        chatClient.subscribe(topicEditMessagePath(event.id), (msg) =>
          messageSubscription(JSON.parse(msg.body)),
        );
        chatClient.subscribe(topicUsersCountPath(event.id), (msg) =>
          usersCountSubscription(JSON.parse(msg.body)),
        );
        chatClient.subscribe(topicCommands(event.id), (msg) =>
          chatCommandsSubscription(JSON.parse(msg.body)),
        );
        // запрос существующих сообщений
        setTimeout(
          () =>
            chatClient.publish({ destination: sendGetMessagesPath(event.id) }),
          500,
        );
      });

      return () => {
        chatClient.deactivate();
      };
    }
  }, []);

  React.useEffect(() => {
    if (user) {
      getRegistration(event?.id, user, language).then((registration) =>
        setRole(registration?.roles || ''),
      );
    }
  }, [user]);

  React.useEffect(() => {
    if (!useMocks() && !role) {
      // КОНТРОЛЬ
      activateStompClient(quizClient, quizUrl, () => {
        // подписки
        quizClient.subscribe(topicQuestionPath(event.id), (msg) =>
          questionSubscription(JSON.parse(msg.body)),
        );
        // регистрация
        quizClient?.publish({
          destination: sendJoinEventPath,
          body: JSON.stringify({
            eventId: event.id,
            userId: user?.id,
            lang: language,
          }),
        });
      });

      return () => {
        quizClient?.deactivate();
      };
    }
  }, [role]);

  // отправка сообщений
  const onSendMessage = (message: Message) => {
    if (useMocks()) {
      message.id = Math.random() + '';
      setChatMessages((messages) => [...messages, message]);
    } else if (chatConnected) {
      chatClient.publish({
        destination: sendMessagePath,
        body: JSON.stringify(UnmapChatMessageWsDto(message)),
      });
    }
  };

  const onEditMessage = (message: Message) => {
    if (useMocks()) {
      setChatMessages((messages) => [
        ...messages.filter((msg) => msg.id !== message.id),
        message,
      ]);
    } else if (chatConnected) {
      chatClient.publish({
        destination: sendEditMessagePath,
        body: JSON.stringify(UnmapChatMessageWsDto(message)),
      });
    }
  };

  const onDeleteMessage = (message: Message) => {
    if (useMocks()) {
      setChatMessages((messages) => [
        ...messages.filter((msg) => msg.id !== message.id),
        { ...message, deletedDate: new Date() },
      ]);
    } else if (chatConnected && message.id) {
      chatClient.publish({
        destination: sendDeleteMessagePath(message.eventId, message.id),
        body: JSON.stringify(UnmapChatMessageWsDto(message)),
      });
    }
  };

  // ответы на вопрос
  const sendAnswers = (answers: AnswerOption[]) => {
    if (useMocks()) {
      console.log('Answer sent', answers);
    } else if (chatConnected && user && question) {
      quizClient.publish({
        destination: sendAnswerPath(event.id, user.id, question.id),
        body: JSON.stringify(answers.map((a) => ({ id: a.id }))),
      });
    }
    setQuestion(null);
  };

  return (
    <WebinarContext.Provider
      value={{
        chatConnected,
        messages: chatMessages,
        onSendMessage,
        onEditMessage,
        onDeleteMessage,
        usersCount,
        chatDisabled,
        toggleChatDisabled,
        question,
        sendAnswers,
      }}
    >
      {children}
    </WebinarContext.Provider>
  );
};
