import './SelectLanguage.css';
import React from 'react';
import { useLocalizationContext } from '../_localization/LocalizationContext';
import { Language as LanguageIcon } from '@material-ui/icons';

export const SelectLanguage: React.FC = () => {
  const { language, setLanguage } = useLocalizationContext();
  return (
    <div className="selectLanguage-container">
      <div className="selectLanguage">
        <LanguageIcon fontSize="small" />
        {language === 'ru' ? (
          <button onClick={() => setLanguage('en')}>English</button>
        ) : (
          <button onClick={() => setLanguage('ru')}>Русский</button>
        )}
      </div>
    </div>
  );
};
