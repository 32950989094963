import './InnerPage.css';
import logoNotext from '../_img/SamGMU Logo NoText.svg';

import React from 'react';
import { ReactNode } from 'react';
import AuthHeader from '../auth/AuthHeader';
import { Link } from 'react-router-dom';
import appRoutes from '../navigation/routes';
import { SelectLanguage } from '../_localization/SelectLanguage';
import { useLocalizationContext } from '../_localization/LocalizationContext';
import { uiMessages } from '../_localization/ui.messages';

interface InnerPageProps {
  header: ReactNode;
  middle?: ReactNode;
  className?: string;
  headerClasses?: string;
  headerBackgroundUrl?: string;
  backLink?: { title: string; link: string };
}

export const InnerPage: React.FC<InnerPageProps> = ({
  className,
  header,
  middle,
  backLink,
  headerBackgroundUrl,
  children,
}) => {
  const { language } = useLocalizationContext();
  return (
    <div className={'pageInner' + (className ? ' ' + className : '')}>
      <div
        className="inverted-bg pageHeader"
        style={{
          backgroundImage: headerBackgroundUrl
            ? 'url(' + headerBackgroundUrl + ')'
            : '',
        }}
      >
        <div className="pageHeader-top">
          <div className="pageHeader-logo">
            <img src={logoNotext} />
          </div>
          <div className="pageHeader-back">
            <Link
              to={backLink?.link || appRoutes.home}
              className="pageHeader-backlink"
            >
              {backLink?.title || uiMessages.nav_BackToCatalog[language]}
            </Link>
          </div>
          <div className="pageHeader-lang">
            <SelectLanguage />
          </div>
          <div className="pageHeader-auth">
            <AuthHeader className="inner" />
          </div>
        </div>
        {header}
      </div>
      {middle && <div className="pageInner-middle">{middle}</div>}
      <div className="pageInner-main">{children}</div>
    </div>
  );
};
