import './User.css';
import React from 'react';
import appRoutes from '../navigation/routes';
import { Route, Switch } from 'react-router-dom';
import NotFoundPage from '../layout/NotFoundPage';
import UserEditProfile from './UserEditProfile';
import UserChangePassword from './UserChangePassword';
import UserAccount from './UserAccount';

const UserPage: React.FC = () => {
  return (
    <Switch>
      <Route exact path={appRoutes.profile} component={UserEditProfile} />
      <Route
        exact
        path={appRoutes.changePassword}
        component={UserChangePassword}
      />
      <Route exact path={appRoutes.user} component={UserAccount} />
      <Route component={NotFoundPage} />
    </Switch>
  );
};

export default UserPage;
