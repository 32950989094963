import { Message } from '../Message';

export const mockMessages: Message[] = [
  {
    id: '1',
    userId: '1',
    userName: 'Елена',
    timestamp: new Date(Date.now() - 249 * 1000),
    message: ' Первая! Всем привет!',
    eventId: '0d06e6aa-10ca-4e0a-8c26-d9a09f7abe0f',
  },
  {
    id: '2',
    userId: '2',
    userName: 'Василий Красильников',
    timestamp: new Date(Date.now() - 123 * 1000),
    message: 'А подскажите пожалуйста кто ведет операцию, возможно я пропустил',
    eventId: '0d06e6aa-10ca-4e0a-8c26-d9a09f7abe0f',
  },
  {
    id: '3',
    userId: '3',
    userName: 'Иванова Ирина',
    timestamp: new Date(Date.now() - 10 * 1000),
    message:
      'заведующая лабораторией ВРТ, Госпиталь на Мичуринском, главный эмбриолог сети клиник Фомина',
    eventId: '0d06e6aa-10ca-4e0a-8c26-d9a09f7abe0f',
  },
];
