import { mockEvents } from './MockSheduledEvents';
import { SheduledEvent, VisitedEvent } from '../SheduledEvent';
import { User, UserProfile } from '../User';
import { Participant, Registration } from '../Registration';
import { Speciality } from '../Speciality';
import {
  mockRegistrations,
  mockUserProfile,
  mockVisitedEvents,
} from './MockUser';
import { specialities as mockSpecialities } from './MockSpecialities';
import { wait } from './wait';

export async function getSpecialitiesMock(): Promise<Speciality[]> {
  await wait(500);
  return mockSpecialities;
}

export async function getEventsMock(): Promise<SheduledEvent[]> {
  await wait(1500);
  const today = new Date();
  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);
  today.setMilliseconds(0);
  return mockEvents.filter((e) => e.start >= today);
}

export async function getArchiveMock(): Promise<SheduledEvent[]> {
  await wait(1200);
  const today = new Date();
  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);
  today.setMilliseconds(0);
  return mockEvents.filter((e) => e.start < today);
}

export async function getEventMock(
  id: string,
): Promise<SheduledEvent | undefined> {
  await wait(900);
  return mockEvents.find((event) => event.link === id || event.id === id);
}

export async function getRegistrationMock(
  id: string,
  user: User,
): Promise<Registration | undefined> {
  await wait(250);
  return mockRegistrations.find(
    (reg) => reg.eventId === id && reg.userId === user.id,
  );
}

export async function postRegistrationMock(
  id: string,
  user: User,
): Promise<Participant | undefined> {
  await wait(300);
  const reg = {
    userId: user.id,
    eventId: id,
    speaker: false,
  };
  mockRegistrations.push(reg);
  return reg;
}

export async function postUnregisterMock(
  id: string,
  userId: string,
): Promise<void> {
  await wait(300);
  delete mockRegistrations[
    mockRegistrations.findIndex((r) => r.eventId === id && r.userId === userId)
  ];
}

export async function getUserProfileMock(): Promise<UserProfile> {
  await wait(350);
  return Object.assign(mockUserProfile);
}

export async function getUserVisitedEventsMock(): Promise<VisitedEvent[]> {
  await wait(450);
  return Object.assign(mockVisitedEvents);
}

export async function postUserProfileMock(profile: UserProfile): Promise<void> {
  await wait(1200);
  Object.keys(profile).forEach((key) => {
    (mockUserProfile as any)[key as keyof UserProfile] =
      profile[key as keyof UserProfile] || '';
  });
}
