import React from 'react';
import { useHistory } from 'react-router-dom';
import { SheduledEvent } from '../../_data/SheduledEvent';
import { differenceInMinutes } from 'date-fns';
import { Link } from 'react-router-dom';
import appRoutes, { getActualLink } from '../../navigation/routes';
import { useAuthContext } from '../../auth/AuthContext';
import { useEventRestApi } from '../EventPage.utils';
import { SendingSpinner } from '../../_components/Spinner';
import { ErrorMessage } from '../../_components/Messages';
import { Registration } from '../../_data/Registration';
import { EventHeaderCountdown } from './EventHeaderCountdown';
import { isUserProfileUncompleted } from '../../_data/User';
import { useLocalizationContext } from '../../_localization/LocalizationContext';
import { uiMessages } from '../../_localization/ui.messages';
import { PublicOffer, PublicOfferProps } from '../payment/PublicOffer';

interface EventHeaderRegisterProps {
  event: SheduledEvent;
}
export const EventHeaderRegister: React.FC<EventHeaderRegisterProps> = ({
  event,
}) => {
  const { login, user, userProfile } = useAuthContext();
  const history = useHistory();
  const { language } = useLocalizationContext();
  const [countdown, setCountdown] = React.useState(0);
  const [sending, setSending] = React.useState(!!user);
  const [error, setError] = React.useState('');
  const [registration, setRegistration] = React.useState<Registration | null>(
    null,
  );
  const {
    getRegistration,
    postRegistration,
    postUnregister,
    getPublicOffer,
  } = useEventRestApi();
  const [publicOffer, setPublicOffer] = React.useState<
    PublicOfferProps | undefined
  >(undefined);
  const [offerAccepted, setOfferAccepted] = React.useState<boolean | undefined>(
    false,
  );
  const [tryAccept, setTryAccept] = React.useState(false);

  const checkRegistration = () => {
    if (!user || registration) return;
    setSending(true);
    getRegistration(event.id, user)
      .then((reg) => {
        setSending(false);
        setRegistration(reg || null);
      })
      .catch(() => {
        setSending(false);
        setError(uiMessages.errors_onCheckRegistration[language]);
      });
  };

  React.useEffect(() => {
    checkRegistration();
  }, [user?.id, registration]);

  function updateCountdown() {
    setCountdown(differenceInMinutes(event.start, new Date()));
  }

  React.useEffect(() => {
    updateCountdown();
    const intervalId = setInterval(updateCountdown, 30 * 1000);
    return () => clearInterval(intervalId);
  }, []);

  const tryRegister = async () => {
    if (!user || !event || registration) return;
    if (!userProfile || isUserProfileUncompleted(userProfile)) {
      history.push(appRoutes.profile);
      return;
    }
    setSending(true);
    localStorage.setItem(
      'paymentsOf_' + user.id,
      [
        ...(localStorage
          .getItem('paymentsOf_' + user.id)
          ?.split(',')
          .filter((id) => id !== event.id) || []),
        event.id,
      ].join(','),
    );
    if (event?.price && !offerAccepted) {
      setSending(true);
      setOfferAccepted(undefined);
      setTryAccept(true);
      getPublicOffer()
        .then((fileId) => {
          setPublicOffer({
            publicOfferFileId: fileId,
            doAccept: () => {
              setOfferAccepted(true);
              setTryAccept(false);
            },
            doCancel: () => {
              setOfferAccepted(false);
              setSending(false);
              setTryAccept(false);
            },
          });
        })
        .catch(() => {
          setError(uiMessages.errors_onLoad[language]);
          setSending(false);
        });
      return;
    }
    postRegistration(event.id, user)
      .then((result) => {
        if (!result || ('error' in result && result.error)) {
          setSending(false);
          throw new Error();
        } else if (offerAccepted && 'redirectUrl' in result) {
          window.location.assign(result.redirectUrl);
        } else {
          setSending(false);
          setRegistration(null);
          checkRegistration();
        }
      })
      .catch(() => {
        setSending(false);
        setError(uiMessages.errors_onRegister[language]);
      });
  };

  React.useEffect(() => {
    if (publicOffer && offerAccepted) {
      tryRegister();
    }
  }, [publicOffer, offerAccepted]);

  const tryUnregister = async () => {
    if (!user || !registration) return;
    setSending(true);
    postUnregister(event.id, user)
      .then(() => {
        setSending(false);
        setRegistration(null);
        checkRegistration();
      })
      .catch(() => {
        setSending(false);
        setError(uiMessages.errors_onCancelRegistration[language]);
      });
  };

  const eventIsFinished =
    event.end && event.end < new Date() && countdown < -event.duration;
  const regitrationYetOpen =
    !registration &&
    (countdown > 0 ||
      (event.registrationOverdue &&
        countdown >= -event.registrationOverdue / 60));
  const regitrationQuotaRemained =
    !event.quota ||
    event.remainingQuota === undefined ||
    event.remainingQuota > 0;

  return (
    <>
      <div className="eventHeader-register">
        {tryAccept && publicOffer && offerAccepted === undefined && (
          <PublicOffer {...publicOffer} />
        )}
        <div className="eventHeader-register-action">
          {eventIsFinished ? (
            <div className="finished">
              {uiMessages.event_HasFinished[language]}
            </div>
          ) : sending ? (
            <SendingSpinner />
          ) : error ? (
            <ErrorMessage>{error}</ErrorMessage>
          ) : !user ? (
            <button className="button" onClick={() => login()}>
              {uiMessages.nav_Login[language]}
            </button>
          ) : regitrationYetOpen ? (
            regitrationQuotaRemained ? (
              <button className="button" onClick={tryRegister}>
                {uiMessages.event_DoRegister[language]}{' '}
                {event.price
                  ? `(${event.price} ${uiMessages.rubles[language]})`
                  : ''}
              </button>
            ) : (
              <div className="highlited">
                {uiMessages.event_RegistrationFinished[language]}
                <br />
                {uiMessages.event_QuotaExceeded[language]}
              </div>
            )
          ) : registration && countdown > 0 ? (
            registration?.roles?.match('speaker') ? (
              <div className="highlited">
                {uiMessages.event_RegisteredAsSpeaker[language]}
              </div>
            ) : event.price && event.price > 0 ? (
              <div className="highlited">
                {uiMessages.event_Registered[language]}
              </div>
            ) : (
              <button className="button" onClick={tryUnregister}>
                {uiMessages.event_CancelRegistration[language]}
              </button>
            )
          ) : !registration ? (
            <div className="finished">
              {uiMessages.event_RegistrationFinished[language]}
            </div>
          ) : (
            <Link
              to={getActualLink(appRoutes.webinar, [
                { key: 'id', value: event.id },
              ])}
            >
              <button className="button active">
                {uiMessages.event_GoToBroadcast[language]}
              </button>
            </Link>
          )}
        </div>
        {countdown > 0 && (
          <EventHeaderCountdown
            countdown={countdown}
            isConference={event.format === 'Аудитория'}
          />
        )}
      </div>
    </>
  );
};
