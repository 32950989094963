import React from 'react';
import { SubmittingSpinner } from '../_components/Spinner';
import { FileLink } from '../_data/FileLink';
import { getApiFileUrl } from './backendPaths';
import { useRestApiContext } from './RestApiContext';

interface ImgBackendProps {
  srcFile: FileLink;
}

const ImgBackend: React.FC<
  ImgBackendProps & React.ImgHTMLAttributes<HTMLImageElement>
> = ({ srcFile, ...imgProps }) => {
  const { accessToken, loadingToken } = useRestApiContext();

  if (!srcFile.fileId && !srcFile.url) {
    return null;
  }

  if (srcFile.fileId && loadingToken) {
    return <SubmittingSpinner />;
  }
  return (
    <img
      {...imgProps}
      src={
        srcFile.fileId
          ? getApiFileUrl(srcFile.fileId, accessToken)
          : srcFile.url
      }
    />
  );
};

export default ImgBackend;
