import './AuthHeader.css';
import React from 'react';
import { useAuthContext } from './AuthContext';
import { Link, useHistory } from 'react-router-dom';
import {
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@material-ui/core';
import { PersonOutline } from '@material-ui/icons';
import appRoutes from '../navigation/routes';
import ImgBackend from '../restapi/ImgBackend';
import { getUserName, UserProfile } from '../_data/User';
import { uiMessages } from '../_localization/ui.messages';
import { useLocalizationContext } from '../_localization/LocalizationContext';

interface AuthHeaderMenuProps {
  user?: UserProfile;
  logout: () => void;
}

const AuthHeaderMenu: React.FC<AuthHeaderMenuProps> = ({
  user,
  logout,
  children,
}) => {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLAnchorElement>(null);
  const history = useHistory();
  const { language } = useLocalizationContext();

  return (
    <>
      <a
        onClick={() => setMenuOpen(true)}
        ref={anchorRef}
        className="authHeader-user"
      >
        {children}
      </a>
      <Popper
        open={menuOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        className="authHeader-user-menu dropdown"
        placement="bottom-end"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
              <Paper>
                <MenuList
                  autoFocusItem={menuOpen}
                  onKeyDown={(event: React.KeyboardEvent) => {
                    if (event.key === 'Tab') {
                      event.preventDefault();
                      setMenuOpen(false);
                    }
                  }}
                >
                  <MenuItem component="label" selected>
                    {user && getUserName(user)}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setMenuOpen(false);
                      history.push(appRoutes.user);
                    }}
                  >
                    {uiMessages.profile[language]}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setMenuOpen(false);
                      logout();
                    }}
                  >
                    {uiMessages.nav_Logout[language]}
                  </MenuItem>
                </MenuList>
              </Paper>
            </ClickAwayListener>
          </Grow>
        )}
      </Popper>
    </>
  );
};

interface AuthHeaderProps {
  className?: string;
}

const AuthHeader: React.FC<AuthHeaderProps> = ({ className }) => {
  const { user, userProfile, login, logout } = useAuthContext();
  const actualUser = userProfile || user;
  const { language } = useLocalizationContext();

  return (
    <div className={'authHeader' + (className ? ' ' + className : '')}>
      {actualUser ? (
        <AuthHeaderMenu logout={logout} user={userProfile}>
          <div className="authHeader-name">{getUserName(actualUser)}</div>
          <div className="authHeader-avatar avatar">
            {userProfile?.photo ? (
              <ImgBackend
                srcFile={userProfile?.photo}
                title={getUserName(actualUser)}
              />
            ) : (
              <PersonOutline fontSize="small" />
            )}
          </div>
        </AuthHeaderMenu>
      ) : (
        <Link className="authHeader-login" to="#" onClick={() => login()}>
          <div className="authHeader-name">
            {uiMessages.nav_Login[language]}
          </div>
          <PersonOutline fontSize="large" />
        </Link>
      )}
    </div>
  );
};

export default AuthHeader;
