import { Speciality } from '../_data/Speciality';
import { SheduledEvent } from '../_data/SheduledEvent';
import {
  getArchiveMock,
  getEventsMock,
  getSpecialitiesMock,
} from '../_data/mocks/mockRequests';
import { useMocks } from '../restapi/backendPaths';
import {
  mapSpecialityRestApiDto,
  SpecialityRestApiDto,
} from '../_data/restApiDto/SpecialityRestApiDto';
import {
  EventRestApiDto,
  mapEventRestApiDto,
} from '../_data/restApiDto/EventRestApiDto';
import { checkHttpResult, get } from '../restapi/api';
import { useRestApiContext } from '../restapi/RestApiContext';
import { Language } from '../_localization/avaliable.languages';
import { useLocalizationContext } from '../_localization/LocalizationContext';
import { uiMessages } from '../_localization/ui.messages';

const getSpecialitiesPath = (lang: Language = 'ru') =>
  `events/specialties?lang=${lang}`;
const getEventsPath = (lang: Language = 'ru') =>
  `events?active=true&lang=${lang}`;
const getArchivePath = (lang: Language = 'ru') =>
  `events?active=false&lang=${lang}`;

export function useCatalogRestApi(): {
  getSpecialities: (lang?: Language) => Promise<Speciality[] | undefined>;
  getEvents: (lang?: Language) => Promise<SheduledEvent[] | undefined>;
  getArchive: (lang?: Language) => Promise<SheduledEvent[] | undefined>;
} {
  const { accessToken: accessTokenRestApi } = useRestApiContext();
  const { language } = useLocalizationContext();

  async function getSpecialities(
    lang: Language = language,
  ): Promise<Speciality[] | undefined> {
    if (useMocks()) {
      return getSpecialitiesMock();
    }
    return get<SpecialityRestApiDto[]>(
      getSpecialitiesPath(lang),
      accessTokenRestApi,
    ).then((result) =>
      checkHttpResult(result, uiMessages.errors_onLoad[language]).map(
        mapSpecialityRestApiDto,
      ),
    );
  }

  async function getEvents(
    lang: Language = language,
  ): Promise<SheduledEvent[] | undefined> {
    if (useMocks()) {
      return getEventsMock();
    }
    return get<EventRestApiDto[]>(
      getEventsPath(lang),
      accessTokenRestApi,
    ).then((result) =>
      checkHttpResult(result, uiMessages.errors_onLoad[language]).map(
        mapEventRestApiDto,
      ),
    );
  }

  async function getArchive(
    lang: Language = language,
  ): Promise<SheduledEvent[] | undefined> {
    if (useMocks()) {
      return getArchiveMock();
    }
    return get<EventRestApiDto[]>(
      getArchivePath(lang),
      accessTokenRestApi,
    ).then((result) =>
      checkHttpResult(result, uiMessages.errors_onLoad[language]).map(
        mapEventRestApiDto,
      ),
    );
  }
  return { getSpecialities, getEvents, getArchive };
}
