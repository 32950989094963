import './Spinner.css';
import React from 'react';
import { CircularProgress, LinearProgress } from '@material-ui/core';
import { useLocalizationContext } from '../_localization/LocalizationContext';
import { uiMessages } from '../_localization/ui.messages';

interface LoadingSpinnerProps {
  text?: string;
}

export const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ text }) => {
  const { language } = useLocalizationContext();
  return (
    <div className="Spinner-loading">
      <LinearProgress />
      <div>{text || uiMessages.loading[language]}</div>
    </div>
  );
};

export const SendingSpinner: React.FC<LoadingSpinnerProps> = ({ text }) => {
  const { language } = useLocalizationContext();
  return (
    <div className="Spinner-sending">
      <CircularProgress />
      <div>{text || uiMessages.loading[language]}</div>
    </div>
  );
};

export const SubmittingSpinner: React.FC = () => {
  return (
    <div className="Spinner-submitting">
      <CircularProgress size="small" />
    </div>
  );
};
