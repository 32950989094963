export function getTodayDate(): Date {
  return new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate(),
  );
}

export function isArchiveDate(date: Date): boolean {
  return date < getTodayDate();
}
