import iconCamera from '../_img/Camera.svg';
import React, { useEffect } from 'react';
import { useAuthContext } from '../auth/AuthContext';
import UserPageTemplate from './UserPageTemplate';
import appRoutes from '../navigation/routes';
import { LoadingSpinner } from '../_components/Spinner';
import { Link } from 'react-router-dom';
import useDataLoading from '../_hooks/useDataLoading';
import { ErrorMessage } from '../_components/Messages';
import { useUserRestApi } from './User.utils';
import { VisitedEvent } from '../_data/SheduledEvent';
import UserVisitedEvents from '../eventsList/UserVisitedEvents';
import { ReactNode } from 'react';
import NotAuthorizedPage from '../layout/NotAuthorizedPage';
import { Speciality } from '../_data/Speciality';
import { useCatalogRestApi } from '../catalog/Catalog.utils';
import ImgBackend from '../restapi/ImgBackend';
import { uiMessages } from '../_localization/ui.messages';
import { useLocalizationContext } from '../_localization/LocalizationContext';

interface UserAccountInfoProps {
  title: string;
  info: ReactNode;
}

const UserAccountInfo: React.FC<UserAccountInfoProps> = ({ title, info }) => (
  <div className="UserAccount-info">
    <div>{title}</div>
    <div>{info}</div>
  </div>
);

const UserAccount: React.FC = () => {
  const { language } = useLocalizationContext();
  const { initialized, user, userProfile, logout } = useAuthContext();
  const { getUserVisitedEvents } = useUserRestApi();
  const { getSpecialities } = useCatalogRestApi();

  if (!initialized) {
    return <LoadingSpinner />;
  }

  if (!user) {
    return <NotAuthorizedPage />;
  }

  const {
    data: visitedEvents,
    loading: loadingVisitedEvents,
    error: errorVisitedEvents,
    reloadData: reloadVisitedEvents,
  } = useDataLoading<VisitedEvent[]>(() =>
    getUserVisitedEvents(user.id, language),
  );

  useEffect(() => {
    reloadVisitedEvents();
  }, [user, language]);

  const {
    data: specialities,
    loading: loadingSpecialities,
    error: errorSpecialities,
  } = useDataLoading<Speciality[] | undefined>(() => getSpecialities());
  if (loadingVisitedEvents || loadingSpecialities) {
    return <LoadingSpinner />;
  }

  const sumPoints = visitedEvents?.reduce((sum, e) => sum + e.points, 0) || 0;
  const sumMaxPoints =
    visitedEvents?.reduce((sum, e) => sum + e.maxPoints, 0) || 0;

  return (
    <UserPageTemplate
      backLink={{
        title: uiMessages.nav_BackToCatalog[language],
        link: appRoutes.home,
      }}
      header={uiMessages.profile[language]}
    >
      <div className="userAccount">
        <nav className="userAccount-nav">
          <Link to={appRoutes.profile}>
            {uiMessages.profile_Edit[language].toLowerCase()}
          </Link>
          <Link to={appRoutes.home} onClick={() => logout()}>
            {uiMessages.nav_Logout[language].toLowerCase()}
          </Link>
        </nav>
        <div className="userAccount-main">
          {!userProfile || !specialities ? (
            <ErrorMessage>
              {errorSpecialities || uiMessages.errors_onLoadProfile[language]}
            </ErrorMessage>
          ) : (
            <>
              <div className="userAccount-photo avatar">
                <ImgBackend
                  srcFile={userProfile?.photo || { url: iconCamera }}
                  className={userProfile?.photo ? '' : 'empty'}
                />
              </div>
              <div className="userAccount-personalInfo">
                <div>{userProfile.lastName}</div>
                <div>{userProfile.firstName}</div>
                <div>{userProfile.middleName}</div>
              </div>
              <div className="userAccount-specialities">
                {userProfile.specialties.map((speciality, i) => (
                  <UserAccountInfo
                    key={'speciality' + (i + 1)}
                    title={`${uiMessages.profile_Speciality[language]} ${
                      i + 1
                    }:`}
                    info={
                      specialities?.find((sp) => sp.id === speciality)?.title ||
                      uiMessages.form_NothingSelected[language]
                    }
                  />
                ))}
              </div>
              <div className="userAccount-contacts">
                <UserAccountInfo
                  title={`${uiMessages.profile_Phone[language]}:`}
                  info={userProfile.phone || '-'}
                />
                <UserAccountInfo
                  title={`${uiMessages.profile_Email[language]}:`}
                  info={
                    <a href={'mailto:' + userProfile.email}>
                      {userProfile.email}
                    </a>
                  }
                />
              </div>
              <div className="userAccount-job">
                <UserAccountInfo
                  title={`${uiMessages.profile_City[language]}:`}
                  info={userProfile.city || '-'}
                />
                <UserAccountInfo
                  title={`${uiMessages.profile_Workplace[language]}:`}
                  info={userProfile.work || '-'}
                />
                <UserAccountInfo
                  title={`${uiMessages.profile_Position[language]}:`}
                  info={userProfile.position || '-'}
                />
              </div>
              <div className="userAccount-score">
                <div className="userAccount-scorePoints">
                  <span>{sumPoints}</span>
                  <span className="userAccount-scorePoints-total">
                    /{sumMaxPoints}
                  </span>
                </div>
                <div>
                  {uiMessages.profile_YourScore[language]
                    .split(' ')
                    .map((line) => (
                      <>
                        {line}
                        <br />
                      </>
                    ))}
                </div>
              </div>
            </>
          )}
        </div>
        <div className="userAccount-events">
          {errorVisitedEvents ? (
            <ErrorMessage>
              {`${uiMessages.errors_onLoadVisitedEvents[language]}:`}
            </ErrorMessage>
          ) : (
            <UserVisitedEvents events={visitedEvents} />
          )}
        </div>
      </div>
    </UserPageTemplate>
  );
};

export default UserAccount;
