import React from 'react';
import { useLocalizationContext } from '../../_localization/LocalizationContext';
import { uiMessages } from '../../_localization/ui.messages';
import { firstUppercase } from '../../utils/strings';

interface EventHeaderCountdownProps {
  countdown: number;
  isConference: boolean;
}
export const EventHeaderCountdown: React.FC<EventHeaderCountdownProps> = ({
  countdown,
  isConference,
}) => {
  const { language } = useLocalizationContext();
  const m = countdown % 60;
  const h = Math.trunc((countdown / 60) % 24);
  const d = Math.trunc(countdown / 60 / 24);

  return (
    <div className="eventHeader-register-countdown">
      {firstUppercase(
        (language === 'en' ? 'the ' : '') + (isConference ? uiMessages.conference[language]: uiMessages.broadcast[language]),
      )}{' '}
      {uiMessages.startIn[language] + ' '}
      <span className="highlited">
        {d > 0 && `${d}\u00A0${uiMessages.days[language]} `}
        {h > 0 && `${h}\u00A0${uiMessages.hours[language]} `}
        {`${m}\u00A0${uiMessages.minutes[language]}`}
      </span>{' '}
    </div>
  );
};
