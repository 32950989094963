import React from 'react';
import { InnerPage } from '../layout/InnerPage';
import { useLocalizationContext } from '../_localization/LocalizationContext';
import { uiMessages } from '../_localization/ui.messages';

const PhotosPage: React.FC = () => {
  const { language } = useLocalizationContext();
  return (
    <InnerPage header={<h1>{uiMessages.photos[language]}</h1>}>
      <div>{uiMessages.errors_underDevelopment[language]}</div>
    </InnerPage>
  );
};

export default PhotosPage;
