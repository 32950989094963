import React from 'react';
import './App.css';
import { AppRouter } from './navigation/AppRouter';
import { ThemeProvider } from '@material-ui/core/styles';
import { appTheme } from './appTheme';
import { RestApiContextProvider } from './restapi/RestApiContext';
import { BrowserRouter } from 'react-router-dom';
import { AuthContextProvider } from './auth/AuthContext';
import { NavigationContextProvider } from './navigation/NavigationContext';
import { LocalizationContextProvider } from './_localization/LocalizationContext';

function App() {
  return (
    <ThemeProvider theme={appTheme}>
      <BrowserRouter>
        <LocalizationContextProvider>
          <NavigationContextProvider>
            <RestApiContextProvider>
              <AuthContextProvider>
                <div className="app">
                  <AppRouter />
                </div>
              </AuthContextProvider>
            </RestApiContextProvider>
          </NavigationContextProvider>
        </LocalizationContextProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
