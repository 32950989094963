import React from 'react';
import { useLocalizationContext } from '../_localization/LocalizationContext';
import { uiMessages } from '../_localization/ui.messages';
import { InnerPage } from './InnerPage';

const NotFoundPage: React.FC = () => {
  const { language } = useLocalizationContext();
  return (
    <InnerPage header={<h1>{uiMessages.notFound_Title[language]}</h1>}>
      <div>{uiMessages.notFound_Excuse[language]}</div>
    </InnerPage>
  );
};

export default NotFoundPage;
