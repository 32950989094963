import logoFull from '../_img/SamGMU Logo Full.svg';
import './CatalogAside.css';
import React from 'react';
import DateRangePicker from '../_components/DateRangePicker';
import CheckList from '../_components/CheckList';
import { useCatalogRestApi } from './Catalog.utils';
import useDataLoading from '../_hooks/useDataLoading';
import { LoadingSpinner } from '../_components/Spinner';
import { ErrorMessage } from '../_components/Messages';
import Label from '../_components/Label';
import { Speciality } from '../_data/Speciality';
import { getTodayDate } from '../utils/dates';
import { CatalogNavAside } from './CatalogNav';
import { SelectLanguage } from '../_localization/SelectLanguage';
import { uiMessages } from '../_localization/ui.messages';
import { useLocalizationContext } from '../_localization/LocalizationContext';

interface SpecialitiesListProps {
  onSpecialitiesChanged: (filteredSpecialities: Speciality[]) => void;
}

const SpecialitiesList: React.FC<SpecialitiesListProps> = ({
  onSpecialitiesChanged,
}) => {
  const { language } = useLocalizationContext();
  const { getSpecialities } = useCatalogRestApi();
  const {
    data: specialities,
    loading,
    error,
    reloadData,
  } = useDataLoading(() => getSpecialities(language));

  React.useEffect(() => {
    reloadData();
  }, [language]);

  return (
    <>
      <Label>{uiMessages.catalog_Specialities[language]}</Label>
      <div className="catalogAside-specialities-list">
        {loading && <LoadingSpinner />}
        {specialities && (
          <CheckList
            id="specialities"
            defaultOptions={specialities.map((sp) => ({
              value: sp.id,
              label: sp.title,
              checked: false,
            }))}
            onChangeSelection={(selectedOptions) =>
              onSpecialitiesChanged(
                specialities.filter((sp) =>
                  selectedOptions.some(
                    (so) => so.checked && so.value === sp.id,
                  ),
                ),
              )
            }
          />
        )}
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </div>
    </>
  );
};

interface CatalogAsideProps {
  onDatesChanged: (start: Date, end: Date) => void;
  onSpecialitiesChanged: (filteredSpecialities: Speciality[]) => void;
  onSearchChange: (search: string) => void;
  archive: boolean;
}

const CatalogAside: React.FC<CatalogAsideProps> = ({
  onDatesChanged,
  onSpecialitiesChanged,
  onSearchChange,
  archive = false,
}) => {
  const today = getTodayDate();
  const { language } = useLocalizationContext();

  return (
    <div className="catalogAside">
      <div className="catalogAside-header">
        <div className="catalogAside-logo">
          <img src={logoFull} className="logoFull" />
        </div>
        <div className="catalogAside-menu">
          {window.matchMedia('(min-width: 1200px)') && (
            <CatalogNavAside onSearchChange={onSearchChange} />
          )}
          <div className="catalogAside-lang">
            <SelectLanguage />
          </div>
        </div>
      </div>
      <div className="catalogAside-dates">
        <Label>{uiMessages.catalog_Period[language]}</Label>
        {archive ? (
          <DateRangePicker
            key="archive-dates-range"
            defaultStart={new Date(new Date().getFullYear(), 0, 1)}
            defaultEnd={today}
            onDatesChanged={onDatesChanged}
          />
        ) : (
          <DateRangePicker
            key="current-dates-range"
            defaultStart={today}
            defaultEnd={new Date(new Date().getFullYear(), 11, 31)}
            onDatesChanged={onDatesChanged}
          />
        )}
      </div>
      <div className="catalogAside-specialities">
        <SpecialitiesList onSpecialitiesChanged={onSpecialitiesChanged} />
      </div>
    </div>
  );
};

export default CatalogAside;
