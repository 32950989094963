import { User, UserProfile } from '../User';
import { Registration } from '../Registration';
import { mockEvents } from './MockSheduledEvents';
import { VisitedEvent } from '../SheduledEvent';

export const mockUser: User = {
  id: '74acfdfe-956d-4f0c-8ae5-aecbfe5e7544',
  firstName: 'Александр',
  middleName: 'Андреевич',
  lastName: 'Сахаров',
  email: 'test@test.test',
  role: 'User',
  username: 'aaSakharov',
};

export const mockRegistrations: Registration[] = [
  {
    eventId: mockEvents[0].id,
    userId: mockUser.id,
    roles: 'moderator',
    cameraName: undefined,
    videoUrl: 'https://nmodedia1.samsmu.ru/broadcasts/30650_59571',
    //'https://www.youtube.com/embed/Zy9dTURGF1M', //'https://cdn-curie.wasd.tv/publish/samgmu/embed.html',
  },
];

export const mockUserProfile: UserProfile = {
  id: mockUser.id,
  email: mockUser.email || '',
  firstName: mockUser.firstName,
  lastName: mockUser.lastName,
  middleName: mockUser.middleName,
  phone: '8 (800) 535-35-35',
  //photo: 'https://sun9-75.userapi.com/impf/c604419/v604419626/47a75/7B8qNMvGzwg.jpg?size=720x1080&quality=96&sign=ed73a7f5209ab7f6175ff217a3a8a51c&c_uniq_tag=YbJMK15wuuDeN14zg6BG4QwSCOX5zREdlQ7gkrP-evM&type=album',
  work: 'Самарская городская клиническая больница имени Н.И. Пирогова',
  position: 'заведующий хирургическим отделением',
  city: '',
  specialties: ['1', '2', '3'],
};

export const mockVisitedEvents: VisitedEvent[] = [
  {
    id: mockEvents.slice(-1)[0].id,
    title: mockEvents.slice(-1)[0].title,
    start: mockEvents.slice(-1)[0].start,
    end: mockEvents.slice(-1)[0].end,
    type: mockEvents.slice(-1)[0].type,
    points: 3,
    maxPoints: 3,
  },
  {
    id: mockEvents[0].id,
    title: mockEvents[0].title,
    start: mockEvents[0].start,
    end: mockEvents[0].end,
    type: mockEvents[0].type,
    points: 1,
    maxPoints: 2,
  },
];
