import React from 'react';
import { useLocation } from 'react-router-dom';
import * as H from 'history';

export interface NavigationContextProps {
  locations: H.Location<unknown>[];
  lastLocation?: H.Location<unknown>;
}

const NavigationContext = React.createContext<NavigationContextProps>({
  locations: [],
  lastLocation: undefined,
});

export const useNavigationContext = () => React.useContext(NavigationContext);

export const NavigationContextProvider: React.FC = ({ children }) => {
  const location = useLocation();
  const [locations, setlocations] = React.useState([location]);

  React.useEffect(() => {
    setlocations((locations) => [location, ...locations]);
  }, [location]);

  return (
    <NavigationContext.Provider
      value={{ locations, lastLocation: locations[1] }}
    >
      {children}
    </NavigationContext.Provider>
  );
};
