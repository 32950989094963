import './EventShedule.css';
import React from 'react';
import { EventUnit } from '../../_data/EventUnit';
import { format } from 'date-fns';
import { Speaker } from '../../_data/Speaker';
import ImgBackend from '../../restapi/ImgBackend';
import { useLocalizationContext } from '../../_localization/LocalizationContext';
import { uiMessages } from '../../_localization/ui.messages';

interface EventSheduleSpeakerProps {
  speaker: Speaker;
}

const EventSheduleSpeaker: React.FC<EventSheduleSpeakerProps> = ({
  speaker,
}) => {
  return (
    <div
      className={'eventSheduleSpeaker' + (speaker.primary ? ' primary' : '')}
    >
      <div className="eventSheduleSpeaker-photo avatar">
        {speaker.photoLink && <ImgBackend srcFile={speaker.photoLink} />}
      </div>
      <div className="eventSheduleSpeaker-info">
        <span className="eventSheduleSpeaker-name">{speaker.fullName}</span>
        <span className="eventSheduleSpeaker-regalia">
          {' '}
          – {speaker.regalia}
        </span>
      </div>
    </div>
  );
};

interface EventSheduleSingleDateProps {
  shedule: EventUnit[];
  now: number;
}

const EventSheduleSingleDate: React.FC<EventSheduleSingleDateProps> = ({
  shedule,
  now,
}) => {
  const { language, locale } = useLocalizationContext();
  return (
    <>
      {' '}
      <div className="eventShedule-date">
        {format(
          shedule[0].start,
          language === 'ru' ? 'd MMMM yyyy года' : 'd MMMM yyyy',
          {
            locale,
          },
        )}
      </div>
      <div className="eventShedule-timeline">
        {shedule.map((unit) => (
          <div key={unit.id} className="eventShedule-unit">
            {unit.start <= new Date(now) && unit.end > new Date(now) && (
              <div
                className="eventShedule-now"
                style={{
                  top:
                    ((now - unit.start.getTime()) /
                      (unit.end.getTime() - unit.start.getTime())) *
                      100 +
                    '%',
                }}
              />
            )}
            <div className="eventShedule-time">
              {format(unit.start, 'HH:mm', { locale })} -{' '}
              {format(unit.end, 'HH:mm', { locale })}
            </div>
            <div className="eventShedule-title">
              {unit.title}
              {unit.description && (
                <div className="eventShedule-description">
                  {unit.description}
                </div>
              )}
            </div>
            <div className="eventShedule-speakers">
              {unit.speakers.map((speaker) => (
                <EventSheduleSpeaker key={speaker.id} speaker={speaker} />
              ))}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

interface EventSheduleProps {
  shedule: EventUnit[];
}

const EventShedule: React.FC<EventSheduleProps> = ({ shedule }) => {
  const { language } = useLocalizationContext();
  const sortedShedule = [...shedule];
  sortedShedule.sort((u1, u2) => u1.start.getTime() - u2.start.getTime());

  const dates: string[] = shedule
    .map((u) => u.start.toDateString())
    .reduce(
      (dts, d) => (dts.some((dd) => dd === d) ? dts : [...dts, d]),
      [] as string[],
    );

  const sheduleByDate: {
    [x: string]: EventUnit[];
  } = {};
  dates.forEach((d) => {
    sheduleByDate[d] = sortedShedule.filter(
      (u) => d === u.start.toDateString(),
    );
  });

  const [now, setNow] = React.useState(Date.now());

  React.useEffect(() => {
    const interval = setInterval(() => setNow(Date.now), 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="eventShedule">
      {shedule.length === 0 ? (
        <div>{uiMessages.event_SheduleIsEmpty[language]}</div>
      ) : (
        dates.map((d) => (
          <EventSheduleSingleDate
            key={d}
            shedule={sheduleByDate[d]}
            now={now}
          />
        ))
      )}
    </div>
  );
};

export default EventShedule;
