import { Speaker } from '../Speaker';

export interface SpeakerRestApiDto {
  fullName: string | null;
  id: string;
  photoLink: string | null;
  photoId: string | null;
  regalia: string | null;
  primary: boolean | null;
  order: number | null;
}

export function mapSpeakerRestApiDto(s: SpeakerRestApiDto): Speaker {
  return {
    id: s.id,
    fullName: s.fullName || '',
    photoLink: s.photoLink
      ? { url: s.photoLink }
      : s.photoId
      ? { fileId: s.photoId }
      : undefined,
    regalia: s.regalia || '',
    primary: s.primary || false,
    order: s.order || 0,
  };
}
