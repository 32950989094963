export interface AnswerOption {
  id: string;
  value: string;
}

export enum QuestionType {
  Single,
  Multiple,
}

export interface Question {
  id: string;
  text: string;
  options: AnswerOption[];
  questionType: QuestionType;
  expirationSeconds?: number;
}
