import { Question, QuestionType } from '../Question';

export interface QuestionOptionWsDto {
  id: string;
  value: string;
}

export interface QuestionWsDto {
  id: string;
  questionType: string;
  value: string;
  expirationTime: number | null;
  answerOptions: QuestionOptionWsDto[];
}

export function MapQuestionWsDto(question: QuestionWsDto): Question {
  return {
    id: question.id,
    questionType:
      question.questionType === 'SINGLE_SELECT'
        ? QuestionType.Multiple
        : QuestionType.Multiple,
    text: question.value,
    expirationSeconds: question.expirationTime || -1,
    options: question.answerOptions.map((qo) => ({
      id: qo.id,
      value: qo.value,
    })),
  };
}
