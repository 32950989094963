import './Popup.css';
import React, { ReactNode } from 'react';
import { useLocalizationContext } from '../_localization/LocalizationContext';
import { uiMessages } from '../_localization/ui.messages';

interface PopupProps {
  header: ReactNode;
  fullscreen?: boolean;
  canSubmit: boolean;
  submitText: string;
  onSubmit: () => void;
  onCancel?: () => void;
}

export const Popup: React.FC<PopupProps> = ({
  header,
  fullscreen,
  submitText,
  canSubmit,
  onSubmit,
  onCancel,
  children,
}) => {
  const { language } = useLocalizationContext();

  return (
    <div className={fullscreen ? 'popup-fullscreen' : 'popup-container'}>
      <div className="popup">
        <div className="popup-header">{header}</div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onSubmit();
          }}
        >
          <div className="popup-body">{children}</div>
          <div className="popup-footer">
            <button
              type="submit"
              className="popup-submit button"
              disabled={!canSubmit}
            >
              {submitText}
            </button>
            {onCancel && (
              <button className="popup-cancel button" onClick={onCancel}>
                {uiMessages.cancel[language]}
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};
