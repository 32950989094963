import './Messages.css';
import React from 'react';

export const SuccessMessage: React.FC = ({ children }) => {
  return <div className="message success">{children}</div>;
};

export const ErrorMessage: React.FC = ({ children }) => {
  return <div className="message error">{children}</div>;
};
