import './Catalog.css';
import React from 'react';
import CatalogAside from './CatalogAside';
import { CatalogNav } from './CatalogNav';
import EventsList from '../eventsList/EventsList';
import CatalogFooter from './CatalogFooter';
import { Speciality } from '../_data/Speciality';
import { NavLink, useRouteMatch } from 'react-router-dom';
import appRoutes from '../navigation/routes';
import { useLocalizationContext } from '../_localization/LocalizationContext';
import { uiMessages } from '../_localization/ui.messages';

const Catalog: React.FC = () => {
  const [filterStartDate, setFilterStartDate] = React.useState<Date>(
    new Date(),
  );
  const [filterEndDate, setFilterEndDate] = React.useState<Date>(
    new Date(new Date().getFullYear(), 11, 31),
  );
  const [filteredSpecialities, setFilteredSpecialities] = React.useState<
    Speciality[]
  >([]);
  const [search, setSearch] = React.useState('');
  const { language } = useLocalizationContext();

  const archive = useRouteMatch().path === appRoutes.archive;

  return (
    <div className="catalog">
      <div className="catalog-aside inverted-bg">
        <CatalogAside
          onDatesChanged={(start: Date, end: Date) => {
            setFilterStartDate(start);
            setFilterEndDate(end);
          }}
          onSpecialitiesChanged={(filteredSpecialities: Speciality[]) => {
            setFilteredSpecialities(filteredSpecialities);
          }}
          onSearchChange={setSearch}
          archive={archive}
        />
      </div>
      <div className="catalog-main">
        {window.matchMedia('(max-width: 1200px)') && (
          <CatalogNav onSearchChange={setSearch} />
        )}
        {archive && <h1>{uiMessages.nav_Archive[language]}</h1>}
        <div className="catalog-list">
          <EventsList
            key={archive ? 'archive-events' : 'all-events'}
            startDate={filterStartDate}
            endDate={filterEndDate}
            filteredSpecialities={filteredSpecialities}
            search={search}
            archive={archive}
          />
        </div>
        <div className="catalog-archiveLink">
          {archive ? (
            <NavLink to={appRoutes.home}>
              {uiMessages.nav_Home[language]}
            </NavLink>
          ) : (
            <NavLink to={appRoutes.archive}>
              {uiMessages.nav_Archive[language]}
            </NavLink>
          )}
        </div>
        <div className="catalog-footer">
          <CatalogFooter />
        </div>
      </div>
    </div>
  );
};

export default Catalog;
