import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { ListItemIcon } from '@material-ui/core';

interface CheckListOption {
  value: any;
  label: string;
  checked: boolean;
}

interface CheckListProps {
  id: string;
  defaultOptions: CheckListOption[];
  onChangeSelection?: (selectedOptions: CheckListOption[]) => void;
}

const CheckList: React.FC<CheckListProps> = ({
  id,
  defaultOptions,
  onChangeSelection,
}) => {
  const [options, setOptions] = React.useState(defaultOptions);

  React.useEffect(() => {
    onChangeSelection?.(options);
  }, [options]);

  function changeOpionSelection(value: string) {
    setOptions((options) =>
      options.map((option) => ({
        value: option.value,
        label: option.label,
        checked: option.value === value ? !option.checked : option.checked,
      })),
    );
  }

  return (
    <List dense>
      {options.map((option) => {
        const labelId = `${id}-list-label-${option.value}`;
        return (
          <ListItem
            key={option.value}
            button
            onClick={() => changeOpionSelection(option.value)}
          >
            <ListItemText
              id={labelId}
              primary={option.label}
              color="secondary"
              style={{
                fontWeight: option.checked ? 'bolder' : 'normal',
              }}
            />
            <ListItemSecondaryAction>
              <Checkbox
                edge="end"
                checked={option.checked}
                onChange={() => changeOpionSelection(option.value)}
                inputProps={{ 'aria-labelledby': labelId }}
              />
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
  );
};

export default CheckList;
