import { createMuiTheme } from '@material-ui/core/styles';

export const appTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#33C3C3',
      light: '#b5b5b5',
      dark: '#000',
    },
    secondary: {
      main: '#fff',
      light: '#d2e3ff',
      dark: '#848484',
    },

    action: {
      hover: '#258FDB2E',
      hoverOpacity: 0.4,
    },
  },

  overrides: {
    MuiCheckbox: {
      root: {
        color: '#258FDB',
      },
    },
    MuiTypography: {
      body2: {
        fontWeight: 'inherit',
      },
    },
  },
});
