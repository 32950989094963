import React from 'react';
import { useLocalizationContext } from '../../../_localization/LocalizationContext';
import { uiMessages } from '../../../_localization/ui.messages';
import { useWebinarContext } from '../Webinar.Provider';
import { IconButton as MuiIconButton } from '@material-ui/core';
import BlockIcon from '@material-ui/icons/Block';

export const ChatModeration: React.FC = () => {
  const { language } = useLocalizationContext();
  const { chatDisabled, toggleChatDisabled } = useWebinarContext();

  return (
    <div
      title={
        chatDisabled
          ? uiMessages.chat_DoEnable[language]
          : uiMessages.chat_DoDisable[language]
      }
    >
      <MuiIconButton
        className={chatDisabled ? 'text-danger' : 'text-success'}
        type="button"
        onClick={(evt) => {
          evt.preventDefault();
          evt.stopPropagation();
          toggleChatDisabled();
        }}
        size="small"
      >
        <BlockIcon />
      </MuiIconButton>
    </div>
  );
};
