import { EventUnit } from '../EventUnit';
import { mapSpeakerRestApiDto, SpeakerRestApiDto } from './SpeakerRestApiDto';

export interface EventUnitRestApiDto {
  description: string | null;
  endTimestamp: string | null;
  id: string;
  name: string | null;
  period: string | null;
  speakers: SpeakerRestApiDto[];
  startTimestamp: string | null;
}

export function mapEventUnitRestApiDto(u: EventUnitRestApiDto): EventUnit {
  return {
    id: u.id,
    start: new Date(u.startTimestamp || 0),
    end: new Date(u.endTimestamp || 0),
    title: u.name || '',
    description: u.description || '',
    period: u.period || '',
    speakers: u.speakers?.map(mapSpeakerRestApiDto) || [],
  };
}
