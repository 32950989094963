/* eslint-disable no-undef */
import Keycloak from 'keycloak-js';

const keycloakOptions =
  window.app?.env?.KEYCLOAK || process.env.REACT_APP_KEYCLOAK || undefined;

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
const keycloak = new Keycloak(keycloakOptions);

export default keycloak;
