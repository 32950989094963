import { User, UserProfile } from '../User';

export interface UserRestApiDto {
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  middleName: string | null;
  username: string | null;
  ssoId: string;
}

export const mapUserRestApiDto = (user: User): UserRestApiDto => ({
  email: user.email || '',
  firstName: user.firstName || '',
  lastName: user.lastName || '',
  middleName: user.middleName || '',
  ssoId: user.id,
  username: user.username || '',
});

export interface UserProfileRestApiDto {
  email: string | null;
  firstName: string | null;
  id: string;
  lastName: string | null;
  middleName: string | null;
  phone: string | null;
  photo: string | null;
  position: string | null;
  specialtyOne: string | null;
  specialtyThree: string | null;
  specialtyTwo: string | null;
  work: string | null;
  city: string | null;
  personalInfoAgreement: boolean | null;
}

export const mapUserProfileRestApiDto = (
  profile: UserProfileRestApiDto,
): UserProfile => ({
  id: profile.id,
  email: profile.email || '',
  firstName: profile.firstName || '',
  lastName: profile.lastName || '',
  middleName: profile.middleName || undefined,
  phone: profile.phone || undefined,
  photo: profile.photo ? { fileId: profile.photo } : undefined,
  work: profile.work || '',
  position: profile.position || '',
  specialties: [
    profile.specialtyOne || '',
    profile.specialtyTwo || '',
    profile.specialtyThree || '',
  ],
  city: profile.city || '',
  personalInfoAgreement: profile.personalInfoAgreement || undefined,
});

export const unmapUserProfileRestApiDto = (
  profile: UserProfile,
): UserProfileRestApiDto => ({
  id: profile.id,
  email: profile.email || '',
  firstName: profile.firstName || '',
  lastName: profile.lastName || '',
  middleName: profile.middleName || '',
  phone: profile.phone || '',
  photo: profile.photo?.fileId || '',
  work: profile.work || '',
  position: profile.position || '',
  specialtyOne:
    profile.specialties && profile.specialties.length > 0
      ? profile.specialties[0]
      : '',
  specialtyTwo:
    profile.specialties && profile.specialties.length > 1
      ? profile.specialties[1]
      : '',
  specialtyThree:
    profile.specialties && profile.specialties.length > 2
      ? profile.specialties[2]
      : '',
  city: profile.city || '',
  personalInfoAgreement: profile.personalInfoAgreement || null,
});
