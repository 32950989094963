import React from 'react';
import UserPageTemplate from './UserPageTemplate';
import appRoutes from '../navigation/routes';
import { useAuthContext } from '../auth/AuthContext';
import { LoadingSpinner } from '../_components/Spinner';
import NotAuthorizedPage from '../layout/NotAuthorizedPage';
import { useLocalizationContext } from '../_localization/LocalizationContext';
import { uiMessages } from '../_localization/ui.messages';

const UserChangePassword: React.FC = () => {
  const { initialized, user } = useAuthContext();
  const { language } = useLocalizationContext();
  if (!initialized) {
    return <LoadingSpinner />;
  }

  if (!user) {
    return <NotAuthorizedPage />;
  }
  return (
    <UserPageTemplate
      backLink={{
        title: uiMessages.nav_BackToProfile[language],
        link: appRoutes.user,
      }}
      header={uiMessages.user_ChangePassword[language]}
    >
      <div>{uiMessages.errors_underDevelopment[language]}</div>
    </UserPageTemplate>
  );
};

export default UserChangePassword;
