import { apiUrl } from './backendPaths';

export function getApiUrl(endpoint: string): string {
  const url = new URL(endpoint, apiUrl.href);
  return url.href;
}

export interface HttpResponse<T> extends Response {
  parsedBody?: T;
}

export async function http<T>(
  request: RequestInfo,
  accessToken?: string,
): Promise<HttpResponse<T>> {
  if (accessToken) {
    (request as Request)?.headers.append(
      'Authorization',
      `Bearer ${accessToken}`,
    );
  }
  const response: HttpResponse<T> = await fetch(request);
  if (!response.ok) {
    throw new Error((await response.text()) || response.statusText);
  }

  try {
    response.parsedBody = await response.json();
  } catch (ex) {
    response.parsedBody = undefined;
  }
  return response;
}

export function checkHttpResult<T>(
  result: HttpResponse<T>,
  errorMessage: string,
): T {
  if (
    result.ok &&
    result.status >= 200 &&
    result.status <= 300 &&
    result.parsedBody
  ) {
    return result.parsedBody;
  } else {
    throw new Error(errorMessage);
  }
}

export function checkHttpResultVoid(
  result: HttpResponse<void>,
  errorMessage: string,
): void {
  if (result.ok && result.status >= 200 && result.status <= 300) {
    return;
  } else {
    throw new Error(errorMessage);
  }
}

export async function get<T>(
  path: string,
  accessToken?: string,
  args: RequestInit = {},
): Promise<HttpResponse<T>> {
  args = {
    ...args,
    method: 'get',
  };
  return await http<T>(new Request(getApiUrl(path), args), accessToken);
}

export async function post<T>(
  path: string,
  body: any,
  accessToken?: string,
  args: RequestInit = {},
): Promise<HttpResponse<T>> {
  if (body instanceof File) {
    args = {
      ...args,
      method: 'post',
      body: body,
      headers: { ...args.headers, 'Content-Type': body.type },
    };
    return await http<T>(new Request(getApiUrl(path), args), accessToken);
  }
  args = {
    ...args,
    method: 'post',
    body: JSON.stringify(body),
    headers: { ...args.headers, 'Content-Type': 'application/json' },
  };
  return await http<T>(new Request(getApiUrl(path), args), accessToken);
}

export async function put<T>(
  path: string,
  body: any,
  accessToken?: string,
  args: RequestInit = {},
): Promise<HttpResponse<T>> {
  args = {
    ...args,
    method: 'put',
    body: JSON.stringify(body),
    headers: { ...args.headers, 'Content-Type': 'application/json' },
  };
  return await http<T>(new Request(getApiUrl(path), args), accessToken);
}

export async function del<T>(
  path: string,
  accessToken?: string,
  args: RequestInit = {},
): Promise<HttpResponse<T>> {
  args = {
    ...args,
    method: 'delete',
  };
  return await http<T>(new Request(getApiUrl(path), args), accessToken);
}
