import React from 'react';

interface CheckBoxFieldProps {
  name: string;
  disabled?: boolean;
  initialValue?: boolean;
  onCheck?: (checked: boolean) => void;
}

const CheckBoxField: React.FC<CheckBoxFieldProps> = ({
  name,
  disabled,
  initialValue,
  onCheck,
}) => {
  const [value, setValue] = React.useState(initialValue || false);

  React.useEffect(() => {
    onCheck?.(value);
  }, [value]);
  return (
    <input
      className="field-input"
      type="checkbox"
      checked={value}
      disabled={disabled}
      name={name}
      onChange={(e) => setValue(e.target.checked)}
    />
  );
};

export default CheckBoxField;
