import './DateRangePicker.css';
import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useLocalizationContext } from '../_localization/LocalizationContext';

interface DateRangePickerProps {
  defaultStart: Date;
  defaultEnd: Date;
  onDatesChanged?: (start: Date, end: Date) => void;
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({
  defaultStart,
  defaultEnd,
  onDatesChanged,
}) => {
  const { locale } = useLocalizationContext();
  const [start, setStart] = React.useState(defaultStart);
  const [end, setEnd] = React.useState(defaultEnd);
  const dateFormat = 'dd MMM yy';

  React.useEffect(() => onDatesChanged?.(start, end), [start, end]);

  return (
    <div className="dateRangePicker">
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
        <DatePicker
          className="from-date"
          value={start}
          onChange={(date) => {
            if (date) setStart(date);
          }}
          format={dateFormat}
          variant="inline"
          disableToolbar={true}
          size="small"
          margin="none"
        />
        <div className="dash"></div>
        <DatePicker
          className="to-date"
          value={end}
          onChange={(date) => {
            if (date) setEnd(date);
          }}
          format={dateFormat}
          variant="inline"
          disableToolbar={true}
          size="small"
          margin="none"
        />
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default DateRangePicker;
