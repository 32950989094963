import React from 'react';
import {
  IconButton as MuiIconButton,
  Button as MuiButton,
  IconButtonProps,
  ButtonProps as MuiButtonProps,
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import { uiMessages } from '../_localization/ui.messages';
import { useLocalizationContext } from '../_localization/LocalizationContext';

interface SendButtonProps {
  disabled?: boolean;
}

export const SendButton: React.FC<SendButtonProps & IconButtonProps> = ({
  disabled,
  children,
  ...other
}) => {
  return (
    <MuiIconButton type="submit" disabled={disabled} {...other}>
      {children || <SendIcon />}
    </MuiIconButton>
  );
};

interface SubmitButtonProps {
  disabled?: boolean;
}

export const SubmitButton: React.FC<SubmitButtonProps & MuiButtonProps> = ({
  disabled,
  children,
  ...other
}) => {
  const { language } = useLocalizationContext();
  return (
    <MuiButton type="submit" disabled={disabled} variant="outlined" {...other}>
      {children || uiMessages.send[language]}
    </MuiButton>
  );
};
