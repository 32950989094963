import { Message } from '../Message';

export interface ChatMessageWsDto {
  id: string;
  eventId: string;
  userId: string;
  username: string;
  photoId: string | null;
  timestamp: Date;
  message: string;
  deletedDate: Date | null;
}

export function MapChatMessageWsDto(msg: ChatMessageWsDto): Message {
  return {
    id: msg.id,
    eventId: msg.eventId,
    userId: msg.userId,
    userName: msg.username,
    photoId: msg.photoId || undefined,
    timestamp: new Date(msg.timestamp),
    message: msg.message,
    deletedDate: msg.deletedDate || undefined,
  };
}

export function UnmapChatMessageWsDto(msg: Message): ChatMessageWsDto {
  return {
    id: msg.id || '',
    eventId: msg.eventId,
    userId: msg.userId,
    username: msg.userName,
    photoId: msg.photoId || null,
    timestamp: msg.timestamp,
    message: msg.message,
    deletedDate: msg.deletedDate || null,
  };
}
