import React from 'react';

interface EventHeaderOptionProps {
  icon: string;
  title: string;
  status: string;
}
export const EventHeaderOption: React.FC<EventHeaderOptionProps> = ({
  icon,
  title,
  status,
}) => {
  return (
    <div className="eventHeader-option">
      <div className="eventHeader-option-icon">
        <img src={icon} />
      </div>
      <div className="eventHeader-option-title">{title}</div>
      <div className="eventHeader-option-status">{status}</div>
    </div>
  );
};
