/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState } from 'react';
import { useContext, createContext } from 'react';
import keycloak from './keycloak';
import { ReactKeycloakProvider, useKeycloak } from '@react-keycloak/web';
import { User, UserProfile } from '../_data/User';
import { mockUser } from '../_data/mocks/MockUser';
import { useLocation } from 'react-router-dom';
import { useMocks } from '../restapi/backendPaths';
import { useUserRestApi } from '../user/User.utils';
import { useLocalizationContext } from '../_localization/LocalizationContext';

const keycloakProviderInitConfig = {
  onLoad: 'check-sso',
};

export interface AuthContextProps {
  initialized: boolean;
  user: User | null;
  userProfile?: UserProfile;
  accessToken?: string;
  login: (redirectUri?: string) => void;
  logout: () => void;
  loadUserProfile: () => void;
}

export const AuthContext = createContext<AuthContextProps>({
  initialized: false,
  user: null,
  login: () => {},
  logout: () => {},
  loadUserProfile: () => {},
});

export const useAuthContext = () => useContext(AuthContext);

interface AuthContextProviderInnerProps {
  user: User | null;
  login: (redirectUri?: string) => void;
  logout: () => void;
}

const AuthContextProviderInner: React.FC<AuthContextProviderInnerProps> = ({
  user,
  login,
  logout,
  children,
}) => {
  const { initialized, keycloak } = useKeycloak();
  const [userProfile, setUserProfile] = useState<UserProfile | undefined>(
    undefined,
  );
  const { getUserProfile, postNewUserProfile } = useUserRestApi();

  React.useEffect(() => {
    loadUserProfile();
  }, [user]);

  function createUserProfile(pause?: number) {
    if (user) {
      postNewUserProfile(user, keycloak.token).then(() =>
        setTimeout(() => loadUserProfile(pause), 500),
      );
    }
  }

  async function loadUserProfile(pause?: number) {
    if (user) {
      getUserProfile(user.id, keycloak.token)
        .then((existingProfile) => {
          if (!existingProfile) {
            setTimeout(
              () => createUserProfile((pause || 0) + 500),
              pause || 500,
            );
          } else {
            setUserProfile(existingProfile);
          }
        })
        .catch(() =>
          setTimeout(() => createUserProfile((pause || 0) + 500), pause || 500),
        );
    } else {
      setUserProfile(undefined);
    }
  }

  return (
    <AuthContext.Provider
      value={{
        initialized,
        user,
        userProfile,
        accessToken: keycloak.token,
        login,
        logout,
        loadUserProfile,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const AuthContextProvider: React.FC = ({ children }) => {
  const location = useLocation();
  const [user, setUser] = useState<User | null>(null);
  const { language } = useLocalizationContext();

  function rememberCurrentUser(user: User) {
    if (!user['role']) {
      user['role'] = 'user';
    }
    setUser(user);
  }

  function login(redirectUri?: string) {
    if (useMocks()) {
      setUser(mockUser);
    } else if (!keycloak.authenticated) {
      const actualRedirectUri = redirectUri || location.pathname;
      window.location.assign(
        keycloak.createLoginUrl({ actualRedirectUri, locale: language }),
      );
      //keycloak.login({ actualRedirectUri });
    }
  }

  function logout() {
    setUser(null);
    if (keycloak.authenticated) {
      keycloak.logout();
    }
  }
  // acr: "1"
  // at_hash: "oQG0hrM_S19X8BpaS5k-rg"
  // aud: "nmo-events"
  // auth_time: 1626353075
  // azp: "nmo-events"
  // email: "akorobetskaya@webzavod.ru"
  // email_verified: false
  // exp: 1626353376
  // family_name: "Коробецкая"
  // given_name: "Анастасия"
  // iat: 1626353076
  // iss: "http://dev-samgmu3.demo.webzavod.ru/auth/realms/SAMGMU"
  // jti: "9c61b263-602c-4355-bb4c-809600e64aff"
  // middle_name: "Александровна"
  // name: "Анастасия Коробецкая"
  // nonce: "c4eea671-9690-447d-944b-674a8387f984"
  // preferred_username: "korobetskayaa"
  // role: "user"
  // session_state: "2403fb7e-4dca-4062-b670-23e761372259"
  // sub: "38703bed-cfff-41a4-a146-185780ea70da"
  // typ: "ID"

  const onKeycloakTokens = (/*tokens: any*/) => {
    const user: User = {
      id: keycloak.idTokenParsed.sub,
      firstName: keycloak.idTokenParsed.given_name,
      middleName: keycloak.idTokenParsed.middle_name,
      lastName: keycloak.idTokenParsed.family_name,
      username: keycloak.idTokenParsed.preferred_username,
      email: keycloak.idTokenParsed.email,
      role: keycloak.idTokenParsed.role,
    };
    rememberCurrentUser(user);
  };

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={keycloakProviderInitConfig}
      //onEvent={onKeycloakEvent}
      onTokens={onKeycloakTokens}
    >
      <AuthContextProviderInner user={user} login={login} logout={logout}>
        {children}
      </AuthContextProviderInner>
    </ReactKeycloakProvider>
  );
};
