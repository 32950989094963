import React, { ReactNode } from 'react';
import { useLocalizationContext } from '../../_localization/LocalizationContext';
import { uiMessages } from '../../_localization/ui.messages';
import { InputField } from './InputField';
import SelectField, { SelectFieldOption } from './SelectField';
import CheckBoxField from './CheckBoxField';

export interface FormFieldProps {
  name: string;
  label?: ReactNode;
  initialValue?: string;
  initialChecked?: boolean;
  type?: string; //'text' | 'password' | 'email' | 'phone' | 'select';
  options?: SelectFieldOption[];
  required?: boolean;
  disabled?: boolean;
  validate?: (value?: string) => string;
  afterValidation?: (valid: boolean) => void;
}

const FormField: React.FC<FormFieldProps> = ({
  name,
  label,
  initialValue,
  initialChecked,
  type = 'input',
  options,
  required,
  disabled,
  validate,
  afterValidation,
}) => {
  const { language } = useLocalizationContext();
  const [value, setValue] = React.useState(initialValue);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

  React.useEffect(() => {
    if (validate || required) {
      let validationResult = '';
      if (required && !value) {
        validationResult = uiMessages.form_Required[language];
      } else if (validate) {
        validationResult = validate(value);
      }
      setErrorMessage(validationResult);
      if (afterValidation) afterValidation(!validationResult);
    }
  }, [value, validate]);

  return (
    <div className={'formField' + (required ? ' required' : '')}>
      {type === 'checkbox' && (
        <CheckBoxField
          name={name}
          disabled={disabled}
          initialValue={initialChecked}
          onCheck={(checked) => setValue(checked ? '+' : undefined)}
        />
      )}
      {label && <label className="field-label">{label}</label>}
      {type === 'select' && options ? (
        <SelectField
          name={name}
          options={options}
          initialSelectedId={value}
          onOptionSelect={(optionId) => setValue(optionId)}
        />
      ) : (
        type !== 'checkbox' && (
          <InputField
            name={name}
            type={type}
            disabled={disabled}
            value={value || ''}
            setValue={setValue}
          />
        )
      )}
      {errorMessage && <div className="field-error">{errorMessage}</div>}
    </div>
  );
};

export default FormField;
