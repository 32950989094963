import { useState, useEffect } from 'react';
import { useLocalizationContext } from '../_localization/LocalizationContext';
import { uiMessages } from '../_localization/ui.messages';

function useDataLoading<T>(
  dataLoading: () => Promise<T>,
): {
  error: string;
  loading: boolean;
  data: T | undefined;
  reloadData: () => void;
} {
  const { language } = useLocalizationContext();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<T | undefined>(undefined);
  const [error, setError] = useState('');

  const loadData = () => {
    setData(undefined);
    setLoading(true);
    setError('');
    dataLoading()
      .then((data) => {
        setLoading(false);
        setData(data);
      })
      .catch((error) => {
        setLoading(false);
        setError(
          process.env.NODE_ENV !== 'production'
            ? error.message
            : uiMessages.errors_onLoad[language],
        );
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  return {
    error,
    loading,
    data,
    reloadData: loadData,
  };
}

export default useDataLoading;
