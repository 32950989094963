import './UserEditProfile.css';
import React from 'react';
import { useAuthContext } from '../auth/AuthContext';
import UserPageTemplate from './UserPageTemplate';
import appRoutes from '../navigation/routes';
import { LoadingSpinner } from '../_components/Spinner';
import { Form } from '../_components/forms/Form';
import { useUserRestApi } from './User.utils';
import { ErrorMessage } from '../_components/Messages';
import NotAuthorizedPage from '../layout/NotAuthorizedPage';
import { LoadPhoto } from '../_components/forms/LoadPhoto';
import { useHistory } from 'react-router';
import { useCatalogRestApi } from '../catalog/Catalog.utils';
import useDataLoading from '../_hooks/useDataLoading';
import { Speciality } from '../_data/Speciality';
import { isUserProfileUncompleted } from '../_data/User';
import { useNavigationContext } from '../navigation/NavigationContext';
import { useLocalizationContext } from '../_localization/LocalizationContext';
import { uiMessages } from '../_localization/ui.messages';
import { DownloadFile } from '../_components/DownloadFile';
import { getApiFileUrl } from '../restapi/backendPaths';

const UserEditProfile: React.FC = () => {
  const { initialized, user, userProfile, loadUserProfile } = useAuthContext();
  const history = useHistory();
  const { language } = useLocalizationContext();
  const { lastLocation } = useNavigationContext();
  const { postUserProfile, getPersonalInfoAgreement } = useUserRestApi();
  const { getSpecialities } = useCatalogRestApi();
  const {
    data: specialities,
    loading: loadingSpecialities,
    error: errorSpecialities,
  } = useDataLoading<Speciality[] | undefined>(() => getSpecialities());
  const {
    data: personalInfoAgreementFileId,
    loading: loadingPersonalInfoAgreement,
    error: errorPersonalInfoAgreement,
  } = useDataLoading(() => getPersonalInfoAgreement());
  const [photoFile, setPhotoFile] = React.useState<File | undefined>(undefined);

  if (!initialized || loadingSpecialities || loadingPersonalInfoAgreement) {
    return <LoadingSpinner />;
  }

  if (!user) {
    return <NotAuthorizedPage />;
  }
  return (
    <UserPageTemplate
      backLink={{
        title: uiMessages.nav_BackToProfile[language],
        link: appRoutes.user,
      }}
      header={uiMessages.profile_Edit[language]}
    >
      <div className="userEditProfile">
        {!userProfile ? (
          <ErrorMessage>
            {uiMessages.errors_onLoadProfile[language]}
          </ErrorMessage>
        ) : !specialities ? (
          <ErrorMessage>
            {errorSpecialities ||
              uiMessages.errors_onLoadSpecialities[language]}
          </ErrorMessage>
        ) : (
          <>
            <div className="info">
              <span className="highlited">
                {isUserProfileUncompleted(userProfile) &&
                  uiMessages.profile_Uncompleted[language]}
              </span>
              {uiMessages.profile_MarkedFields[language]}
              <span className="required field-label" />
              {uiMessages.profile_MarkedFields2[language]}
            </div>
            <Form
              onSubmit={async (values) => {
                return postUserProfile(
                  {
                    ...userProfile,
                    email: values.email,
                    firstName: values.firstName,
                    middleName: values.middleName,
                    lastName: values.lastName,
                    phone: values.phone,
                    work: values.work,
                    position: values.position,
                    city: values.city,
                    specialties: [1, 2, 3].map((i) => values['speciality' + i]),
                    personalInfoAgreement: values.personalInfoAgreement,
                  },
                  photoFile,
                ).then(() => loadUserProfile());
              }}
              afterSubmit={() =>
                history.push(
                  lastLocation?.pathname || appRoutes.user,
                  lastLocation?.state,
                )
              }
              fields={[
                {
                  name: 'lastName',
                  label: uiMessages.profile_LastName[language],
                  required: true,
                  initialValue: userProfile.lastName,
                  validate: (value) => (value ? '' : 'Введите фамилию'),
                },
                {
                  name: 'firstName',
                  label: uiMessages.profile_FirstName[language],
                  required: true,
                  initialValue: userProfile.firstName,
                  validate: (value) => (value ? '' : 'Введите имя'),
                },
                {
                  name: 'middleName',
                  label: uiMessages.profile_MiddleName[language],
                  initialValue: userProfile.middleName || '',
                },
                {
                  name: 'city',
                  label: uiMessages.profile_City[language],
                  required: true,
                  initialValue: userProfile.city || '',
                },
                {
                  name: 'work',
                  label: uiMessages.profile_Workplace[language],
                  required: true,
                  initialValue: userProfile.work || '',
                },
                {
                  name: 'position',
                  label: uiMessages.profile_Position[language],
                  required: true,
                  initialValue: userProfile.position || '',
                },
                ...userProfile.specialties.map((speciality, i) => ({
                  name: 'speciality' + (i + 1),
                  label: uiMessages.profile_Speciality[language] + (i + 1),
                  type: 'select',
                  required: i === 0,
                  options: specialities.map((sp) => ({
                    id: sp.id,
                    value: sp.title,
                  })),
                  initialValue: speciality,
                })),
                {
                  name: 'email',
                  label: uiMessages.profile_Email[language],
                  required: true,
                  initialValue: userProfile.email || '',
                  type: 'email',
                  disabled: true,
                },
                {
                  name: 'phone',
                  label: uiMessages.profile_Phone[language],
                  required: true,
                  initialValue: userProfile.phone || '',
                  type: 'phone',
                },
                {
                  name: 'personalInfoAgreement',
                  label: (
                    <>
                      {uiMessages.profile_PersonalInfoAgreement_pre[language]}{' '}
                      {personalInfoAgreementFileId ? (
                        <DownloadFile
                          link={getApiFileUrl(personalInfoAgreementFileId)}
                          text={
                            uiMessages.profile_PersonalInfoAgreement_link[
                              language
                            ]
                          }
                        />
                      ) : (
                        uiMessages.profile_PersonalInfoAgreement_link[language]
                      )}
                      {!personalInfoAgreementFileId && (
                        <ErrorMessage>
                          {errorPersonalInfoAgreement ||
                            uiMessages.errors_onLoadPersonalInfoAgreement[
                              language
                            ]}
                        </ErrorMessage>
                      )}
                    </>
                  ),
                  required: true,
                  initialChecked: userProfile.personalInfoAgreement,
                  type: 'checkbox',
                },
              ]}
            >
              <div key="photo" style={{ gridArea: 'photo' }}>
                <LoadPhoto
                  name="photo"
                  initialValue={userProfile?.photo}
                  file={photoFile}
                  setFile={setPhotoFile}
                />
              </div>
            </Form>
          </>
        )}
      </div>
    </UserPageTemplate>
  );
};

export default UserEditProfile;
