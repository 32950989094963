/* eslint-disable no-undef */
const apiPath = (window.app && window.app.env.API_PATH) || window.location;

const apiUrl = new URL(apiPath);

const getApiFileUrl = (fileId, accessToken) =>
  `${apiUrl}v2/files/${fileId}` +
  (accessToken ? '?access_token=' + accessToken : '');

export { apiUrl, getApiFileUrl };

export const chatUrl =
  (window.app && window.app.env.CHAT_PATH) ||
  new URL('/chat', window.location.href).href ||
  'wss://localhost:8080/chat';

export const quizUrl =
  (window.app && window.app.env.QUIZ_PATH) ||
  new URL('/quiz', window.location.href).href ||
  'wss://localhost:8080/quiz';

export const useMocks = () => {
  return (
    (window.app && window.app.env.USE_MOCKS) ||
    window.location.search
      .slice(1) // remove ?
      .split('&')
      .find((param) => {
        const paramValue = param.split('=');
        return (
          paramValue[0] === 'usemocks' &&
          (paramValue.length === 1 ||
            paramValue[1] === 'true' ||
            paramValue[1] === '1')
        );
      }) ||
    false
  );
};
