import { Participant, Payment, Registration } from '../Registration';

export interface CheckRegistrationApiDto {
  registered: boolean | null;
  speaker: boolean | null;
  sponsor: boolean | null;
  moderator: boolean | null;
  roles: string | null;
  webLink: string | null;
  streamName?: string | null;
}

export const mapCheckRegistrationApiDto = (
  userId: string,
  eventId: string,
  reg: CheckRegistrationApiDto,
): Registration | undefined => {
  return reg.registered
    ? {
        userId,
        eventId,
        videoUrl: reg.webLink || undefined,
        roles: [
          reg.roles,
          reg.speaker && 'speaker',
          reg.sponsor && 'sponsor',
          reg.moderator && 'moderator',
        ]
          .filter((r) => r)
          .join(','),
        cameraName: reg.streamName || undefined,
      }
    : undefined;
};

export interface ParticipantApiDto {
  id: string;
  eventid: string | null;
  userId: string | null;
}
export interface PaymentApiDto {
  error: string | null;
  paymentNumber: string | null;
  paymentPageUrl: string | null;
  status: string | null;
}

export interface RegistrationApiDto {
  participant?: ParticipantApiDto;
  payment?: PaymentApiDto;
}

export const mapRegistrationApiDto = (
  reg: RegistrationApiDto,
): Participant | Payment | undefined => {
  return reg.participant
    ? {
        id: reg.participant.id,
        eventId: reg.participant.eventid || '',
        userId: reg.participant.userId || '',
      }
    : reg.payment
    ? {
        error: reg.payment.error || undefined,
        number: reg.payment.paymentNumber || '',
        redirectUrl: reg.payment.paymentPageUrl || '',
        status: reg.payment.status || '',
      }
    : undefined;
};
