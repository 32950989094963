import { Speciality } from '../Speciality';

export interface SpecialityRestApiDto {
  id: string;
  name: string | null;
  specialtyId: string | null;
}

export function mapSpecialityRestApiDto(
  spec: SpecialityRestApiDto,
): Speciality {
  return {
    id: spec.specialtyId || '',
    title: spec.name || '',
  };
}
