import { Question, QuestionType } from '../Question';

export const mockQuestions: Question[] = [
  {
    id: '1',
    text: 'Что такое печень?',
    questionType: QuestionType.Multiple,
    expirationSeconds: 15 * 60,
    options: [
      { id: '0', value: 'самый большой внутренний орган человека' },
      { id: '1', value: 'очень редко печень может находится слева' },
      {
        id: '2',
        value:
          'находится справа в полости живота под диафрагмой (в правом подреберье)',
      },
      {
        id: '3',
        value: 'непарный орган, то есть второго такого органа в организме нет',
      },
    ],
  },
  {
    id: '2',
    text: 'Ты тут?',
    questionType: QuestionType.Single,
    expirationSeconds: 5 * 60,
    options: [
      { id: '0', value: 'да' },
      { id: '1', value: 'нет' },
    ],
  },
];
