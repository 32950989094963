const appRoutes = {
  home: '/',
  event: '/event/:id',
  payment: '/event/payment',
  webinar: '/event/:id/webinar',
  archive: '/archive',
  user: '/user',
  profile: '/user/profile',
  changePassword: '/user/change-password',
  contacts: '/contacts',
  photos: '/photos',
};

interface LinkMatches {
  key: string;
  value: string;
}

export function getActualLink(route: string, params: LinkMatches[]): string {
  let actualRoute = route;
  if (params && params.length > 0) {
    const inlineParams = params.filter((p) => route.match(':' + p.key));
    inlineParams.forEach((p) => {
      actualRoute = actualRoute.replace(':' + p.key, p.value);
    });
    const paramsStr = params
      .filter((p) => !route.match(':' + p.key))
      .map((p) => `${p.key}=${p.value}`)
      .join('&');
    actualRoute += '?' + paramsStr;
  }
  return actualRoute;
}

export default appRoutes;
