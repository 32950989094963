import { SheduledEvent } from '../SheduledEvent';
import { specialities } from './MockSpecialities';
import { mockSponsors, mockOrganzers } from './MockSponsors';

const getCurrentDateHour = (addHour = 0, addMinutes = 0) =>
  new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate(),
    new Date().getHours() + addHour,
    addMinutes,
  );

export const mockEvents: SheduledEvent[] = [
  {
    id: '1',
    link: 'test_lection_now',
    published: true,
    start: getCurrentDateHour(),
    duration: 2 * 60 + 30,
    points: 5,
    title: 'Тестовая лекция (идет сейчас)',
    titleFormatted: 'Тестовая лекция <font size="90%">(идет сейчас)</font>',
    type: 'Лекция',
    format: 'online',
    fileDescription: 'Пример файла',
    fileId: '1234567890',
    description:
      'Тестовая лекция, которая всегда начинается в 0 минут текущего часа',
    address:
      'г. Самара, Волжское шоссе, 70, Клинический госпиталь ИДК,  6 этаж, конференц-зал',
    control:
      'Осуществляется контроль присутствия на трансляции с помощью рандомно всплывающих окон.',
    sponsors: [mockSponsors[0], ...mockSponsors.slice(4)],
    organizers: [mockSponsors[0], mockSponsors[1], mockSponsors[2]],
    specialities: [specialities[0], specialities[1]],
    shedule: [
      {
        id: '1',
        start: getCurrentDateHour(),
        end: getCurrentDateHour(0, 30),
        title: 'Регистрация участников',
        description: '',
        period: '',
        speakers: [],
      },
      {
        id: '2',
        start: getCurrentDateHour(0, 30),
        end: getCurrentDateHour(0, 45),
        title: 'Открытие конференции. Приветственное слово.',
        description: '',
        period: '',
        speakers: [
          {
            id: '1',
            order: 1,
            fullName: 'Колсанов Александр Владимирович',
            photoLink: {
              url:
                'https://sgpress.ru/wp-content/uploads/2019/05/Gv6i-kfrNKY.jpg',
            },
            regalia:
              'ректор СамГМУ, профессор РАН, ФГБОУ ВО СамГМУ Минздрава России',
            primary: true,
          },
          {
            id: '2',
            order: 2,
            fullName: 'Бенян Армен Сисакович',
            photoLink: { url: '' },
            regalia:
              'министр здравоохранения Самарской области, д.м.н., профессор',
          },
          {
            id: '3',
            order: 3,
            fullName: 'Шурыгина Оксана Викторовна',
            photoLink: {
              url:
                'http://st.volga.news/image/w450/53b651a7-9ae8-4208-a069-0164d12a083c.jpg',
            },
            regalia:
              'д.м.н., профессор кафедры репродуктивной медицины, клинической эмбриологии и генетики ИПО ФГБОУ ВО «Самарский государственный медицинский университет» Минздрава России; заведующая лабораторией ВРТ Клинического госпиталя ИДК, ГК «Мать и дитя»',
          },
        ],
      },
      {
        id: '3',
        start: getCurrentDateHour(0, 45),
        end: getCurrentDateHour(1, 30),
        title:
          'Отмена цикла, связанного с лабораторией ЭКО: как избежать и как справиться',
        description: '',
        period: '',
        speakers: [
          {
            id: '4',
            order: 1,
            fullName: 'Неджати Фындыклы',
            photoLink: { url: '' },
            regalia:
              'научный руководитель «Башейси Хелс Груп» (Стамбул, Турция)',
            primary: true,
          },
        ],
      },
      {
        id: '4',
        start: getCurrentDateHour(1, 30),
        end: getCurrentDateHour(2, 30),
        title:
          'Электронный контроль движения биологических материалов в лаборатории ЭКО',
        description: '*не обеспечено кредитами НМО. При поддержке компании БМТ',
        period: '',
        speakers: [
          {
            id: '5',
            order: 1,
            fullName: 'Ковальский Борис Юрьевич',
            photoLink: {
              url:
                'http://medkarm.ru/gallery_gen/85124bd74d8837961859ba3607236904.jpg',
            },
            regalia: 'генеральный директор ООО «БМТ-Интер» (Москва)',
          },
        ],
      },
    ],
    chatDisabled: true,
    showOnlineUsers: true,
  },
  {
    id: '2',
    published: true,
    start: new Date(2021, 7, 31, 19, 30),
    end: new Date(2021, 8, 2, 17, 0),
    duration: 1 * 60 + 30,
    points: 1,
    quota: 10,
    price: 100,
    remainingQuota: 0,
    title:
      'Определение момента смерти человека и прекращения реанимационных мероприятий',
    titleFormatted:
      'Определение момента смерти человека и прекращения реанимационных мероприятий',
    type: 'Лекция',
    format: 'online',
    fileDescription: '',
    description:
      'Целевая аудитория: врачи-акушеры-гинекологи, врачи клинической лабораторной диагностики',
    address: 'https://nmo.samsmu.ru/event/a&g2021112526',
    sponsors: [],
    organizers: [],
    specialities: [specialities[0], specialities[1]],
    shedule: [
      {
        id: '1',
        start: new Date(2021, 7, 31, 9, 30),
        end: new Date(2021, 7, 31, 10, 0),
        title: 'Регистрация участников',
        description: '',
        period: '',
        speakers: [],
      },
      {
        id: '2',
        start: new Date(2021, 7, 31, 10, 0),
        end: new Date(2021, 7, 31, 10, 10),
        title: 'Открытие конференции. Приветственное слово.',
        description: '',
        period: '',
        speakers: [],
      },
      {
        id: '3',
        start: new Date(2021, 7, 31, 10, 10),
        end: new Date(2021, 7, 31, 10, 40),
        title:
          'Отмена цикла, связанного с лабораторией ЭКО: как избежать и как справиться',
        description: '',
        period: '',
        speakers: [],
      },
      {
        id: '3',
        start: new Date(2021, 8, 1, 10, 40),
        end: new Date(2021, 8, 1, 12, 10),
        title:
          'Электронный контроль движения биологических материалов в лаборатории ЭКО',
        description: '*не обеспечено кредитами НМО. При поддержке компании БМТ',
        period: '',
        speakers: [],
      },
      {
        id: '11',
        start: new Date(2021, 8, 1, 10, 10),
        end: new Date(2021, 8, 1, 10, 40),
        title:
          'Отмена цикла, связанного с лабораторией ЭКО: как избежать и как справиться',
        description: '',
        period: '',
        speakers: [
          {
            id: '5',
            order: 1,
            fullName: 'Ковальский Борис Юрьевич',
            photoLink: {
              url:
                'http://medkarm.ru/gallery_gen/85124bd74d8837961859ba3607236904.jpg',
            },
            regalia: 'генеральный директор ООО «БМТ-Интер» (Москва)',
          },
        ],
      },
      {
        id: '12',
        start: new Date(2021, 8, 1, 10, 40),
        end: new Date(2021, 8, 1, 12, 10),
        title:
          'Электронный контроль движения биологических материалов в лаборатории ЭКО',
        description: '*не обеспечено кредитами НМО. При поддержке компании БМТ',
        period: '',
        speakers: [
          {
            id: '5',
            order: 1,
            fullName: 'Ковальский Борис Юрьевич',
            photoLink: {
              url:
                'http://medkarm.ru/gallery_gen/85124bd74d8837961859ba3607236904.jpg',
            },
            regalia: 'генеральный директор ООО «БМТ-Интер» (Москва)',
          },
        ],
      },
    ],
    chatDisabled: false,
    showOnlineUsers: true,
  },
  {
    id: '4',
    published: true,
    start: new Date(2021, 8, 3, 12, 20),
    duration: 2 * 60 + 0,
    points: 0,
    title:
      'Проведение патолого-анатомических вскрытий. Определение момента смерти человека и прекращения реанимационных мероприятий. Проведение патолого-анатомических вскрытий. ',
    titleFormatted:
      'Проведение патолого-анатомических вскрытий. Определение момента смерти человека и прекращения реанимационных мероприятий. Проведение патолого-анатомических вскрытий. ',
    type: 'Конференция',
    format: 'online',
    fileDescription: '',
    description:
      'Целевая аудитория: врачи-акушеры-гинекологи, врачи клинической лабораторной диагностики',
    address:
      'г. Самара, Волжское шоссе, 70, Клинический госпиталь ИДК,  6 этаж, конференц-зал',
    sponsors: [],
    organizers: [],
    specialities: [specialities[1], specialities[2]],
    shedule: [],
    chatDisabled: false,
    showOnlineUsers: true,
  },
  {
    id: '5',
    published: true,
    start: new Date(2021, 9, 10, 14, 45),
    end: new Date(2021, 9, 13, 10, 30),
    duration: 1 * 60 + 45,
    points: 15,
    title: 'Использование тела, органов и тканей умершего человека.',
    titleFormatted: 'Использование тела, органов и тканей умершего человека.',
    type: 'Лекция',
    format: 'off-line',
    fileDescription: '',
    description:
      'Целевая аудитория: врачи-акушеры-гинекологи, врачи клинической лабораторной диагностики',
    address:
      'г. Самара, Волжское шоссе, 70, Клинический госпиталь ИДК,  6 этаж, конференц-зал',
    sponsors: [],
    organizers: [],
    specialities: [specialities[2]],
    shedule: [],
    chatDisabled: false,
    showOnlineUsers: true,
  },
  {
    id: '6',
    published: true,
    start: getCurrentDateHour(-49),
    duration: 0 * 60 + 25,
    points: 10,
    title: 'Тестовый вебинар (уже закончился)',
    titleFormatted: '<i>Тестовый вебинар</i> (уже закончился)',
    type: 'Семинар',
    format: 'onine',
    fileDescription: '',
    description: 'Тест',
    address:
      'г. Самара, Волжское шоссе, 70, Клинический госпиталь ИДК,  6 этаж, конференц-зал',
    sponsors: [],
    organizers: [],
    specialities: [specialities[5], specialities[3]],
    shedule: [
      {
        id: '1',
        start: getCurrentDateHour(-49),
        end: getCurrentDateHour(-47),
        title: 'Вебинар',
        description: '',
        period: '',
        speakers: [],
      },
    ],
    chatDisabled: false,
    showOnlineUsers: true,
  },
  {
    id: '7',
    published: true,
    start: getCurrentDateHour(),
    duration: 2 * 60 + 30,
    registrationOverdue: 120 * 60,
    points: 5,
    title: 'Тестовая лекция 2',
    titleFormatted: 'Тестовая лекция 2',
    type: 'Лекция',
    format: 'online',
    fileDescription: '',
    description:
      'Тестовая лекция, которая всегда начинается в 0 минут текущего часа',
    address:
      'г. Самара, Волжское шоссе, 70, Клинический госпиталь ИДК,  6 этаж, конференц-зал',
    sponsors: [mockSponsors[0], ...mockSponsors.slice(3)],
    organizers: [mockSponsors[0], mockSponsors[2]],
    specialities: [specialities[0], specialities[1]],
    shedule: [
      {
        id: '1',
        start: getCurrentDateHour(),
        end: getCurrentDateHour(2, 30),
        title: 'Лекция',
        description: '',
        period: '',
        speakers: [],
      },
    ],
    chatDisabled: false,
    showOnlineUsers: true,
  },
  {
    id: '8',
    published: true,
    start: getCurrentDateHour(72),
    duration: 2 * 60 + 30,
    registrationOverdue: 120 * 60,
    points: 5,
    title: 'Тестовая лекция 3',
    titleFormatted: 'Тестовая лекция 3',
    type: 'Аудитория',
    format: 'online',
    fileDescription: '',
    description: 'Тестовая лекция, которая всегда начинается через 72 часа',
    address:
      'г. Самара, Волжское шоссе, 70, Клинический госпиталь ИДК,  6 этаж, конференц-зал',
    sponsors: [...mockSponsors.slice(3)],
    organizers: [mockSponsors[1]],
    specialities: [specialities[1], specialities[2]],
    shedule: [
      {
        id: '1',
        start: getCurrentDateHour(72),
        end: getCurrentDateHour(72, 30),
        title: 'Лекция',
        description: '',
        period: '',
        speakers: [],
      },
    ],
    chatDisabled: false,
    showOnlineUsers: true,
  },
  {
    id: '9',
    published: true,
    start: new Date(2021, 8, 3, 14, 20),
    duration: 2 * 60 + 0,
    points: 0,
    title:
      'Проведение патолого-анатомических вскрытий. Определение момента смерти человека и прекращения реанимационных мероприятий. Проведение патолого-анатомических вскрытий. ',
    titleFormatted:
      'Проведение патолого-анатомических вскрытий. Определение момента смерти человека и прекращения реанимационных мероприятий. Проведение патолого-анатомических вскрытий. ',
    type: 'Конференция',
    format: 'online',
    fileDescription: '',
    description:
      'Целевая аудитория: врачи-акушеры-гинекологи, врачи клинической лабораторной диагностики',
    address:
      'г. Самара, Волжское шоссе, 70, Клинический госпиталь ИДК,  6 этаж, конференц-зал',
    sponsors: [],
    organizers: [],
    specialities: [specialities[1], specialities[2]],
    shedule: [],
    chatDisabled: false,
    showOnlineUsers: true,
  },
  {
    id: '10',
    published: true,
    start: new Date(2021, 10, 15, 10, 0),
    duration: 2 * 60 + 0,
    points: 0,
    title:
      'Проведение патолого-анатомических вскрытий. Определение момента смерти человека и прекращения реанимационных мероприятий. Проведение патолого-анатомических вскрытий. ',
    titleFormatted:
      'Проведение патолого-анатомических вскрытий. Определение момента смерти человека и прекращения реанимационных мероприятий. Проведение патолого-анатомических вскрытий. ',
    type: 'Конференция',
    format: 'online',
    fileDescription: '',
    description:
      'Целевая аудитория: врачи-акушеры-гинекологи, врачи клинической лабораторной диагностики',
    address:
      'г. Самара, Волжское шоссе, 70, Клинический госпиталь ИДК,  6 этаж, конференц-зал',
    sponsors: [],
    organizers: [],
    specialities: [specialities[1], specialities[2]],
    shedule: [],
    chatDisabled: false,
    showOnlineUsers: true,
  },
];
