import { Sponsor } from '../Sponsor';

export interface SponsorRestApiDto {
  id: string;
  logoId: string | null;
  logoLink: string | null;
  name: string | null;
  pageLink: string | null;
  primary: boolean | null;
  order: number | null;
}

export function mapSponsorRestApiDto(sponsor: SponsorRestApiDto): Sponsor {
  return {
    id: sponsor.id,
    logo: sponsor.logoId
      ? { fileId: sponsor.logoId }
      : sponsor.logoLink
      ? { fileId: sponsor.logoLink }
      : undefined,
    name: sponsor.name || '',
    url: sponsor.pageLink || '',
    primary: sponsor.primary || undefined,
    order: sponsor.order || 0,
  };
}
