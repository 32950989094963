import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Catalog from '../catalog/Catalog';
import EventPage from '../event/EventPage';
import NotFoundPage from '../layout/NotFoundPage';
import UserPage from '../user/UserPage';
import ContactsPage from '../contacts/ContactsPage';
import PhotosPage from '../photos/PhotosPage';
import appRoutes from './routes';
import PaymentPage from '../event/payment/PaymentPage';

export const AppRouter: React.FC = () => {
  return (
    <Switch>
      <Route path={appRoutes.user} component={UserPage} />
      <Route path={appRoutes.payment} component={PaymentPage} />
      <Route path={appRoutes.event} component={EventPage} />
      <Route path={appRoutes.contacts} component={ContactsPage} />
      <Route path={appRoutes.photos} component={PhotosPage} />
      <Route exact path={appRoutes.home} component={Catalog} />
      <Route exact path={appRoutes.archive} component={Catalog} />
      <Route component={NotFoundPage} />
    </Switch>
  );
};
