import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons';
import React from 'react';
import ImgBackend from '../../../restapi/ImgBackend';
import { Message } from '../../../_data/Message';
import { useLocalizationContext } from '../../../_localization/LocalizationContext';
import { uiMessages } from '../../../_localization/ui.messages';

interface ChatMessageProps {
  message: Message;
  my: boolean;
  editable: boolean;
  active: boolean;
  onSetActive: (message: Message) => void;
}

const ChatMessage: React.FC<ChatMessageProps> = ({
  message,
  my,
  editable,
  active,
  onSetActive,
}) => {
  const { language } = useLocalizationContext();
  const canEdit = (my || editable) && message.id && !message.deletedDate;
  return (
    <div
      className={
        'chat-message' +
        (my ? ' my' : '') +
        (canEdit ? ' editable' : '') +
        (active ? ' active highlited' : '') +
        (message.deletedDate ? ' deleted' : '')
      }
      onClick={() => {
        if (canEdit) onSetActive(message);
      }}
    >
      {canEdit && (
        <div className="edit-message">
          <EditIcon />
        </div>
      )}
      <div className="avatar">
        <ImgBackend srcFile={{ fileId: message.photoId }} />
      </div>
      <div className="text-container">
        <div className="author">{message.userName}</div>
        <div className="text">
          {!message.deletedDate
            ? message.message
            : uiMessages.chat_MessageDeleted[language]}
        </div>
        <div className="date">
          {message.timestamp.toLocaleTimeString().slice(0, 5)}
        </div>
      </div>
    </div>
  );
};

export default ChatMessage;
