import { Speciality } from '../Speciality';

export const specialities: Speciality[] = [
  { id: '1', title: 'Медицинская биофизика' },
  { id: '2', title: 'Анатомии человека' },
  { id: '3', title: 'Гистология и эмбриология' },
  { id: '4', title: 'Глазных болезней ИПО' },
  { id: '5', title: 'Госпитальная педиатрия' },
  { id: '6', title: 'Детская хирургия' },
  { id: '7', title: 'Медицинское права и биоэтика' },
  { id: '8', title: 'Медицинская химия' },
  { id: '9', title: 'Неврология и нейрохирургия' },
  { id: '10', title: 'Медицинская биофизика' },
  { id: '11', title: 'Анатомия человека' },
];
