import './DownloadFile.css';
import React from 'react';
import { useLocalizationContext } from '../_localization/LocalizationContext';
import { uiMessages } from '../_localization/ui.messages';

interface DownloadFileProps {
  text?: string;
  withIcon?: boolean;
  link: string;
}

export const DownloadFile: React.FC<DownloadFileProps> = ({
  text,
  withIcon,
  link,
}) => {
  const { language } = useLocalizationContext();
  return (
    <a href={link} className={'downloadFile' + (withIcon ? ' withIcon' : '')}>
      {text || uiMessages.download[language]}
    </a>
  );
};
