import './Webinar.css';
import React, { useEffect } from 'react';
import Chat from './chat/Chat';
import VideoPlayer from './video/VideoPlayer';
import Quiz from './quiz/Quiz';
import { SheduledEvent } from '../../_data/SheduledEvent';
import { WebinarContextProvider } from './Webinar.Provider';
import { useAuthContext } from '../../auth/AuthContext';
import useDataLoading from '../../_hooks/useDataLoading';
import { useEventRestApi } from '../EventPage.utils';
import { ErrorMessage } from '../../_components/Messages';
import { LoadingSpinner } from '../../_components/Spinner';
import { uiMessages } from '../../_localization/ui.messages';
import { useLocalizationContext } from '../../_localization/LocalizationContext';

interface WebinarProps {
  event: SheduledEvent;
}

const Webinar: React.FC<WebinarProps> = ({ event }) => {
  const { language } = useLocalizationContext();
  const { user, userProfile } = useAuthContext();
  const { getRegistration } = useEventRestApi();
  const registrationLoading =
    user && useDataLoading(() => getRegistration(event.id, user, language));

  useEffect(() => {
    registrationLoading?.reloadData();
  }, [event.id, user?.id, language]);

  return !user ? (
    <ErrorMessage>
      {uiMessages.errors_onWebinarNotAuthorized[language]}
    </ErrorMessage>
  ) : !registrationLoading || registrationLoading.loading ? (
    <LoadingSpinner />
  ) : registrationLoading.error ? (
    <ErrorMessage>
      {uiMessages.errors_onWebinarCheckRegisteration[language]}
    </ErrorMessage>
  ) : !registrationLoading.data ? (
    <ErrorMessage>
      {uiMessages.errors_onWebinarNotRegistered[language]}
    </ErrorMessage>
  ) : (
    <WebinarContextProvider event={event}>
      <div
        className={
          'webinar' +
          (registrationLoading?.data?.roles?.match('speaker') ? ' novideo' : '')
        }
      >
        {!registrationLoading?.data?.roles?.match('speaker') && (
          <div className="webinar-video">
            <VideoPlayer
              url={registrationLoading.data.videoUrl || ''}
              cameraName={registrationLoading.data.cameraName || language}
            />
          </div>
        )}
        <div className="webinar-chat">
          <Quiz />
          {user && (
            <Chat
              user={user}
              userProfile={userProfile}
              isModerator={!!registrationLoading.data.roles?.match('moderator')}
              eventId={event.id}
              showOnlineUsers={event.showOnlineUsers}
            />
          )}
        </div>
      </div>
    </WebinarContextProvider>
  );
};

export default Webinar;
