import React from 'react';
import EventHeader from './header/EventHeader';
import EventSponsors from './sponsors/EventSponsors';
import EventShedule from './shedule/EventShedule';
import useDataLoading from '../_hooks/useDataLoading';
import { useEventRestApi } from './EventPage.utils';
import { LoadingSpinner } from '../_components/Spinner';
import { useParams } from 'react-router';
import NotFoundPage from '../layout/NotFoundPage';
import { InnerPage } from '../layout/InnerPage';
import { isArchiveDate } from '../utils/dates';
import appRoutes from '../navigation/routes';
import { getApiFileUrl } from '../restapi/backendPaths';
import { useRestApiContext } from '../restapi/RestApiContext';
import { useLocalizationContext } from '../_localization/LocalizationContext';
import { uiMessages } from '../_localization/ui.messages';

const EventPage: React.FC = () => {
  const { language } = useLocalizationContext();
  const { id } = useParams<{ id: string }>();
  const { accessToken } = useRestApiContext();
  const { getEvent } = useEventRestApi();
  const { data: event, loading, error, reloadData } = useDataLoading(() =>
    getEvent(id),
  );

  React.useEffect(() => {
    reloadData();
  }, [language]);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error || !event) {
    return <NotFoundPage />;
  }

  return (
    <InnerPage
      className="event"
      header={<EventHeader event={event} />}
      backLink={{
        title: uiMessages.nav_BackToCatalog[language],
        link: !isArchiveDate(event.end || event.start)
          ? appRoutes.home
          : appRoutes.archive,
      }}
      headerBackgroundUrl={
        event.backgroundFileId &&
        getApiFileUrl(event.backgroundFileId, accessToken)
      }
      middle={
        (event.sponsors.length > 0 || event.organizers.length > 0) && (
          <div className="event-sponsors">
            <EventSponsors
              sponsors={event.sponsors}
              organizers={event.organizers}
            />
          </div>
        )
      }
    >
      <div className="event-shedule">
        <EventShedule shedule={event.shedule} />
      </div>
    </InnerPage>
  );
};

export default EventPage;
