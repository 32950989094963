import React from 'react';
import { useAuthContext } from '../auth/AuthContext';
import { useLocalizationContext } from '../_localization/LocalizationContext';
import { uiMessages } from '../_localization/ui.messages';
import { InnerPage } from './InnerPage';

const NotAuthorizedPage: React.FC = () => {
  const { login } = useAuthContext();
  const { language } = useLocalizationContext();
  return (
    <InnerPage header={<h1>{uiMessages.notAuth_Title[language]}</h1>}>
      <div>{uiMessages.notAuth_Excuse[language]}</div>
      <div>
        <button className="button" onClick={() => login()}>
          {uiMessages.nav_Login[language]}
        </button>
      </div>
    </InnerPage>
  );
};

export default NotAuthorizedPage;
