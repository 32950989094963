import './Chat.css';
import React from 'react';
import { getUserName, User, UserProfile } from '../../../_data/User';
import ChatMessageList from './ChatMessageList';
import ChatSendMessage from './ChatSendMessage';
import { useWebinarContext } from '../Webinar.Provider';
import { Message } from '../../../_data/Message';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import { ChatModeration } from './ChatModeration';
import { uiMessages } from '../../../_localization/ui.messages';
import { useLocalizationContext } from '../../../_localization/LocalizationContext';

interface ChatProps {
  user: User;
  userProfile?: UserProfile;
  isModerator: boolean;
  eventId: string;
  showOnlineUsers: boolean;
}

const Chat: React.FC<ChatProps> = ({
  user,
  userProfile,
  isModerator,
  eventId,
  showOnlineUsers,
}) => {
  const {
    messages,
    onSendMessage,
    onDeleteMessage,
    onEditMessage,
    usersCount,
  } = useWebinarContext();
  const { language } = useLocalizationContext();

  const [activeMessage, setActiveMessage] = React.useState<Message | undefined>(
    undefined,
  );

  return (
    <div className="chat">
      {isModerator && (
        <div className="command-container">
          <ChatModeration />
          {showOnlineUsers && (
            <div
              className="users-count-container"
              title={uiMessages.chat_UsersOnline[language]}
            >
              <RemoveRedEyeIcon fontSize="small" />
              <div className="users-count">
                {usersCount !== undefined ? usersCount : '?'}
              </div>
            </div>
          )}
        </div>
      )}

      <ChatMessageList
        messages={messages}
        user={user}
        isModerator={isModerator}
        activeMessage={activeMessage}
        setActiveMessage={setActiveMessage}
      />
      <ChatSendMessage
        messageToEdit={activeMessage}
        onSendMessage={(msg) => {
          if (!onSendMessage) return;
          const newMessage: Message = {
            eventId: eventId,
            userId: user.id,
            userName: getUserName(user),
            photoId: userProfile?.photo?.fileId,
            timestamp: new Date(),
            message: msg,
          };
          onSendMessage(newMessage);
        }}
        onCancelEdit={() => setActiveMessage(undefined)}
        onEditMessage={(msg) => {
          if (!onEditMessage) return;
          onEditMessage(msg);
          setActiveMessage(undefined);
        }}
        onDeleteMessage={(msg) => {
          if (!onDeleteMessage) return;
          onDeleteMessage(msg);
          setActiveMessage(undefined);
        }}
      />
    </div>
  );
};

export default Chat;
