import React from 'react';

interface EventHeaderFormatProps {
  eventFormat: string;
}
export const EventHeaderFormat: React.FC<EventHeaderFormatProps> = ({
  eventFormat,
}) => {
  return <div className="eventHeader-format">{eventFormat}</div>;
};
