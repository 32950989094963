import './EventsList.css';
import React from 'react';
import { useHistory } from 'react-router';
import appRoutes, { getActualLink } from '../navigation/routes';
import { SheduledEvent } from '../_data/SheduledEvent';
import { format } from 'date-fns';
import { useCatalogRestApi } from '../catalog/Catalog.utils';
import { Speciality } from '../_data/Speciality';
import { LoadingSpinner } from '../_components/Spinner';
import { ErrorMessage } from '../_components/Messages';
import useDataLoading from '../_hooks/useDataLoading';
import { EventDate } from './EventDate';
import { useLocalizationContext } from '../_localization/LocalizationContext';
import { uiMessages } from '../_localization/ui.messages';

function getEventMonth(event: SheduledEvent, locale: Locale): string {
  return format(event.start, 'LLLL yyyy', { locale });
}

function sortByStartEndDate(data: SheduledEvent[], desc = false) {
  data?.sort((a, b) =>
    a.start > b.start || (a.end && b.end && a.end > b.end)
      ? desc
        ? 1
        : -1
      : a.start < b.start || (a.end && b.end && a.end < b.end)
      ? desc
        ? -1
        : 1
      : 0,
  );
  return data;
}

interface EventsMonthListProps {
  month: string;
  events: SheduledEvent[];
  orderAsc?: boolean;
}

const EventsMonthList: React.FC<EventsMonthListProps> = ({ month, events }) => {
  const history = useHistory();
  const { language, locale } = useLocalizationContext();

  return (
    <div>
      <h3>{month}</h3>
      <table>
        <colgroup>
          <col className="col-date" />
          <col className="col-time" />
          <col className="col-type" />
          <col />
        </colgroup>
        <thead>
          <tr>
            <th>{uiMessages.event_Date[language]}</th>
            <th>{uiMessages.event_Start[language]}</th>
            <th>{uiMessages.event_Type[language]}</th>
            <th>{uiMessages.event_EventTitle[language]}</th>
          </tr>
        </thead>
        <tbody>
          {events.map((event) => (
            <tr
              key={event.id}
              className="pointer"
              onClick={(e) => {
                e.preventDefault();
                const link = getActualLink(appRoutes.event, [
                  { key: 'id', value: event.link || event.id },
                ]);
                history.push(link);
              }}
            >
              <td className="event-date">
                <EventDate start={event.start} end={event.end} />
              </td>
              <td className="event-time">
                {format(event.start, 'HH:mm', { locale })}
              </td>
              <td className="event-type">{event.type}</td>
              <td className="event-title">{event.title}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

interface EventsListProps {
  startDate: Date;
  endDate: Date;
  filteredSpecialities: Speciality[];
  search?: string;
  archive?: boolean;
}

const EventsList: React.FC<EventsListProps> = ({
  startDate,
  endDate,
  filteredSpecialities,
  search,
  archive = false,
}) => {
  const { language, locale } = useLocalizationContext();
  const { getArchive, getEvents } = useCatalogRestApi();
  const { data: events, error, loading, reloadData } = useDataLoading(() =>
    (archive ? getArchive(language) : getEvents(language)).then((events) => {
      return events ? sortByStartEndDate(events, !archive) : undefined;
    }),
  );

  React.useEffect(() => {
    reloadData();
  }, [language]);

  if (error) {
    return <ErrorMessage>{error}</ErrorMessage>;
  }

  if (loading) {
    return <LoadingSpinner />;
  }

  let filteredEvents =
    (archive
      ? events
      : events?.filter((event) => {
          return (
            ((event.end || event.start) >= startDate ||
              event.start >= startDate) &&
            ((event.end || event.start) <= endDate || event.start <= endDate)
          );
        })) || [];
  if (filteredSpecialities && filteredSpecialities.length > 0) {
    filteredEvents = filteredEvents.filter((event) =>
      event.specialities.some((esp) =>
        filteredSpecialities.some((fsp) => esp.id === fsp.id),
      ),
    );
  }
  if (search) {
    const searchRegexp = new RegExp(search, 'i');
    filteredEvents = filteredEvents.filter((event) =>
      event.title.match(searchRegexp),
    );
  }
  const months = filteredEvents.map((event) => getEventMonth(event, locale));
  const eventsByMonth: {
    [x: string]: SheduledEvent[];
  } = {};
  months.forEach((month) => {
    eventsByMonth[month] = filteredEvents.filter(
      (event) => month === getEventMonth(event, locale),
    );
  });

  return (
    <div className="eventsList">
      {filteredEvents.length
        ? Object.keys(eventsByMonth).map((month) => (
            <EventsMonthList
              key={`eventsList-month-${month}`}
              month={month}
              events={eventsByMonth[month]}
              orderAsc={!archive}
            />
          ))
        : uiMessages.events_Empty[language]}
    </div>
  );
};

export default EventsList;
