import iconEntrance from '../../_img/Entrance.svg';
import iconNMO from '../../_img/NMO.svg';
import iconTime from '../../_img/Time.svg';
import React from 'react';
import { SheduledEvent } from '../../_data/SheduledEvent';
import { EventHeaderDate } from './EventHeaderDate';
import { EventHeaderFormat } from './EventHeaderFormat';
import { EventHeaderOption } from './EventHeaderOption';
import { EventHeaderRegister } from './EventHeaderRegister';
import { EventHeaderCard } from './EventHeaderCard';
import { getApiFileUrl } from '../../restapi/backendPaths';
import { DownloadFile } from '../../_components/DownloadFile';
import { uiMessages } from '../../_localization/ui.messages';
import { useLocalizationContext } from '../../_localization/LocalizationContext';

interface EventHeaderInfoProps {
  event: SheduledEvent;
}
export const EventHeaderInfo: React.FC<EventHeaderInfoProps> = ({ event }) => {
  const { language } = useLocalizationContext();
  return (
    <div className="eventInfo">
      <div className="eventHeader-date_format">
        <EventHeaderDate start={event.start} end={event.end} />
        {event.format && <EventHeaderFormat eventFormat={event.format} />}
      </div>
      <div className="eventHeader-options">
        <EventHeaderOption
          icon={iconEntrance}
          title={uiMessages.event_Entrance[language]}
          status={
            event.price && event.price > 0
              ? `${event.price} ${uiMessages.rubles[language]}`
              : uiMessages.priceFree[language]
          }
        />
        <EventHeaderOption
          icon={iconNMO}
          title={uiMessages.event_NmoScore[language]}
          status={event.points.toFixed(0)}
        />
        {event.duration ? (
          <EventHeaderOption
            icon={iconTime}
            title={uiMessages.event_Duration[language]}
            status={event.duration.toFixed(0)}
          />
        ) : null}
      </div>
      <div className="eventHeader-linked"></div>
      <div className="eventHeader-title">
        <h2 dangerouslySetInnerHTML={{ __html: event.titleFormatted }}></h2>
        {event.titleExtra && (
          <div
            dangerouslySetInnerHTML={{ __html: event.titleExtra }}
            className="eventHeader-card-text"
          ></div>
        )}
      </div>
      <EventHeaderRegister event={event} />
      <div className="eventHeader-address">
        {event.address && (
          <EventHeaderCard
            title={uiMessages.event_Location[language]}
            area="address"
            preformatted={event.address}
          >
            {event.addressExtra && (
              <div
                dangerouslySetInnerHTML={{ __html: event.addressExtra }}
              ></div>
            )}
          </EventHeaderCard>
        )}
      </div>
      {event.specialities.length > 0 && (
        <EventHeaderCard
          title={uiMessages.event_TargetAudience[language]}
          area="specialities"
        >
          {event.specialities.map((sp) => (
            <li key={sp.id}>{sp.title}</li>
          ))}
        </EventHeaderCard>
      )}
      <div className="eventHeader-cards">
        {(event.description || event.fileId) && (
          <EventHeaderCard
            title={uiMessages.event_Description[language]}
            area="description"
            preformatted={event.description}
          >
            {event.fileId && (
              <div className="eventHeader-card-download">
                <DownloadFile
                  link={getApiFileUrl(event.fileId)}
                  withIcon={true}
                  text={event.fileDescription}
                />
              </div>
            )}
          </EventHeaderCard>
        )}
        {event.control && (
          <EventHeaderCard
            title={uiMessages.event_ControlInfo[language]}
            area="control"
          >
            {event.control}
          </EventHeaderCard>
        )}
      </div>
    </div>
  );
};
