import React, { useState, useEffect, createContext, useContext } from 'react';
import { HttpResponse, getApiUrl } from './api';
import { TokenResponseRestApiDto } from '../_data/restApiDto/TokenResponseRestApiDto';

const getTokenPath = 'v2/oauth/token';

export type HttpMethod<T> = (
  path: string,
  body?: any,
  args?: RequestInit,
) => Promise<HttpResponse<T>>;

export type HttpGetMethod<T> = (
  path: string,
  args?: RequestInit,
) => Promise<HttpResponse<T>>;

export interface RestApiContextProps {
  loadingToken: boolean;
  accessToken: string | undefined;
}

export const RestApiContext = createContext<RestApiContextProps>({
  loadingToken: false,
  accessToken: undefined,
});

export const useRestApiContext = () => useContext(RestApiContext);

export const RestApiContextProvider: React.FC = ({ children }) => {
  const [loadingToken, setLoadingToken] = useState(true);
  const [token, setToken] = useState<TokenResponseRestApiDto | null>(null);

  useEffect(() => {
    let timeoutHandler: NodeJS.Timeout;
    if (token && token.expires_in) {
      timeoutHandler = setTimeout(
        () => getAccessToken(),
        token.expires_in * 999,
      );
    }
    return () => {
      if (timeoutHandler) {
        clearTimeout(timeoutHandler);
      }
    };
  }, [token]);

  const getAccessToken = async () => {
    if (token?.access_token) {
      return token?.access_token;
    }
    try {
      setLoadingToken(true);
      const tokenResonse: HttpResponse<any> = await fetch(
        new Request(getApiUrl(getTokenPath), {
          method: 'post',
        }),
      );
      setToken(await tokenResonse.json());
      return token?.access_token;
    } catch (ex) {
      return '';
    } finally {
      setLoadingToken(false);
    }
  };

  useEffect(() => {
    if (loadingToken) {
      const timeoutHandler = setTimeout(getAccessToken, 500);
      return () => clearTimeout(timeoutHandler);
    }
    getAccessToken().catch(() => setToken(null));
  }, []);

  return (
    <RestApiContext.Provider
      value={{ loadingToken, accessToken: token?.access_token || undefined }}
    >
      {children}
    </RestApiContext.Provider>
  );
};
