import React from 'react';
import { useLocalizationContext } from '../../_localization/LocalizationContext';
import { uiMessages } from '../../_localization/ui.messages';

export interface SelectFieldOption {
  id: string;
  value: string;
}

interface SelectFieldProps {
  name: string;
  initialSelectedId?: string;
  options: SelectFieldOption[];
  onOptionSelect?: (optionId: string | undefined) => void;
}

const SelectField: React.FC<SelectFieldProps> = ({
  name,
  initialSelectedId,
  options,
  onOptionSelect,
}) => {
  const { language } = useLocalizationContext();
  const [selectedId, setSelectedId] = React.useState(initialSelectedId);
  const [open, setOpen] = React.useState(false);
  const inputRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    const listener = () => setOpen(false);
    if (open) {
      document.addEventListener('click', listener);
    } else {
      document.removeEventListener('click', listener);
    }
    return () => document.removeEventListener('click', listener);
  }, [open]);

  React.useEffect(() => {
    if (onOptionSelect) {
      onOptionSelect(selectedId);
    }
  }, [selectedId, onOptionSelect]);

  const openSelection = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedId(undefined);
  };
  return (
    <div className={'inputSelect' + (open ? ' open' : '')}>
      <input
        type="hidden"
        name={name}
        value={selectedId || ''}
        ref={inputRef}
        onBlur={() => setOpen(false)}
      />
      <div
        className="selectedOption"
        onClick={(e) => {
          e.preventDefault();
          setOpen(true);
          if (inputRef?.current) {
            (inputRef?.current as HTMLInputElement).focus();
          }
        }}
      >
        {options.find((o) => o.id === selectedId)?.value ||
          uiMessages.form_NothingSelected[language]}
      </div>
      <div className={'optionsList'}>
        {open && (
          <ul>
            <li
              className={
                'optionsList-option nothing' + (!selectedId ? ' selected' : '')
              }
              onMouseDown={openSelection}
              onTouchStart={openSelection}
            >
              {uiMessages.form_NothingSelected[language]}
            </li>
            {options.map((o) => (
              <li
                className={
                  'optionsList-option' +
                  (o.id === selectedId ? ' selected' : '')
                }
                key={'option-' + name + '=' + o.id}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setSelectedId(o.id);
                }}
              >
                {o.value}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default SelectField;
