import { KeyboardArrowDown } from '@material-ui/icons';
import React from 'react';
import { Message } from '../../../_data/Message';
import { User } from '../../../_data/User';
import ChatMessage from './ChatMessage';

interface ChatMessageListProps {
  messages: Message[];
  user: User;
  isModerator: boolean;
  activeMessage?: Message;
  setActiveMessage: (message: Message) => void;
}

const ChatMessageList: React.FC<ChatMessageListProps> = ({
  messages,
  user,
  isModerator,
  activeMessage,
  setActiveMessage,
}) => {
  const listRef = React.useRef<HTMLDivElement>(null);
  const [atBottom, setAtBottom] = React.useState(true);

  const isAtBottom = () =>
    !!listRef.current &&
    listRef.current?.scrollHeight ===
      listRef.current?.scrollTop + listRef.current?.clientHeight;

  const scrollToBottom = () => {
    if (listRef && listRef.current) {
      setAtBottom(true);
      const list = listRef.current;
      list.scrollTo({
        top: list.scrollHeight - list.clientHeight,
        behavior: 'smooth',
      });
    }
  };

  React.useEffect(() => {
    if (atBottom && listRef.current) {
      scrollToBottom();
    }
  }, [messages.length]);

  const sortedMessages = Array.from(messages);
  sortedMessages.sort((msgL, msgR) =>
    msgL.timestamp > msgR.timestamp
      ? 1
      : msgL.timestamp > msgR.timestamp
      ? -1
      : 0,
  );
  return (
    <div className="message-list-container">
      <div
        className="message-list-tobottom"
        hidden={atBottom}
        onClick={scrollToBottom}
      >
        <KeyboardArrowDown />
      </div>
      <div
        className="message-list"
        ref={listRef}
        onScroll={(e) => {
          e.stopPropagation();
          setAtBottom(isAtBottom());
        }}
      >
        {sortedMessages.map((msg, i) => (
          <ChatMessage
            key={i}
            message={msg}
            my={msg.userId === user.id}
            editable={isModerator}
            active={!!activeMessage && msg.id === activeMessage.id}
            onSetActive={setActiveMessage}
          />
        ))}
      </div>
    </div>
  );
};

export default ChatMessageList;
