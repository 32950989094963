import './Search.css';
import {
  ClickAwayListener,
  Grow,
  IconButton,
  Input,
  InputAdornment,
  Paper,
  Popper,
} from '@material-ui/core';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import ClearIcon from '@material-ui/icons/Clear';
import React from 'react';

interface SearchInputProps {
  hidden: boolean;
  onSearchChange: (search: string) => void;
}

export const SearchInput: React.FC<SearchInputProps> = ({
  hidden,
  onSearchChange,
}) => {
  const [value, setValue] = React.useState('');

  React.useEffect(() => {
    onSearchChange(hidden ? '' : value);
  }, [value, hidden]);

  return (
    <Input
      type="text"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      margin="dense"
      className="search-field popper"
      style={{
        width: hidden ? 0 : '100%',
      }}
      endAdornment={
        !hidden ? (
          <InputAdornment position="end">
            <IconButton onClick={() => setValue('')} edge="end" size="small">
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        ) : null
      }
    />
  );
};

interface SearchProps {
  onSearchChange: (search: string) => void;
}

export const SearchAside: React.FC<SearchProps> = ({ onSearchChange }) => {
  const [hidden, setHidden] = React.useState(true);
  const anchorRef = React.useRef<HTMLAnchorElement>(null);
  return (
    <div className="search">
      <a
        href="#"
        ref={anchorRef}
        className="search-button"
        onClick={() => setHidden((hidden) => !hidden)}
      >
        <SearchRoundedIcon fontSize="large" />
      </a>
      <Popper
        open={!hidden}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        className="dropdown"
        placement="bottom-end"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <ClickAwayListener onClickAway={() => setHidden(true)}>
              <Paper className="search-field-popper">
                <SearchInput hidden={hidden} onSearchChange={onSearchChange} />
              </Paper>
            </ClickAwayListener>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

const Search: React.FC<SearchProps> = ({ onSearchChange }) => {
  const [hidden, setHidden] = React.useState(true);
  return (
    <div className="search">
      <a
        href="#"
        className="search-button"
        onClick={() => setHidden((hidden) => !hidden)}
      >
        <SearchRoundedIcon />
      </a>
      <span
        className="search-field-container"
        style={{
          ...(hidden && { width: '0', opacity: '0' }),
        }}
      >
        <SearchInput hidden={hidden} onSearchChange={onSearchChange} />
      </span>
    </div>
  );
};

export default Search;
