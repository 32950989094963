import React from 'react';
import useDataLoading from '../../_hooks/useDataLoading';
import { useEventRestApi } from '../EventPage.utils';
import { SendingSpinner } from '../../_components/Spinner';
import { InnerPage } from '../../layout/InnerPage';
import appRoutes, { getActualLink } from '../../navigation/routes';
import { useAuthContext } from '../../auth/AuthContext';
import { Link } from 'react-router-dom';
import { Participant, Payment } from '../../_data/Registration';
import { useLocalizationContext } from '../../_localization/LocalizationContext';
import { uiMessages } from '../../_localization/ui.messages';

interface PaymentResult {
  eventId: string;
  error: string;
  loading: boolean;
  data: Participant | Payment | undefined;
}

const PaymentPage: React.FC = () => {
  const { language } = useLocalizationContext();
  const { user } = useAuthContext();
  const { postRegistration } = useEventRestApi();
  const [payments, setPayments] = React.useState<PaymentResult[] | undefined>(
    undefined,
  );

  React.useEffect(() => {
    if (!user || !user.id) return;
    const localStorageItemId = 'paymentsOf_' + user.id;
    const eventIdStr = localStorage.getItem(localStorageItemId);
    if (!eventIdStr) {
      localStorage.removeItem(localStorageItemId);
      return;
    }
    const eventIds = (eventIdStr || undefined)?.split(',');

    if (!eventIds) return;

    const paymentResults =
      eventIds?.map((eventId) => {
        const pr: PaymentResult = {
          eventId,
          error: '',
          loading: true,
          data: undefined,
        };
        return pr;
      }) || undefined;

    for (const i in eventIds) {
      if (!eventIds[i]) {
        paymentResults[i].loading = false;
        paymentResults[i].error = uiMessages.payment_NotFound[language];
        return;
      }
      postRegistration(eventIds[i], user)
        .then((data) => {
          if (data && 'eventId' in data) {
            localStorage.setItem(
              localStorageItemId,
              localStorage
                .getItem(localStorageItemId)
                ?.split(',')
                .filter((id) => id !== data.eventId)
                .join(',') || '',
            );
          }
          paymentResults[i].loading = false;
          paymentResults[i].data = data;
          setPayments(paymentResults?.filter((pr) => !!pr.eventId) || []);
        })
        .catch(() => {
          paymentResults[i].loading = false;
          paymentResults[i].error = uiMessages.errors_onCheckPayment[language];
          setPayments(paymentResults?.filter((pr) => !!pr.eventId) || []);
        });
    }
    setPayments(paymentResults?.filter((pr) => !!pr.eventId) || []);
  }, [user?.id]);

  return (
    <InnerPage
      className="event"
      header={uiMessages.payment_Check[language]}
      backLink={{
        title: uiMessages.nav_BackToCatalog[language],
        link: appRoutes.home,
      }}
    >
      {!payments ? (
        <SendingSpinner text={uiMessages.payment_Retrieving[language]} />
      ) : payments.length === 0 ? (
        <div>{uiMessages.payment_NothingToCheck[language]}</div>
      ) : (
        payments.map((pr) => (
          <div key={pr.eventId}>
            {pr.loading ? (
              <SendingSpinner text={uiMessages.payment_Checking[language]} />
            ) : pr.error ||
              !pr.data ||
              ('error' in pr.data && pr.data.error) ? (
              <div>{uiMessages.errors_onCheckPayment[language]}</div>
            ) : 'redirectUrl' in pr.data ? (
              <div>
                {uiMessages.payment_NotFinished[language]}.{' '}
                <a className="link" href={pr.data.redirectUrl}>
                  {uiMessages.goTo[language]}
                </a>{' '}
                {uiMessages.payment_ToBank[language]}
              </div>
            ) : (
              <div>
                {uiMessages.payment_Success[language]}.{' '}
                <Link
                  className="link"
                  to={getActualLink(appRoutes.event, [
                    { key: 'id', value: pr.eventId },
                  ])}
                >
                  {uiMessages.goBack[language]}
                </Link>{' '}
                {uiMessages.payment_ToEvent[language]}
              </div>
            )}
          </div>
        ))
      )}
    </InnerPage>
  );
};

export default PaymentPage;
