import {
  mapSpecialityRestApiDto,
  SpecialityRestApiDto,
} from './SpecialityRestApiDto';
import {
  EventUnitRestApiDto,
  mapEventUnitRestApiDto,
} from './EventUnitRestApiDto';
import {
  mapOrganizerRestApiDto,
  OrganizerRestApiDto,
} from './OrganizerRestApiDto';
import { mapSponsorRestApiDto, SponsorRestApiDto } from './SponsorRestApiDto';
import { SheduledEvent, VisitedEvent } from '../SheduledEvent';

export interface EventRestApiDto {
  backgroundFileId: string | null;
  chatDisabled: boolean | null;
  description: string | null;
  descriptionEn: string | null;
  duration: number | null;
  endTimestamp: string | null;
  eventSpecialtyList: SpecialityRestApiDto[] | null;
  eventType: string | null;
  eventTypeEn: string | null;
  eventUnitList: EventUnitRestApiDto[] | null;
  fileDescription: string | null;
  fileDescriptionEn: string | null;
  fileId: string | null;
  format: string | null;
  formatEn: string | null;
  id: string;
  location: string | null;
  locationEn: string | null;
  location2: string | null;
  location2En: string | null;
  name: string | null;
  nameEn: string | null;
  name2: string | null;
  name2En: string | null;
  organizers: OrganizerRestApiDto[] | null;
  points: number | null;
  presenceControlDescription: string | null;
  presenceControlDescriptionEn: string | null;
  price: string | null;
  published: boolean | null;
  publishedEn: boolean | null;
  quota: number | null;
  registrationOverdueTime: number | null;
  remainingQuota?: number | null;
  shortAddress: string | null;
  showOnlineUsers: boolean | null;
  sponsors: SponsorRestApiDto[] | null;
  timestamp: string | null;
}

export function mapEventRestApiDto(e: EventRestApiDto): SheduledEvent {
  const price = +(e.price || 0);
  const title = e.name || e.nameEn || '';
  const titleWithoutTags = title.replace(/<[^>]*>/g, '');
  const sheduledEvent: SheduledEvent = {
    id: e.id,
    link: e.shortAddress || undefined,
    published: e.published || false,
    title: titleWithoutTags,
    titleFormatted: title,
    titleExtra: e.name2 || e.name2En || undefined,
    description: e.description || e.descriptionEn || '',
    address: e.location || e.locationEn || '',
    addressExtra: e.location2 || e.locationEn || undefined,
    control:
      e.presenceControlDescription || e.presenceControlDescriptionEn || '',
    start: new Date(e.timestamp || 0),
    end: new Date(e.endTimestamp || 0),
    duration: e.duration || 0,
    registrationOverdue: e.registrationOverdueTime || undefined,
    type: e.eventType || e.eventTypeEn || '',
    fileDescription: e.fileDescription || e.fileDescriptionEn || '',
    fileId: e.fileId || undefined,
    format: e.format || e.formatEn || '',
    shedule: e.eventUnitList?.map(mapEventUnitRestApiDto) || [],
    specialities: e.eventSpecialtyList?.map(mapSpecialityRestApiDto) || [],
    organizers: e.organizers?.map((org) => mapOrganizerRestApiDto(org)) || [],
    sponsors: e.sponsors?.map((sp) => mapSponsorRestApiDto(sp)) || [],
    points: e.points || 0,
    price: isNaN(price) ? 0 : price,
    quota: e.quota || undefined,
    remainingQuota: e.remainingQuota || undefined,
    backgroundFileId: e.backgroundFileId || undefined,
    chatDisabled: !!e.chatDisabled,
    showOnlineUsers: !!e.showOnlineUsers,
  };
  sheduledEvent.shedule.forEach((unit) => sortWithPrimaries(unit.speakers));
  sortWithPrimaries(sheduledEvent.sponsors);
  sortWithPrimaries(sheduledEvent.organizers);
  return sheduledEvent;

  function sortWithPrimaries(items: { primary?: boolean; order: number }[]) {
    items.sort(
      (a, b) =>
        a.order - b.order - 10000 * ((a.primary ? 1 : 0) - (b.primary ? 1 : 0)),
    );
  }
}

export interface VisitedEventRestApiDto {
  assessedPoints: number | null;
  code: string | null;
  endTimestamp: string | null;
  id: string;
  name: string | null;
  points: number | null;
  timestamp: string | null;
  type: string | null;
}

export function mapVisitedEventRestApiDto(
  e: VisitedEventRestApiDto,
): VisitedEvent {
  return {
    id: e.id,
    title: e.name || '',
    start: new Date(e.timestamp || 0),
    end: e.endTimestamp ? new Date(e.endTimestamp) : undefined,
    type: e.type || '',
    code: e.code || undefined,
    points: e.assessedPoints || 0,
    maxPoints: e.points || 0,
  };
}
