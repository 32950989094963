import React from 'react';
import { InnerPage } from '../layout/InnerPage';
import { useLocalizationContext } from '../_localization/LocalizationContext';
import { uiMessages } from '../_localization/ui.messages';

const ContactsPage: React.FC = () => {
  const { language } = useLocalizationContext();
  return (
    <InnerPage header={<h1>{uiMessages.contacts[language]}</h1>}>
      <div>
        <p>{uiMessages.contacts_Title[language]}</p>
        <p>
          <strong>{uiMessages.contacts_Department[language]}</strong>
        </p>
        <p>{uiMessages.contacts_Address[language]}</p>
        <p>
          <a href="mailto:infonmo@samsmu.ru">infonmo@samsmu.ru</a>
        </p>
      </div>
    </InnerPage>
  );
};

export default ContactsPage;
