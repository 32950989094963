import './Video.css';
import React, { useEffect, useRef, useState } from 'react';
import { useGcoreApi } from '../../../_hooks/useGcoreApi';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface VideoPlayerProps {
  url: string;
  cameraName?: string;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ url, cameraName }) => {
  const frameRef = useRef(null);
  const { gplayerAPI } = useGcoreApi(frameRef.current || undefined);
  const [cameras, setCameras] = useState<any[]>([]);
  const [currentCameraId, setCurrentCameraId] = useState(0);

  useEffect(() => {
    if (gplayerAPI) {
      //console.log('gpalyerApi: ', gplayerAPI, url, cameraName);
      gplayerAPI.on('ready', () => {
        console.log('gpalyerApi is ready');
        gplayerAPI.method({
          name: 'getPlugin',
          params: {
            pluginName: 'multicamera',
            pluginMethod: 'getCamerasList',
            pluginValue: '',
          },
          callback: (cams) => setCameras(cams),
        });
        setTimeout(() => gplayerAPI.method({ name: 'play' }));
      });
    } else {
      setCameras([]);
      setCurrentCameraId(0);
    }
  }, [gplayerAPI]);

  useEffect(() => {
    console.log('gpalyerApi cameras', cameras);
    if (cameras.length < 2) {
      //console.log('gpalyerApi. no cameras to choose from');
      return;
    }
    if (!cameraName) {
      //console.log('gpalyerApi. no camera name');
      return;
    }
    const camera = cameras.find(
      (cam: any) => cam.title.toLowerCase() == cameraName.toLowerCase(),
    );
    //console.log('gpalyerApi appropriate language camera', camera);
    if (!camera) {
      //console.log('gpalyerApi. camera with name', cameraName, 'not found');
      return;
    }
    if (currentCameraId != camera.id) {
      gplayerAPI?.method({
        name: 'getPlugin',
        params: {
          pluginName: 'multicamera',
          pluginMethod: 'changeById',
          pluginValue: camera.id,
        },
      });
      setCurrentCameraId(camera.id);
    }
  }, [cameras, cameraName]);

  return (
    <div className="video">
      <iframe
        className="videoframe"
        src={url}
        frameBorder="0"
        //allowFullScreen
        referrerPolicy="no-referrer"
        allow="autoplay"
        ref={frameRef}
      ></iframe>
    </div>
  );
};

export default VideoPlayer;
