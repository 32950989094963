import './Form.css';
import React from 'react';
import FormField, { FormFieldProps } from './FormField';
import { ErrorMessage, SuccessMessage } from '../Messages';
import { SubmittingSpinner } from '../Spinner';
import { useLocalizationContext } from '../../_localization/LocalizationContext';
import { uiMessages } from '../../_localization/ui.messages';

interface FormProps {
  fields: FormFieldProps[];
  onSubmit: (values: any) => Promise<void>;
  afterSubmit?: () => void;
  submitLabel?: string;
}

export const Form: React.FC<FormProps> = ({
  fields,
  onSubmit,
  afterSubmit,
  submitLabel,
  children,
}) => {
  const { language } = useLocalizationContext();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [submitSuccess, setSubmitSuccess] = React.useState(false);
  const [submittingError, setSubmittingError] = React.useState('');
  const [fieldsValid, setFieldsValid] = React.useState<
    { [key: string]: boolean } | undefined
  >(undefined);
  const canSubmit =
    !isSubmitting &&
    !submitSuccess &&
    (!fieldsValid || Object.values(fieldsValid).every((value) => value));

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (canSubmit) {
          setIsSubmitting(true);
          setSubmitSuccess(false);
          setSubmittingError('');
          const formData = new FormData(e.target as HTMLFormElement);
          const values: any = {};
          fields.forEach(
            (field) =>
              (values[field.name] = field.disabled
                ? field.initialValue
                : field.type === 'checkbox'
                ? formData.get(field.name) == 'on'
                : formData.get(field.name)),
          );
          onSubmit(values)
            .then(() => {
              setIsSubmitting(false);
              setSubmitSuccess(true);
              afterSubmit?.();
            })
            .catch((err) => {
              setIsSubmitting(false);
              setSubmitSuccess(false);
              console.error(err);
              setSubmittingError(err || uiMessages.errors_onSave[language]);
            });
        }
      }}
    >
      <div className="form-columns">
        {children}
        {fields.map((field) => (
          <div key={field.name} style={{ gridArea: field.name }}>
            <FormField
              {...field}
              afterValidation={(valid) => {
                setFieldsValid((fv) => {
                  return { ...fv, [field.name]: valid };
                });
                setSubmitSuccess(false);
              }}
            />
          </div>
        ))}
      </div>
      <div className="submitButton-container">
        <button className="button" type="submit" disabled={!canSubmit}>
          {isSubmitting && <SubmittingSpinner />}
          {submitLabel || uiMessages.form_Save[language]}
        </button>
      </div>
      {submitSuccess && (
        <SuccessMessage>uiMessages.dataIsSaved[language]</SuccessMessage>
      )}
      {submittingError && <ErrorMessage>{submittingError}</ErrorMessage>}
    </form>
  );
};
