import { Paper, InputBase } from '@material-ui/core';
import { Close, Delete } from '@material-ui/icons';
import React, { useState } from 'react';
import { SendButton } from '../../../_components/Button';
import { Message } from '../../../_data/Message';
import { useLocalizationContext } from '../../../_localization/LocalizationContext';
import { uiMessages } from '../../../_localization/ui.messages';
import { useWebinarContext } from '../Webinar.Provider';

interface ChatSendMessageProps {
  messageToEdit?: Message;
  onSendMessage: (msg: string) => void;
  onEditMessage: (msg: Message) => void;
  onDeleteMessage: (msg: Message) => void;
  onCancelEdit: () => void;
}

const ChatSendMessage: React.FC<ChatSendMessageProps> = ({
  messageToEdit,
  onSendMessage,
  onEditMessage,
  onDeleteMessage,
  onCancelEdit,
}) => {
  const { language } = useLocalizationContext();
  const { chatDisabled } = useWebinarContext();
  const [text, setText] = useState<string>('');

  React.useEffect(() => {
    if (messageToEdit?.message) {
      setText(messageToEdit?.message);
    }
  }, [messageToEdit]);

  const submitMessage: React.FormEventHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (chatDisabled) return;
    if (messageToEdit) {
      onEditMessage(Object.assign(messageToEdit, { message: text }));
      setText('');
    } else if (text) {
      onSendMessage(text);
      setText('');
    }
  };

  if (chatDisabled) {
    return (
      <form className="send-message disabled">
        <label className="send-message-editLabel">
          {uiMessages.chat_Disabled[language]}
        </label>
        <div>
          <InputBase fullWidth multiline disabled />
        </div>
        <div className="send-message-send">
          <SendButton disabled edge="end" size="small" color="secondary" />
        </div>
      </form>
    );
  }

  return (
    <form
      className={'send-message' + (messageToEdit ? ' edit' : '')}
      onSubmit={submitMessage}
    >
      {messageToEdit && (
        <div className="send-message-delete">
          <button
            onClick={() => {
              onDeleteMessage(messageToEdit);
              setText('');
            }}
          >
            <Delete />
          </button>
        </div>
      )}
      <label className="send-message-editLabel">
        {messageToEdit
          ? uiMessages.chat_MessageEditing[language]
          : uiMessages.chat_NewMessage[language]}{' '}
      </label>
      {messageToEdit && (
        <button
          className="send-message-close"
          onClick={() => {
            onCancelEdit();
            setText('');
          }}
        >
          <Close fontSize="small" />
        </button>
      )}
      <div className="send-message-text">
        <InputBase
          value={text}
          onChange={(e) => setText(e.target.value)}
          fullWidth
          multiline
          placeholder={uiMessages.chat_Placeholder[language]}
          onKeyPress={(e) => {
            if (e.key === 'Enter') submitMessage(e);
          }}
        />
      </div>
      <div className="send-message-send">
        <SendButton
          disabled={!text}
          edge="end"
          size="small"
          color="secondary"
        />
      </div>
    </form>
  );
};

export default ChatSendMessage;
