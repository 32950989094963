import './Quiz.css';
import React from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { SubmitButton } from '../../../_components/Button';
import { useWebinarContext } from '../Webinar.Provider';
import { AnswerOption, QuestionType } from '../../../_data/Question';
import { Radio } from '@material-ui/core';
import { QuizTimer } from './QuizTimer';
import popupSoundFile from '../../../_media/control-popup.wav';
import { useLocalizationContext } from '../../../_localization/LocalizationContext';
import { uiMessages } from '../../../_localization/ui.messages';

const Quiz: React.FC = () => {
  const { language } = useLocalizationContext();
  const { question, sendAnswers } = useWebinarContext();
  const [answers, setAnswers] = React.useState<AnswerOption[]>([]);
  const [submitted, setSubmitted] = React.useState(false);
  const quizRef = React.useRef<HTMLDivElement>(null);
  const audio = new Audio(popupSoundFile);

  React.useEffect(() => {
    setSubmitted(false);
    setAnswers([]);
    let timeotId: number;
    if (question) {
      timeotId = setTimeout(() => {
        audio.play();
        quizRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
      });
    }

    return () => {
      if (timeotId) clearTimeout(timeotId);
    };
  }, [question]);

  const submitAnswers = () => {
    if (answers.length > 0) {
      setSubmitted(true);
      setTimeout(() => {
        sendAnswers(answers);
      }, 500); //таймаут на анимацию
    }
  };

  if (!question) {
    return null;
  }

  return (
    <div
      className={
        'quiz' + (submitted ? ' submitted' : '') + (question ? '' : ' hidden')
      }
    >
      <div className="quiz-header" ref={quizRef}>
        {(question.expirationSeconds || question.expirationSeconds === 0) &&
          question.expirationSeconds >= 0 && (
            <QuizTimer
              durationSeconds={question.expirationSeconds}
              onTimerFinished={() => sendAnswers([])}
            />
          )}
        <h4>{uiMessages.quiz_PresenceControl[language]}</h4>
        <div className="label">{uiMessages.quiz_Answer[language]}</div>
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          submitAnswers();
        }}
      >
        <div className="question">
          <div className="question-text">{question.text}</div>
          <div className="options">
            {question.options &&
              question.options.map((option) => (
                <FormControlLabel
                  key={option.id}
                  control={
                    question.questionType === QuestionType.Multiple ? (
                      <Checkbox color="primary" />
                    ) : (
                      <Radio color="primary" />
                    )
                  }
                  label={option.value}
                  onChange={(e) => {
                    setAnswers(
                      question.questionType === QuestionType.Multiple
                        ? [...answers, option].filter(
                            (a) =>
                              a.id !== option.id ||
                              (e.target as HTMLInputElement)?.checked,
                          )
                        : question.options.filter((a) => a.id === option.id),
                    );
                  }}
                  className="option"
                  value={option.id}
                  checked={answers.some((a) => a.id === option.id)}
                />
              ))}
          </div>
        </div>
        <SubmitButton
          className="quiz-submit"
          disabled={
            answers.length === 0 &&
            question.questionType === QuestionType.Single
          }
        >
          {uiMessages.quiz_Answer[language]}
        </SubmitButton>
      </form>
    </div>
  );
};

export default Quiz;
