import './PublicOffer.css';
import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { Popup } from '../../_components/Popup';
import { DownloadFile } from '../../_components/DownloadFile';
import { getApiFileUrl } from '../../restapi/backendPaths';
import { FormControlLabel } from '@material-ui/core';

export interface PublicOfferProps {
  publicOfferFileId?: string;
  doAccept: () => void;
  doCancel: () => void;
}

export const PublicOffer: React.FC<PublicOfferProps> = ({
  publicOfferFileId,
  doAccept,
  doCancel,
}) => {
  const [agreed, setAgreed] = React.useState(false);

  if (!publicOfferFileId) return null;

  return (
    <Popup
      header={<h4>Публичная оферта</h4>}
      canSubmit={agreed}
      fullscreen={true}
      submitText="Перейти к оплате"
      onSubmit={doAccept}
      onCancel={doCancel}
    >
      <div className="publicOffer">
        <p>Пожалуйста, ознакомьтесь с договором Публичной оферты.</p>
        <DownloadFile
          link={getApiFileUrl(publicOfferFileId)}
          withIcon={true}
          text="Скачать договор Публичной оферты"
        />
        <div>
          <FormControlLabel
            control={<Checkbox color="primary" />}
            label="Я прочитал(а) и согласен(на) с условиями Публичной оферты"
            checked={agreed}
            onChange={(_, checked) => setAgreed(checked)}
          />
        </div>
        <p>
          После нажатия на кнопку &ldquo;Перейти к оплате&rdquo; вы будете
          перенаправлены на страницу банка для проведения платежной операции.
        </p>
      </div>
    </Popup>
  );
};

export default PublicOffer;
