import './EventSponsors.css';
import React from 'react';
import { Organizer } from '../../_data/Organizer';
import { Sponsor } from '../../_data/Sponsor';
import ImgBackend from '../../restapi/ImgBackend';
import { uiMessages } from '../../_localization/ui.messages';
import { useLocalizationContext } from '../../_localization/LocalizationContext';

interface EventSponsorProps {
  sponsor: Sponsor;
}
const EventSponsor: React.FC<EventSponsorProps> = ({ sponsor }) => (
  <div className="eventSponsor">
    <a {...(sponsor.url && { href: sponsor.url })}>
      {sponsor.logo ? (
        <ImgBackend
          srcFile={sponsor.logo}
          alt={sponsor.name}
          title={sponsor.name}
        />
      ) : (
        <div className="eventSponsor-name">sponsor.name</div>
      )}
    </a>
  </div>
);

interface EventOrganizerProps {
  organizer: Organizer;
}
const EventOrganizer: React.FC<EventOrganizerProps> = ({ organizer }) => (
  <div className="eventOrganizer">
    <a {...(organizer.url && { href: organizer.url })}>
      {organizer.logo ? (
        <ImgBackend
          srcFile={organizer.logo}
          alt={organizer.name}
          title={organizer.name}
        />
      ) : (
        <div className="eventOrganizer-name">{organizer.name}</div>
      )}
    </a>
  </div>
);

interface EventSponsorsProps {
  organizers: Organizer[];
  sponsors: Sponsor[];
}

const EventSponsors: React.FC<EventSponsorsProps> = ({
  organizers,
  sponsors,
}) => {
  const { language } = useLocalizationContext();
  return (
    <div
      className={
        organizers.length > 0 || sponsors.length > 0 ? 'eventSponsors' : ''
      }
    >
      {organizers.length > 0 && (
        <>
          <div className="eventSponsors-title">
            {uiMessages.event_Organizers[language]}
          </div>
          <div className="eventSponsors-organizers">
            {organizers.map((organizer) => (
              <EventOrganizer key={organizer.id} organizer={organizer} />
            ))}
          </div>
        </>
      )}
      {sponsors.length > 0 && (
        <>
          <div className="eventSponsors-title">
            {uiMessages.event_Sponsors[language]}
          </div>
          <div className="eventSponsors-sponsors">
            {sponsors.map((sponsor) => (
              <EventSponsor key={sponsor.id} sponsor={sponsor} />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default EventSponsors;
