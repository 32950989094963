import { useState, useEffect } from 'react';

declare global {
  interface Window {
    GcorePlayer: any;
  }
}

export interface GplayerApiMethodParams {
  name: string;
  params?: any;
  callback?: (res: any) => void;
}

export interface GplayerApi {
  on: (event: string, callback: () => void) => void;
  method: (params: GplayerApiMethodParams) => void;
}

export function useGcoreApi(
  frame?: HTMLIFrameElement,
): {
  gplayerAPI?: GplayerApi;
} {
  const [loadedScript, setLoadedScript] = useState(false);
  const [gplayerAPI, setGplayerAPI] = useState<GplayerApi | undefined>(
    undefined,
  );

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://vplatform.gcdn.co/_players/v2.1.27/gplayerAPI.js';
    script.async = true;
    script.addEventListener('load', () => {
      setLoadedScript(true);
    });
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  useEffect(() => {
    //console.log('useGcoreApi', window.GcorePlayer, frame);
    if (window.GcorePlayer && frame) {
      setGplayerAPI(new window.GcorePlayer.gplayerAPI(frame));
    } else {
      setGplayerAPI(undefined);
    }
  }, [loadedScript, frame]);

  return { gplayerAPI };
}
