import './EventsList.css';
import React from 'react';
import { useHistory } from 'react-router';
import appRoutes, { getActualLink } from '../navigation/routes';
import { VisitedEvent } from '../_data/SheduledEvent';
import { EventDate } from './EventDate';
import { useLocalizationContext } from '../_localization/LocalizationContext';
import { uiMessages } from '../_localization/ui.messages';

interface UserVisitedEventsProps {
  events?: VisitedEvent[];
}

const UserVisitedEvents: React.FC<UserVisitedEventsProps> = ({ events }) => {
  const history = useHistory();
  const { language } = useLocalizationContext();
  return (
    <div className="eventsList">
      <h3>{uiMessages.user_VisitedEvents[language]}</h3>
      {events && events.length > 0 ? (
        <table>
          <colgroup>
            <col className="col-date" />
            <col className="col-type" />
            <col className="col-code" />
            <col className="col-score" />
            <col />
          </colgroup>
          <thead>
            <tr>
              <th>{uiMessages.event_Date[language]}</th>
              <th>{uiMessages.event_Type[language]}</th>
              <th>{uiMessages.event_NmoCode[language]}</th>
              <th>{uiMessages.event_Score[language]}</th>
              <th>{uiMessages.event_EventTitle[language]}</th>
            </tr>
          </thead>
          <tbody>
            {events.map((event) => (
              <tr key={event.id}>
                <td className="event-date">
                  <EventDate start={event.start} end={event.end} />
                </td>
                <td className="event-type">{event.type}</td>
                <td className="event-code">{event.code}</td>
                <td className="event-points">
                  {`${event.points} ${uiMessages.of[language]} ${event.maxPoints}`}
                </td>
                <td
                  className="event-title link"
                  onClick={(e) => {
                    e.preventDefault();
                    const link = getActualLink(appRoutes.event, [
                      { key: 'id', value: event.id },
                    ]);
                    history.push(link);
                  }}
                >
                  {event.title}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        uiMessages.user_VisitedEventsNone[language]
      )}
    </div>
  );
};

export default UserVisitedEvents;
