import React from 'react';
import differenceInSeconds from 'date-fns/differenceInSeconds';

interface QuizTimerProps {
  durationSeconds: number;
  onTimerFinished: () => void;
}
export const QuizTimer: React.FC<QuizTimerProps> = ({
  durationSeconds,
  onTimerFinished,
}) => {
  const [startTime, setStartTime] = React.useState(new Date());
  const [timer, setTimer] = React.useState(durationSeconds);

  React.useEffect(() => {
    setStartTime(new Date());
    setInterval(
      () =>
        setTimer(durationSeconds - differenceInSeconds(new Date(), startTime)),
      1000,
    );
  }, []);

  React.useEffect(() => {
    if (timer <= 0) {
      onTimerFinished();
    }
  }, [timer]);

  const minutes = Math.floor(timer / 60);
  const seconds = Math.floor(timer % 60);
  return (
    <div className="quiz-timer">
      {minutes}:{(seconds < 10 ? '0' : '') + seconds}
    </div>
  );
};
