import './CatalogNav.css';
import React from 'react';
import AuthHeader from '../auth/AuthHeader';
import Search, { SearchAside } from '../_components/Search';
import { Link, useHistory, useLocation } from 'react-router-dom';
import appRoutes from '../navigation/routes';
import { Menu as MenuIcon } from '@material-ui/icons';
import {
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@material-ui/core';
import { useLocalizationContext } from '../_localization/LocalizationContext';
import { uiMessages } from '../_localization/ui.messages';

interface CatalogNavProps {
  onSearchChange: (search: string) => void;
}

export const CatalogNav: React.FC<CatalogNavProps> = ({ onSearchChange }) => {
  const { language } = useLocalizationContext();
  const location = useLocation();
  return (
    <div className="catalog-nav">
      <nav className="catalogNav">
        {location.pathname !== appRoutes.home && (
          <li>
            <Link to={appRoutes.home}>{uiMessages.nav_Home[language]}</Link>
          </li>
        )}
        <li>
          <Link to={appRoutes.contacts}>{uiMessages.contacts[language]}</Link>
        </li>
        {/* <li>
          <Link to={appRoutes.photos}>{uiMessages.photos[language]}</Link>
        </li> */}
        <Search onSearchChange={onSearchChange} />
      </nav>
      <AuthHeader />
    </div>
  );
};

const NavMenuPopper: React.FC = () => {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLAnchorElement | null>(null);
  const history = useHistory();
  const { language } = useLocalizationContext();
  return (
    <>
      <a
        href="#"
        ref={anchorRef}
        onClick={() => setMenuOpen((menuOpen) => !menuOpen)}
      >
        <MenuIcon fontSize="large" />
      </a>
      <Popper
        open={!!menuOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        className="dropdown"
        placement="bottom-end"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
              <Paper>
                <MenuList
                  className="catalogNav"
                  autoFocusItem={menuOpen}
                  onKeyDown={(event: React.KeyboardEvent) => {
                    if (event.key === 'Tab') {
                      event.preventDefault();
                      setMenuOpen(false);
                    }
                  }}
                >
                  {history.location.pathname !== appRoutes.home && (
                    <MenuItem
                      onClick={() => {
                        setMenuOpen(false);
                        history.push(appRoutes.home);
                      }}
                    >
                      {uiMessages.nav_Home[language]}
                    </MenuItem>
                  )}
                  <MenuItem
                    onClick={() => {
                      setMenuOpen(false);
                      history.push(appRoutes.contacts);
                    }}
                  >
                    {uiMessages.contacts[language]}
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setMenuOpen(false);
                      history.push(appRoutes.photos);
                    }}
                  >
                    {uiMessages.photos[language]}
                  </MenuItem>
                </MenuList>
              </Paper>
            </ClickAwayListener>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export const CatalogNavAside: React.FC<CatalogNavProps> = ({
  onSearchChange,
}) => {
  return (
    <div className="catalog-nav-aside">
      <NavMenuPopper />
      <SearchAside onSearchChange={onSearchChange} />
      <AuthHeader />
    </div>
  );
};
