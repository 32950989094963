import { Organizer } from '../Organizer';
import { Sponsor } from '../Sponsor';

export const mockSponsors: Sponsor[] = [
  {
    id: '1',
    name: 'ФГБОУ ВО СамГМУ Минздрава России',
    logo: { url: 'https://samsmu.ru/i/samgmu-logo-iso.png' },
    url: 'https://samsmu.ru/',
    primary: true,
    order: 5,
  },
  {
    id: '2',
    name: 'Министерство здравоохранения	Самарской области',
    logo: {
      url:
        'https://minzdrav.samregion.ru/wp-content/uploads/sites/28/2019/05/logotip.png',
    },
    url: 'https://minzdrav.samregion.ru/',
    order: 4,
  },
  {
    id: '3',
    name: 'ЗАО «Медицинская компания ИДК»',
    logo: { url: 'http://aes-samara.ru/images/logo/idk.gif' },
    url: 'https://samara.mamadeti.ru/',
    order: 1,
  },
  {
    id: '4',
    name: 'Спонсор без логотипа',
    logo: { url: '' },
    url: '',
    order: 3,
  },
  {
    id: '5',
    name: 'Спонсор 5',
    logo: {
      url:
        'https://e7.pngegg.com/pngimages/530/525/png-clipart-green-and-white-medical-logo-herbalism-medicine-alternative-health-services-naturopathy-pharmacy-leaf-logo.png',
    },
    url: '',
    order: 2,
  },
  {
    id: '6',
    name: 'Спонсор 6',
    logo: {
      url:
        'https://w7.pngwing.com/pngs/312/1018/png-transparent-orange-blue-and-black-logo-logo-circle-technology-circle-blue-text-information-technology.png',
    },
    url: '',
    order: 7,
  },
  {
    id: '7',
    name: 'Спонсор 7',
    logo: {
      url:
        'https://img2.freepng.ru/20180413/dvq/kisspng-logo-pharmacy-pills-5ad0fdc97d5a49.1567847115236458975135.jpg',
    },
    url: '',
    order: 6,
  },
  {
    id: '8',
    name: 'Спонсор 8',
    logo: {
      url:
        'https://img2.freepng.ru/20180713/rzc/kisspng-hospital-logo-clinic-health-care-physician-5b48c1801f8383.2739660215314947841291.jpg',
    },
    url: '',
    order: 8,
  },
  {
    id: '9',
    name: 'Спонсор 9',
    logo: { url: '' },
    url: '',
    order: 9,
  },
];

export const mockOrganzers: Organizer[] = mockSponsors.map((s) => ({
  id: s.id,
  name: s.name,
  logo: s.logo,
  url: s.url,
  primary: s.primary,
  order: s.order,
}));
