import './EventHeader.css';

import React from 'react';
import { SheduledEvent } from '../../_data/SheduledEvent';
import { Route, Switch } from 'react-router';
import appRoutes from '../../navigation/routes';
import NotFoundPage from '../../layout/NotFoundPage';
import Webinar from '../webinar/Webinar';
import { useAuthContext } from '../../auth/AuthContext';
import { EventHeaderInfo } from './EventHeaderInfo';
import { useLocalizationContext } from '../../_localization/LocalizationContext';
import { uiMessages } from '../../_localization/ui.messages';

interface EventHeaderProps {
  event: SheduledEvent;
}

const EventHeader: React.FC<EventHeaderProps> = ({ event }) => {
  const { user } = useAuthContext();
  const { language } = useLocalizationContext();
  return (
    <div className="eventHeader">
      {!event.published && (
        <div className="eventHeader-languageUnavaliable">
          {uiMessages.event_NotPublished[language]}
        </div>
      )}

      <div className="eventHeader-main">
        <Switch>
          <Route
            exact
            path={appRoutes.webinar}
            render={() =>
              user ? (
                <Webinar event={event} />
              ) : (
                <EventHeaderInfo event={event} />
              )
            }
          />
          <Route
            exact
            path={appRoutes.event}
            render={() => <EventHeaderInfo event={event} />}
          />
          <Route component={NotFoundPage} />
        </Switch>
      </div>
    </div>
  );
};

export default EventHeader;
