import React from 'react';
import { format } from 'date-fns';
import { useLocalizationContext } from '../../_localization/LocalizationContext';

interface EventHeaderDateProps {
  start: Date;
  end?: Date;
}
export const EventHeaderDate: React.FC<EventHeaderDateProps> = ({
  start,
  end,
}) => {
  const { locale } = useLocalizationContext();
  return (
    <div className="eventHeader-date">
      <span>{format(start, 'd MMMM, yyyy', { locale })}</span>
      <span>
        {end &&
          end.toDateString() !== start.toDateString() &&
          ' — ' + format(end, 'd MMMM, yyyy', { locale })}
      </span>
    </div>
  );
};
