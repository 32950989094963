/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Language } from './avaliable.languages';
import ruLocale from 'date-fns/locale/ru';
import enLocale from 'date-fns/locale/en-GB';

interface LocalizationContextProps {
  language: Language;
  locale: Locale;
  setLanguage: (language: Language) => void;
}

const LocalizationContext = React.createContext<LocalizationContextProps>({
  language: 'ru',
  locale: ruLocale,
  setLanguage: () => {},
});

export const useLocalizationContext = () =>
  React.useContext(LocalizationContext);

export const LocalizationContextProvider: React.FC = ({ children }) => {
  const history = useHistory();
  const params = new URLSearchParams(history.location.search);
  const [language, setLanguage] = React.useState<Language>(
    (params.get('lang') as Language) || 'ru',
  );

  const [locationUpdated, setLocationUpdated] = React.useState(false);

  React.useEffect(() => {
    const params = new URLSearchParams(history.location.search);
    if (language != (params.get('lang') || 'ru')) {
      params.set('lang', language);
      history.replace({
        search: '?' + params.toString(),
      });
    }
  }, [language, locationUpdated]);

  React.useEffect(() => {
    // из-за замыкания напрямую нельзя передать текущее значение language
    const unregister = history.listen(() => setLocationUpdated((lu) => !lu));
    return () => unregister();
  }, []);

  const changeLanguage = (language: Language) => {
    setLanguage(language);
  };

  return (
    <LocalizationContext.Provider
      value={{
        language,
        locale: language === 'ru' ? ruLocale : enLocale,
        setLanguage: changeLanguage,
      }}
    >
      {children}
    </LocalizationContext.Provider>
  );
};
